// Typography
$base-font-family: Arial, sans-serif;
$header-font-family: Arial, sans-serif;

// Font Sizes
$base-font-size: 1em;
$h1-font-size: $base-font-size * 2.25;
$h2-font-size: $base-font-size * 2;
$h3-font-size: $base-font-size * 1.75;
$h4-font-size: $base-font-size * 1.5;
$h5-font-size: $base-font-size * 1.25;
$h6-font-size: $base-font-size;
$marginal-font-size: $base-font-size * .85;

$basefont: 15;
$baseh1: 26;
$baseh2: 21;
$baseh3: 18;
$baseh4: 14;
$baseh5: 13;
$baseh6: 12;

// Line height
$base-line-height: 1.5;
$header-line-height: 1.25;

// Other Sizes
$base-border-radius: 3px;
$base-spacing: $base-line-height * 1em;
$base-z-index: 0;

// Colors
$yellow: #F7A922;
$gray: #737373;
$light-gray: #B9B9B9;
$lightest-gray: #E3E3E3;
$blue: #A1D5E0;
$dark-gray: #565656;
$medium-gray: #9d9d9d;
$white: #FFFFFF;
$white-hued: #f2f2f2;

// Brand Colours
$styrolution-blue: #001842;
$styrolution-blue-medium: #435395;
$styrolution-blue-light: #7E95BF;
$styrolution-blue-lightest: #e5eaf2;
$styrolution-green: #64A342;
$styrolution-green-half: #b2d1a1;
$styrolution-green-dark: #0B6822;
$styrolution-green-light: #AEC38B;
$styrolution-orange: #F7A823;
$styrolution-orange-dark: #b87d1a;

// Form colors
$form-color-orange: #fbdca7;
$form-color-orange-light: #fdeed3;

// Background Color
$base-background-color: white;
$accented-background-color: $styrolution-green;

// Font Colors
$base-font-color: $gray;
$accented-font-color: $styrolution-green;
$inverted-font-color: $white;

// Link Colors
$base-link-color: $accented-font-color;
$hover-link-color: $styrolution-green-dark;
$base-button-color: $base-link-color;
$hover-button-color: $hover-link-color;

// Border color
$base-border-color: $lightest-gray;
$accented-border-color: $styrolution-green;
$base-border: 1px solid $base-border-color;

// Forms
$form-border-color: $base-border-color;
$form-border-color-hover: $accented-border-color;
$form-border-color-focus: $accented-border-color;
$form-border-radius: $base-border-radius;
$form-box-shadow: inset 0 1px 3px rgba(black,0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px rgba($styrolution-green-dark, 0.7);
$form-font-size: $base-font-size;
$form-font-family: $base-font-family;
