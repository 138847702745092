// Accordion slider (accordion on mobile, slider on desktop)
$slider-mode: $large-screen-up;

.accordion-slider-nav-container {
	position: absolute;
	width: 100%;
	display: none;

	@include media($slider-mode) {
		display: block;
	}

	.wrapper {
		position: relative;
	}
}

.title--accordion {
	display: none;

	@include media($slider-mode) {
		display: block;
	}
}

.accordion-slider-nav {
	width: 220px;
	position: absolute;
	top: 90px;
	left: 0;
	z-index: 10;
	//@include transition (right .5s ease);

	li {
		a {
			display: block;
			border-top: 1px solid $lightest-gray;
			border-bottom: 1px solid $lightest-gray;
			margin-top: -1px;
			font-weight: 500;
			font-size: 13px;
			line-height: 14px;
			color: $gray;
			text-transform: uppercase;
			padding: 12px 15px 12px 0;
			position: relative;
			overflow: hidden;
			text-overflow: ellipsis;

			&:after {
				content: '\f105';
				font-family: Icomoon;
				color: $accented-font-color;
				@include size(5px 14px);
				@include position(absolute, 0 0 0 null);
				margin: auto;
			}

			&:hover,
			&.active {
				color: $accented-font-color;
			}
		}
	}

	.content--highlighted--dark & {
		li {
			a {
				color: white;
				border-color: #838383;

				&:hover,
				&.active {
					color: $accented-font-color;
				}
			}
		}
	}
}

.slider-wrapper {
	max-width: $max-width;
	margin: 0 auto;
}

.desktop-slider-holder {
	position: relative;
}

.accordion-slider {

	@include clearfix;

	li.accordion-slider__header-and-content {
		&:not(:last-child) {
			.accordion-slider__link {
				border-bottom: 1px solid $lightest-gray;

				&.is-active {
					border-bottom: 0;
				}
			}
		}
	}

	a.accordion-slider__link {
		text-transform: uppercase;
		color: $gray;
		display: block;

		.section-header {
			position: relative;
			padding-right: 20px;
			margin-bottom: 0;

			@include media($slider-mode) {
				margin-bottom: 25px;
			}

			&:before {
				content: '\e114';
				font-family: Icomoon;
				display: inline-block;
				color: $accented-font-color;
				@include position(absolute, 50% 0 null null);
				margin-top: -11px;
			}
		}

		&:hover {
			color: $gray;
		}

		&:focus {
			outline: none;
		}

		&.is-active {

			.section-header:before {
				content: '\e115';
			}

			@include media($slider-mode) {
				border-bottom: 2px solid $accented-border-color;
				margin-bottom: -2px;

				.section-header:before {
					content: '\f105';
				}
			}
		}

		@include media($slider-mode) {
			border-top: 0;
			padding-left: $base-spacing;
			padding-right: $base-spacing;

			display: none;

			.section-header:before {
				content: '\f105';
			}
		}
	}

	&.accordion-with-side-nav {
		@include media($slider-mode) {
			a.accordion-slider__link {
				display: block;
				padding: 0;
				border-bottom: none !important;

				.section-header {
					.title--small {
						font-weight: 500;
					}

					&:before {
						content: none;
					}
				}

				&:hover {
					cursor: default;
				}
			}
		}
	}

	.accordion-slider__content {
		display: none;
		padding: $base-spacing 0;
		position: relative;

		p {
			img {
				margin-bottom: 20px !important;
			}
		}

		h3, h4 {
			font-size: 21px;
			margin-bottom: 25px;
		}

		h3 {
			font-weight: 300;
		}

		h4 {
			font-weight: 500;
		}

		p + ul {
			list-style-type: disc;
			padding-left: 1.5em;
		}

		ul,
		ul.bulleted {
			margin-bottom: 25px;
			li {
				margin-bottom: 5px;

				ul {
					@extend %default-ul;
					padding-left: 25px;
					margin-top: 5px;
					margin-bottom: 0;

					li {
						margin-bottom: 5px;
					}
				}
			}
		}

		ul.two-cols-list {
			@include columns(2);
			padding-left: 0;
			list-style-type: none;

			li:before {
				content: '';
				display: inline-block;
				@include size(4px);
				border-radius: 50%;
				background-color: $gray;
				margin-right: 10px;
				position: relative;
				top: -3px;
			}
		}

		ol {
			@extend %default-ol;
			margin-bottom: 25px;
			li {
				margin-bottom: 5px;
			}
		}

		.bulleted {
			@include media($slider-mode) {

				li {
					position: relative;

					a {
						&:hover {
							cursor: pointer;
						}
					}
				}
			}
		}

		.table-holder {
			overflow-x: scroll;
			margin: 25px 0;
		}

		table {
			display: table !important;
			width: auto;
			margin: 25px 0;

			&.table-dynamic-products {
				margin-top: 0;
			}

			@include media($slider-mode) {
				width: 100%;
			}
		}

		script + .wrapper > section {
			padding-top: 0;
		}

		@include media($slider-mode) {
			display: block;
			max-width: $max-width;
			margin: 0 auto;
			padding: 60px 0;

			.title {
				text-transform: capitalize;
				margin-bottom: 45px;
			}

			[class^="col-lg-"] {
				padding-right: 50px;
			}

			.col-lg-6 {
				@include omega(2n);
			}
			
			.col-lg-6,
			.accordion-content--not-intro {
				margin-left: 280px;
			}
		}

		&.is-open {
			display: block;
			border-bottom: 1px solid $lightest-gray;

			@include media($slider-mode) {
				border-bottom: none;
			}
		}
	}
}

.bx-wrapper {
	.accordion-slider {
		.accordion-slider__content {
			@include media($slider-mode) {
				.col-lg-6 {
					@include omega-reset(2n);
				}
			}
		}
	}
}

.content--highlighted {
	table {
		display: table !important;
		width: auto;
		margin: 25px 0;

		@include media($slider-mode) {
			width: 100%;
		}
	}

	th {
		&.is-invisible {
			visibility: visible;
			text-indent: -9999px;
			width: 200px;
		}
	}

	.accordion-slider {
		.accordion-slider__content {
			tr {
				background-color: white;
			}
		}
	}
}

.content--highlighted--dark {
	.accordion-slider {
		li.accordion-slider__header-and-content:not(:last-child) {
			.accordion-slider__link {
				border-color: #838383;
			}
		}

		.accordion-slider__content {
			&.is-open {
				border-color: #838383;
			}

			table {
				color: $gray;
			}

			th {
				background-color: #fff;
				color: $gray;
			}

			tr:nth-child(even) {
				background-color: $lightest-gray;
			}
		}
	}
}

.accordion-slider-with-title {
	.accordion-slider-nav {
		@include media($slider-mode) {
			top: 60px;
		}
	}

	.accordion-slider {
		.accordion-slider__content {
			@include media($slider-mode) {
				padding-top: 0;
			}
		}
	}
}

.desktop-slider-slides-with-title {
	.accordion-slider-nav {
		@include media($slider-mode) {
			top: 60px;
		}
	}

	.accordion-slider__header-and-content {
		&:not(:first-child) {
			.accordion-slider__content {
				.title {
					padding-left: 280px;
				}
			}
		}
	}
}

.slider-contact-block {
	@include clearfix;

	li {
		display: inline-block;
	}
}

// Dynamic titles
.desktop-slider-dynamic-titles {
	@include media($slider-mode) {
		.accordion-slider__content {
			.title {
				display: none !important;
			}
		}
	}
}

.title .slider-title {
	@include media($slider-mode) {
		display: inline;
	}
}

.slide-title {
	display: none !important;

	@include media($slider-mode) {
		display: block !important;
		color: $gray;

		font-weight: 500;
		font-size: 24px;
		text-transform: none;
		line-height: 1.8;

		.content--highlighted--dark & {
			color: white;
		}
	}
}

// Simple accordion
.accordion {
	$accordion-menu-border-color: $lightest-gray;
	$accordion-menu-border: 1px solid $accordion-menu-border-color;

	border: $accordion-menu-border;

	> li {

		.accordion__header {
			display: block;
			padding: 0 10px;
			
			&:hover {
				background-color: $lightest-gray;
				color: $styrolution-blue;
			}

			.section-header {
				@extend %icon;
				@extend %icon-plus;
				position: relative;
				margin-bottom: 0;

				&:before {
					@include position(absolute, 50% 0 null null);
					font-size: 1em;
					margin-top: -12px;
				}

				.title--small {
					margin: 0;
				}
			}
		}

		&:not(:last-child) {
			border-bottom: $accordion-menu-border;
		}

		&.is-expanded {
			border-bottom: 2px solid $accented-border-color;
			
			.section-header {
				@extend %icon-minus;
			}

			> .accordion__header {
				background-color: $lightest-gray;
				border-bottom: 2px solid $accented-border-color;
			}

			> .section-header {
				color: $styrolution-blue;

				&:before {
					content: '\e115';
				}
			}
		}
	}

	.accordion__content {
		display: none;
		padding: 20px 10px;
	}

	.is-expanded {
		display: block;
		padding-bottom: 0;
	}

	&.accordion--highlighted {
		border: none;

		> li {
			&:hover {
				> a {
					

					.list--arrow__title,
					.list--arrow__subtitle {
						color: white;
					}

					i {
						color: white;
					}
				}
			}
		}

		> li.is-expanded {
			border-bottom: 3px solid $styrolution-blue;

			> a {
				color: $styrolution-blue;
				border-bottom: 3px solid $styrolution-blue;

				.list--arrow__title,
				.list--arrow__subtitle {
					color: white;
				}

				i:before {
					content: '\e115';
				}
			}

			.accordion__content {
				padding: 0;

				.location-details__details {
					@include media($large-screen-up) {
						padding-left: 30px;
						padding-right: 30px;
					}
				}

				&.accordion__content--padded {
					padding: 30px 10px;

					@include media($large-screen-up) {
						padding: 30px 50px;
					}
				}
			}

			.accordion {
				li {
					a {
						.section-header {
							&:before {
								color: $accented-font-color;
							}
						}

						&:hover {
							.section-header {
								&:before {
									color: white;
								}
							}
						}
					}

					&.is-expanded {
						a .section-header:before {
							content: '\e115';
						}
					}
				}
			}
		}
	}
}


.article ul.accordion {
	list-style: none;
	padding-left: 0;
	
	> li {
		margin: 0;
	}
}


.accordion__content__inner {
	padding: 25px;
}

.prod-details-holder {
	padding-top: 5px;

	.section-header {
		&:before {
			color: $accented-font-color !important;
		}
	}

	.title--small {
		a {
			color: $gray;
		}
	}

	li.is-expanded {
		.section-header:before {
			content: '\e115';
		}

		.title--small {
			a {
				color: white;
			}
		}
	}
}

.media-wrapper {
	overflow-x: scroll;
	overflow-y: hidden;

	img {
		width: auto !important;
		max-width: initial;
	}

	.ie8 &,
	.ie9 & {
		overflow-x: hidden;
	}
}

.media-wrapper,
.table-holder {
	&::-webkit-scrollbar {
		-webkit-appearance: none;

		&:vertical {
			width: 7px;
		}

		&:horizontal {
			height: 7px;
		}
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 8px;
		border: 2px solid $lightest-gray; /* should match background, can't be transparent */
		background-color: $accented-background-color;

		@include media($large-screen-up) {
			border: none;
			background-color: transparent;
		}
	}

	&::-webkit-scrollbar-track {
		background-color: $lightest-gray;
		border-radius: 8px;

		@include media($large-screen-up) {
			background-color: transparent;
		}
	}
}






// ******************************
// Additions for Styrolution
// 07-08, 2016
// ******************************
.expandable {
	position: relative;
	border-top: 1px solid $lightest-gray;
	border-bottom: 1px solid $lightest-gray;
	margin: 0 0 1.5em;
	padding: 1em 0;
	
	.expand-link {
		@extend %icon;
		@extend %icon-plus;
		display: block;
		font-size: 1.6em;
		overflow: hidden;
		
		> * {
			font-size: 1em;
			margin: 0;
		}
		
		> h3 {
			font-size: 24px;
			font-weight: 700;
		}
		
		&:before {
			float: right;
			font-size: .9em;
		}

		&.expanded {
			@extend %icon-minus;
			color: $styrolution-blue;
		}
	}

	.expandable__additonal-link {
		display: block;
		position: absolute;
		right: 4em;
		top: 1.1em;
	}
	
	.expandable-content {
		display: none;
		
		> :first-child {
			margin-top: 1em;
		}
	}
	
	section {
		padding: 0;
	}
	
	&.expanded {

		
		.expandable-content {
			// we do the toggling via jQuery so as to have an animation (without using transition with min-height)
		}
	}
}

.expandable + .expandable {
	border-top: none;
	margin-top: -1.5em;
}

section.expandable {
	margin: 60px 0;
}