$categoryChildrenColor: #FFBA45;
$categoryGeneralOutreachColor: #A3B8D6; 
$categoryEnvironmentalInitiativesColor: #94CC8E;
$kunoColor: #0F619B;
$grffColor: #E664A5;
$mediumScreen: 640px;
$maxSizeForLegend: 900px;

.community-module {
  position: relative;

  [class^="iconprop-"],
  [class^="iconprop-"],
  [class^="icon"] {
    font-size: 35px;
    color: inherit;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    speak: none;
    text-align: center;
    text-decoration: inherit;
    text-transform: none;

    &:before {
      font-size: 35px;
      color: $styrolution-green;
    }
  }

  & > .community__legend {
    @media screen and (max-width: $mediumScreen) {
      display: none !important;
    }
  }

  [class^="iconprop-"] {
    &:before {
      font-size: 25px;
    }
  }

  .community__mouseIconContainer {
    position: absolute;
    left: 20px;
    bottom: 110px;
    z-index: 1;
    display: flex;
    align-items: center;
    padding: 10px;
    background: rgba(0, 0, 0, 0.7);

    // snap the container to the left side where the content starts
    @media screen and (min-width:1120px) {
      left: calc((100vw - 1140px) / 2);
    }

    @media screen and (max-width: $maxSizeForLegend) {
      bottom: 190px;
    }

    &.isHidden {
      display: none;
    }

    img {
      width: 36px;
      height: 36px;
    }

    p {
      margin: 0;
      color: white;
      font-size: 13px;
      line-height: 20px;
      width: 320px;
      margin-left: 15px;

      &.hidden {
        display: none;
      }

      @media screen and (min-width: $mediumScreen) and (max-width: 1000px) {
        width: 200px;
        font-size: 12px;
      }
    }
  }

  .community__backgroundContainer {
    position: relative;

    &--isHidden {
      display: none;
    }

    &--hasMouseIconContainer {
      padding-bottom: 88px;

      @media screen and (max-width: $maxSizeForLegend) {
        padding-bottom: 171px;
      }
    }
  }

  .community__content {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 1120px;
    height: 100%;

    @media screen and (min-width:1120px) {
      max-width: inherit;
      width: 1120px;
    }
  }

  .community__clickableContent {
    position: relative;
    height: 100%;
    z-index: 1;

    &--isWorldwide {
      height: 87%;
    }
  }

  .community__clickableContinent {
      div {
        position: relative;
        width: 100%;
        height: 100%;
      }
  }


  .community__clickableContinent {
    cursor: pointer;
  }

  .community__clickableContinent--AMERICAS {
    width: 50%;
    height: 100%;
    position: absolute;
    // background-color: rgba(0,0,255,0.1);
  }

  .community__clickableContinent--ASIA {
    width: 34%;
    height: 64%;
    // background-color: rgba(255,0,0,0.1);
    bottom: 0;
    position: absolute;
    right: 0;
  }

  .community__clickableContinent--EMEA {
    position: absolute;
    // background-color: rgba(0, 255, 0,0.1);
    width: 56%;
    height: 100%;
    right: 0;
  }

  .community__worldwideImage {
    position: relative;
    max-width: 1120px;
    width: 100%;
    margin: auto;
    border-bottom: 1px solid white;

    &--isHidden {
      display: none;
    }
  }

  .community__backgroundImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .community__map {
    position: relative;
    width:  100%;
    max-width: 1120px;
    margin: auto;
    border-bottom: 1px solid white;

    &--isHidden {
      display: none;
    }
  }
  
  .community {
    overflow: visible;
  }
  .no-bottom-padding {
    padding-bottom: 0 !important;
  }

  .community__teaser {
    background-color: #F6F6F6;
    padding: 15px;
    margin-right: 5px;
    height: auto; 
    position: relative;

    @media screen and (min-width: $mediumScreen){
      padding: 20px;
      margin-right: 25px;
    }

    &Headline {
      display: flex;
      margin: 25px 0;

      @media screen and (max-width: $mediumScreen) {
        display: flex;
        align-items: center;
      }

      h3 {
        font-family: Arial;
        font-size: 18px;
        line-height: 23px;
        text-transform: uppercase;
        font-weight: bold;
        margin-left: 20px;

        @media screen and (max-width: 900px) {
          font-size: 16px;
          line-height: 20px;
        }

        @media screen and (min-width: 900px) {
          font-size: 18px;
          line-height: 23px;
        }
      }
    }

    &Text {
      margin: 20px 0 64px 0;
    }

    &Image {
      margin: auto;
      width: 100%;
    }

    &--children h3 {
      color: $categoryChildrenColor;
    }

    &--generalOutreach h3 {
      color: $categoryGeneralOutreachColor;
    }

    &--environmentalInitiatives h3 {
      color: $categoryEnvironmentalInitiativesColor;
    }

    &--flagship {
      border-top: 7px solid;
    }

    &--grff {
      border-top-color: $grffColor;

      h3 {
        color: $grffColor !important;
      }
    }

    &--kuno {
      border-top-color: $kunoColor;

      h3 {
        color: $kunoColor !important;
      }
    }

    .communityMap__icon {
      &--flag {
        height: 90px;
        margin-left: 7px;
        margin-right: -28px;
        margin-top: -30px;
      }
    }
  }

  .community__legendElement {

    &--description {
      color: white;
      font-weight: bold;

      @media screen and (max-width: $mediumScreen) {
        color: #737373;
        padding-bottom: 15px;
        padding-left: 10px;
      }

      @media screen and (min-width: $mediumScreen) and (max-width: $maxSizeForLegend) {
        display: inline-block;
        float: left;
        min-width: 180px;
      }

      @media screen and (min-width: $maxSizeForLegend) {
        width: 180px;
        display: inline-block;
        font-weight: bold;
      }
    }
    
    &--children {
      color: $categoryChildrenColor;
    }

    &--generalOutreach {
      color: $categoryGeneralOutreachColor;
    }

    &--environmentalInitiatives {
      color: $categoryEnvironmentalInitiativesColor;
    }

    span {
      margin: 0 15px 0 3px;
      color: white;

      @media screen and (max-width: $mediumScreen) {
        color: #737373;
      }
    }

    @media screen and (min-width: $mediumScreen) and (max-width: $maxSizeForLegend) {
      align-items: center;
    }

    &--category {
      @media screen and (min-width: $maxSizeForLegend) {
        display: inline-block;
        min-width: 210px;

        &:nth-child(2) {
        min-width: 280px;
        }
      }

      @media screen and (min-width: $maxSizeForLegend) and (max-width: 1100px){
        min-width: 230px;
      }

      @media screen and (min-width: $maxSizeForLegend) and (max-width: 970px){
        min-width: 220px;
      }

      @media screen and (min-width: $maxSizeForLegend) and (max-width: 950px){
        min-width: 200px;
      }

      @media screen and (min-width: $mediumScreen) and (max-width: $maxSizeForLegend){
        display: block;
        margin-bottom: 15px;
      }

      @media screen and (max-width: $mediumScreen) {
        display: block;
        padding: 0 0 10px 10px;
      }
    }
  }

  .community__legend {
    color: black;
    padding: 15px 0 5px 0;
    max-width: 1120px;
    margin: auto;
    font-size: 13px;

    z-index: 100;
    position: absolute;
    left: 0;
    right: 0;
    color: white;
    padding-top: 10px;
    background: none;

    bottom: 45px;

    @media screen and (min-width:1120px) {
      max-width: inherit;
      width: 1120px;
    }

    @media screen and (min-width: $mediumScreen) and (max-width: 1120px) {
      padding-left: 20px;
    }

    @media screen and (min-width: $mediumScreen) and (max-width: $maxSizeForLegend) {
      bottom: 60px;
      padding-top: 15px;
    }

    @media screen and (max-width: $mediumScreen) {
      position: initial;
      background-color: #F6F6F6;
    }

    &:last-child {
      bottom: 10px;

      @media screen and (max-width: $maxSizeForLegend) {
        bottom: 0px;
      }
    }

    & + span  {
      color: #737373; 
    }

    & span:first-child {
      @media screen and (min-width: $mediumScreen) {
        padding-left: 0;
      }
    }

    @media screen and (min-width: $mediumScreen) {
      &:last-child {
        padding-right: 0;
      }
    }

    &--isHidden {
      display: none;
    }

    .communityMap__icon--flag {
      width: 17px;
      vertical-align: sub;

      @media screen and (max-width: $maxSizeForLegend) {
        display: inline-block;
      }

      @media screen and (min-width: $mediumScreen) and (max-width: $maxSizeForLegend) {
        margin-top: -25px;
      }

      @media screen and (min-width: $maxSizeForLegend) {
        display: inline;
      }
    }

    &:first-child {
      margin-top: 15px;
    }
  }

  .community__legendCategories {
    @media screen and (min-width: $mediumScreen) and (max-width: $maxSizeForLegend) {
      display: inline-block;
      margin-top: -3px;
  
      & > .community__legendElement {
        margin-bottom: 10px;
      }
    }

    @media screen and (max-width: $mediumScreen) {
      margin-top: 5px;
    }

    @media screen and (min-width: $maxSizeForLegend) {
      display: inline-block;
    }
  }

  .community__amounts {
    float: right;

    & > span {
      margin-right: 20px;
    }
  }

  .community-tab-link {
    margin-bottom: 10px;

      @media screen and (min-width: $mediumScreen) {
        margin-bottom: 0;
      }
    overflow: hidden;
    
    &:first-child {
      display: none;

      @media screen and (min-width: $mediumScreen) {
        display: block;
      }
    }

    &:nth-child(2) {
      @media screen and (max-width: $medium-screen) {
        margin-top: 10px;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    .icon-minus {
      display: none;
    }

    .icon-plus {
      display: block;
    }

    &.current {

      .community-tab-community-list {
        display: block;

        &.hidden {
          display: none;
        }
      }

      .icon-minus {
        display: block;
      }

      .icon-plus {
        display: none;
      }
    }
  }

  .community__modal-product-element {
    &.open {
      .community__modal-dropdown-button {
        [class^="iconprop-"] {
          transform: rotate(270deg);
        }
      }
    }
  }

  .community__modal-dropdown-button {
    position: relative;
    background-color: transparent;
    border: none;
    outline: none;
    color: $styrolution-green;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    text-transform: none;
    font-weight: normal;
    padding: 0.2em 1em 0.2em;
    width: 48px;

    [class^="iconprop-"] {
      flex: 0 0 auto;
      transform: rotate(90deg);
      transition: transform 200ms;
      margin-left: 5px;
    }

    &:before {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      border-left: 1px solid $light-gray;
      content: '';
    }
  }

  .community__modal--content-headline {
    margin-top: 35px;
    display: none;
  }

  .community__modal {
    padding-top: 10px;
    transform: translate3d(0, -100%, 0);
    transition: transform 200ms;
    background-color: $white;

    &--oneItem {
      @media screen and (max-width: $mediumScreen) {
        padding-bottom: 20px;
      }
    }

    &.active {
      transform: translate3d(0, 0, 0);
    }
  }

  .slick-next,
  .slick-prev {
    position: absolute;
    border: none;
    top: auto;
    bottom: 0;
    padding-top: 30px;
    background-color: transparent;

    &:before {
      color: #64a342;
      line-height: 20px;
      font-size: 45px;
    }

    &:hover,
    &:focus {
      background-color: transparent;
    }
  }

  .slick-next {
    right: 0;
    left: auto;

    @media screen and (min-width: 760px) and (max-width: 1300px) {
      margin-right: 20px;
    }

    @media screen and (min-width: 1301px){
      margin-right: 16px;
    }
  }
  .slick-prev {
    margin-left: -10px;
    left: 0;
    right: auto;
  }

  .slick-dots {
    text-align: center;
    margin: 30px 0;

    li {
      button:before {
        font-size: 45px;
        line-height: 5px;
        color: grey;
      }
    }
  }

  .community-tab-link-description {
    background-color: $styrolution-green;
    color: white;
    text-transform: uppercase;
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    align-items: center;
    z-index: 2;
    position: relative;

    span {
      flex: 1 1 auto;
      padding-top: 12px;
      padding-bottom: 12px;
      font-weight: normal;
      font-size: 18px;
    }

    [class^="iconprop-"] {
      flex: 0 0 auto;
    }

    .icon-plus, .icon-minus {
      color: white;
      cursor: pointer;

      &:before {
        color: white;
      }

      &.isHidden {
        display: none;
      }
    }
  }

  .community-tab-community-list,
  .community__modal-product-sublist {
    display: none;

    &.active {
      display: block;
    }
  }

  .community-tabs {
    @media(max-width: $small-screen-max) {
      transform: none !important;
      margin-bottom: 20px;
    }
  }

  .community-tabs__prev,
  .community-tabs__next {
    display: none;
  }

  .community__modal--content-description {
    margin-bottom: 0;
    margin-top: 20px;
  }

  .community__modal-products-headline {
    margin-top: 55px;
  }

  .community__modal-product-list {
    margin-top: 20px;
  }

  .community__modal-product-element {
    &:before {
      border-top: 1px solid $light-gray;
      content: '';
      display: block;
    }

    &:last-child:after {
      border-bottom: 1px solid $light-gray;
      content: '';
      display: block;
    }
  }

  .community__modal-product-element-label {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
  }

  .community__modal-product-element-label {
    span {
      flex: 1 1 auto;
      text-transform: uppercase;
    }

    a {
      flex: 0 0 auto;
    }
  }

  .community__modal-sublist-title,
  .community__modal-sublist-features {
    margin-bottom: 0;
  }

  .community__modal-sublist-element--unavailable {
    position: relative;
    .community__modal-sublist-title,
    [class^="iconprop-"]:before {
      color: $gray;
    }

    .community__modal-link {
      cursor: auto;
      pointer-events: none;
    }

    .community__modal-sublist-unavailable {
      position: absolute;
      right: 0;
      bottom: 0;
      top: 0;
      left: 0;
      background-color: $styrolution-orange;
      color: white;
      text-align: center;
      display: none;
      align-items: center;
      width: 100%;
      justify-content: center;
    }

    &:hover {
      .community__modal-sublist-unavailable {
        display: flex;
      }
    }
  }

  .community__modal-sublist-element-inner {
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .community__modal-sublist-element + .community__modal-sublist-element,
  .community__modal-sublist-element:first-child {
    &:before {
      border-top: 1px solid $light-gray;
      content: '';
      display: block;
    }
  }

  .community__modal-sublist-title {
    text-transform: uppercase;
    color: $styrolution-green;
  }

  .community__modal-sublist-container {
    flex: 1 1 auto;
    padding-right: 10px;
  }

  .community__modal-footer {
    background-color: #fff;
    color: white;
    padding-bottom: 25px;
  }

  .modal__contact-title {
    margin-bottom: 0;
  }

  .modal__contact-mail {
    color: white;
    text-decoration: underline;
  }

  .modal__contact-description,
  .modal__contact-details,
  .modal__contact-image,
  .modal__contact-mail {
    margin-top: 10px;
    margin-bottom: 0;
  }

  .community__image-wrapper {
    display: none;
  }

  .community__element-mobile {
    align-items: center;

    a {
      display: flex;
      align-items: center;
      padding: 15px 10px;

      span {
        flex: 1;
      }

      [class^="iconprop-"], [class^="icon"] {
        flex: 0 0 auto;
        font-size: 30px;
        line-height: 30px;

        &:before {
          font-size: 20px;
          line-height: 20px;
        }
      }
    }
  }

  .community__element-mobile + .community__element-mobile {
    &:before {
      border-top: 1px solid #b9b9b9;
      content: '';
      display: block;
    }
  }

  .community__modal-link {
    padding: 0.2em 0.74em 0.2em;
    min-width: 48px;
    text-align: center;
  }

  .community__modal-link + .community__modal-link {
    position: relative;

    &:before {
      border-left: 1px solid $light-gray;
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
    }
  }

  .community__modal-link-container {
    position: relative;
    display: flex;
  }

  .community__modal-image-carousel-wrapper {
    position: relative;
    padding-bottom: 66.66666%;
    margin-top: 20px;
    overflow: hidden;
  }

  .community__modal-image-carousel {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .community__modal-category-mobile {
    color: $styrolution-blue;
    flex: 1 1 auto;
    align-items: center;
    text-transform: uppercase;
    display: none;

    @media screen and (min-width: $mediumScreen) {
      display: block;
    }
  }

  .community__modal-products-headline-mobile {
    margin-top: 10px;
  }

  @include breakpoint($medium-screen) {
    .community__modal-footer {
      .community__col {
        @include span-columns(2 of 4);
      }
    }
    .community-tabs {
      padding: 0;
      list-style: none;
      display: flex;
      flex-wrap: nowrap;
      transform: translateX(0);
      transition: transform 200ms;
      overflow: visible; 
    }

    .community-tabs-wrapper {
      margin-top: 30px;
      position: relative;
      overflow: hidden;

      .community-tabs__prev,
      .community-tabs__next {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 0;
        padding-bottom: 0;
        cursor: pointer;
        text-align: right;
        border: none;
        outline: none;
        background-color: white;
        z-index: 10;

        &.hidden {
          display: none;
        }
      }

      .community-tabs__prev {
        left: 0;
        .iconprop-arrow_forward {
          transform: scale(-1);
        }
      }
      .community-tabs__next {
        right: 0;
      }
    }

    .community-tab-community-list {
      display: none;
    }

    .community-tab-link {
      background: none;
      color: $styrolution-green;
      display: inline-block;
      cursor: pointer;
      text-transform: uppercase;
      font-weight: 600;
      white-space: nowrap;
      overflow: visible;
      margin-top: 2px;

      i {
        display: none;
      }

      a {
        padding: 12px 48px 12px 10px;
        height: 48px;
      }

      .iconprop-add-thin,
      .iconprop-minus {
        display: none !important;
      }

      &.current {
        border-bottom: 4px solid $styrolution-blue;
        color: $styrolution-blue;
        pointer-events: none;

        .community-tab-link-description {
          color: $styrolution-blue;
        }
      }
    }

    .community-tab-link-description {
      background-color: transparent;
      color: $styrolution-green;

      span {
        padding: 0;
        font-weight: bold;
        font-size: medium;
      }
    }

    .community-tab-content {
      display: none;

      // exclude the world map tab for the fix below as it has no flags
      &.current:not(#tab-0) {
        display: inherit;
        position: relative;
        height: 100%;

        // flags are positioned with % based on the height of the map background container including the height of
        // the legend on desktop resolutions (2 rows);
        // on mobile, the legend container is higher (1 row per flag type), so we subtract that height difference
        // to make sure the tab container always has the same relative height for the flags to be positioned correctly
        @media screen and (max-width: 900px) {
          height: calc(100% - 83px);
        }
      }
    }

    .community-tab-community-list {
      display: none !important;
    }

    .community__modal {
      width: 100%;
      display: flex;
      background-color: $white-hued;
      z-index: 1000;
      padding-top: 0;
      flex-direction: column;
      justify-content: stretch;
      overflow: hidden;
      position: relative;

      .wrapper {
        position: relative;
      }

      .popup-close-wrapper {
        display: none;
      }

      .popup-inner {
        flex: 1 1 auto;
        background-color: $white;
      }

      .popup-close {
        width: 100%;
        color: $styrolution-green;

        span {
          float: right;
        }
      }

    }

    .community__modal-category-mobile {
      display: none;
    }

    // modal footer
    .community__modal-footer {
      background-color: #FFF;
    }

    .community__modal--application-headline {
      margin-top: -10px;
    }

    .community__modal--content-headline {
      margin-top: 35px;
      display: block;
    }
    .community__modal--content-description {
      margin-top: 25px;
      margin-bottom: 0;
    }

    .community__modal-products-headline-mobile {
      display: none;
    }

    .modal__contact-right-wrapper {
      display: flex;
      align-items: flex-start;
    }

    .modal__contact-image {
      flex: 0 0 auto;
      margin-right: 10px;
    }

    .modal__contact-details {
      flex: 1 1 auto;
    }

    .modal__contact-headline {
      margin-top: 4px;
    }

    .community__image-wrapper {
      display: block;
      position: relative;
      width: 100%;
    }

    .community__element {
      z-index: 2;

      &--isOpen {
        z-index: 3 !important;

        .community__infoWindow {
          visibility: visible;
        }
      }
    }

    .community__image-wrapper--modal-open {
      padding-bottom: 0;

      .hotspot-image {
        display: none;
      }
    }

    .community__image-wrapper--community-visible {
      .community__element {
        display: block;
        z-index: 1;
      }
    }

    .community__more-applications {
      position: absolute;
      z-index: 1;
    }

    .hotspot-image {
      width: 100%;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
    }

    .community__element {
      //display: table;
      cursor: pointer;
    }

    .community__element-image {
      border: 2px solid white;
      padding: 5px;
      border-radius: 100%;
      visibility: hidden;

      > div {
        border: 1px solid white;
        padding: 5px;
        border-radius: 100%;
        transition: border-color 0.25s;

        > div {
          height: 20px;
          width: 20px;
          border-radius: 100%;
          border: 2px solid white;
          background-color: $styrolution-green;
          transition: border-color 500ms;
        }
      }
    }

    .community__element:hover {
      .community__element-image > div {
        border-color: $styrolution-green;
      }
    }
  }

  

    .community__infoWindow {
      border: 1px solid #737373;
      position: absolute;
      margin-left: 15px;
      visibility: hidden;
      background-color: $white;
      color: $styrolution-blue;
      text-align: left;
      padding: 10px 15px;
      z-index: 1001;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      margin-bottom: 0;
      white-space: nowrap;
      left: 45px;
      cursor: initial;
      width: 250px;
      height: auto;

      &:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 100%;
        transform: translateY(-50%);
        border-width: 10px;
        border-style: solid;
        border-color: transparent $styrolution-green transparent transparent;
      }

      ul li {
        white-space: normal;
        line-height: 13px;
        font-size: 13px;
      }

      .iconprop-cancel {
        position: absolute;
        right: 19px;
        top: -6px;

        &:before {
          font-size: 17px;
        }

        &:hover {
          cursor: pointer;
        }
      }

      &--onTheLeft {
        &:after {
          left: 100% !important;
          border-color: transparent transparent transparent $styrolution-green !important;
        }
      }
      
      &--worldwide {
        visibility: visible;

        .iconprop-cancel {
          display: none;
        }

        &:after {
          visibility: hidden;
        }
      }

      &CategoryItem {
        margin-bottom: 5px;

        @media screen and (min-width: 1000px) {
          margin-bottom: 10px;
        }

        &:last-child {
          margin-bottom: 5px;
        }

        &--children {
          color: $categoryChildrenColor;
        }

        &--generalOutreach {
          color: $categoryGeneralOutreachColor;
        }

        &--environmentalInitiatives {
          color: $categoryEnvironmentalInitiativesColor;
        }
      }

      &Button {
        color: $white;
        background-color: $styrolution-green;
        padding: 10px 20px;
        margin-top: 10px;
        display: inline-block;
      }

      &Headline {
        font-size: 16px;
        font-family: Arial;
        margin-bottom: 10px;

        & .icon-close {
          float: right;
          line-height: 25px;

          &:hover {
            cursor: pointer;
          }

          &:before {
            font-size: 25px;
          }
        }
      }
    }

    .community__clickableContinent .community__infoWindow {
      position: absolute;
      width: auto;
      height: auto;
      max-width: 250px;
    }

    .community__more-applications-hotspot {
      height: 50px;
      width: 50px;
      background-color: $styrolution-green;
      position: absolute;
      bottom: 100px;
      left: 100px;

      &.open {
        left: 500px;
        bottom: 220px;
      }
    }

    .community__more-applications {
      color: $white;
      bottom: 50px;
      left: 0;
      right: 0;

      .wrapper--more-applications {
        position: relative;
      }

      .hotspot {
        position: absolute;
        left: -70px;
        cursor: pointer;
        transform: translateY(-50%);
        top: 50%;

        .community__element-image {
          border: 2px solid white;
          padding: 5px;
          border-radius: 100%;

          > div {
            border: 1px solid white;
            padding: 5px;
            border-radius: 100%;

            > div {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 35px;
              width: 35px;
              border-radius: 100%;
              border: 2px solid white;
              background-color: $styrolution-green;
              transition: border-color 500ms;
            }
          }

          &:hover {
            > div {
              border: 1px solid $styrolution-green;
            }
          }
        }

        [class^="iconprop-"] {
          display: none;
          font-size: 15px;
          height: 15px;
          line-height: 15px;

          &:before {
            color: white;
            font-size: 15px;
          }
        }
      }

      .community__element {
        color: white;

        &:hover {
          color: $styrolution-green;
        }
      }

      .content {
        display: inline-block;
        overflow: visible;
        position: relative;
        height: auto;
        padding: 0 3px;
        margin-left: 60px;
        background-color: rgba(0, 0, 0, 0.5);
        transition: padding .4s;

        .community__more-applications-number {
          font-weight: bold;
          font-size: 16px;
        }

        &__inner {
          //max-height: 0;
          display: none;
          overflow: hidden;
          //transition: max-height 0.15s ease-out;
        }

        .content__headline {
          cursor: pointer;
          font-weight: bold;
          text-transform: uppercase;
          padding: 5px 0;
        }

        ul {
          margin-top: 15px;
          //display: none;
          list-style: none;

          .community__element {
            display: flex;
            align-items: center;

            .community__element-image {
              margin-right: 5px;

              > div {
                padding: 5px;
                border-color: white;

                > div {
                  height: 15px;
                  width: 15px;
                }
              }
            }

            &:hover {
              .community__element-image {
                > div {
                  border-color: $styrolution-green;
                }
              }
            }
          }
        }

        li {
          margin: 5px 5px 5px 0;
          cursor: pointer;

          + li {
            margin-top: 10px;
          }

          a {
            display: initial;
          }

          &:hover {
            color: $accented-font-color;
          }
        }
      }

      &.current {
        .community__more-applications-number {
          display: none;
        }

        .hotspot {
          left: calc(100% - 25px);
          top: 0;

          [class^="iconprop-"] {
            display: block;
          }

        }
        .content {
          padding: 20px 50px 20px 35px;
        }
      }
    }

    .wrapper--body {
      margin-top: 0;
    }
    .community__modal-image-carousel-wrapper {
      margin-top: 0;
    }

    .community__modal-product-list {
      margin-top: 30px;
    }
  

  @include breakpoint($large-screen) {
    .community__col {
      @include span-columns(2 of 4);
    }
    .wrapper--body {
      margin-top: 10px;
    }
    .community__modal-products-headline {
      margin-top: 0;
    }
    .community__modal-image-carousel-wrapper {
      margin-top: 4px;
    }
    .community__modal--content-description {
      margin-top: 35px;
    }
    .community__modal-footer {
      margin-top: 0;
    }
  }

  @include breakpoint($desktop-nav-screen) {
    .community__modal-image-carousel-wrapper {
      margin-top: 10px;
    }
  }

  .community__modal-products-headline {
    margin: 30px 0;
    font-family: Arial;
    font-size: 34px;
    line-height: 43px;
    color: #737373;
    display: none;

    @media screen and (min-width: $mediumScreen) {
      display: block;
    }
  }

  .wrapper--head {
    display: none;

    @media screen and (min-width: $mediumScreen) {
      display: block;
    }
  }

  .community__mapFlag {
    min-width: 44px;
    height: 66px;
    margin-top: -66px;
    transition: all .2s;

    &:hover {
      transform: scale(1.2);
    }
  }

  .community__readMore {
    width: 88%;
    background-color: #64A342;
    text-align: center;
    position: absolute;
    bottom: 20px;

    a {
      color: #FFF;
      display: block !important;
      padding: 10px;
    }
  }

  .slick-track {
    display: flex;
    align-items: stretch;
  }

  .world_st2:hover {
		cursor: pointer;
	}
	.world_st3:hover {
		cursor: pointer;
	}
	.world_st4:hover {
		cursor: pointer;
	}
}
