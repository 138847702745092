.results {
	@include media($large-screen-up) {
		max-width: $max-width;
		margin: 0 auto;
		padding-bottom: 60px;
	}
}

.button--more {
	@include media($large-screen-up) {
		display: inline-block;
	}
}