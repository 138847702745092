fieldset {
  background: lighten($base-border-color, 10);
  border: $base-border;
  margin: 0 0 ($base-spacing / 2) 0;
  padding: $base-spacing;
}

.modal fieldset {
	background: none;
	border: none;
	padding: 0;
}

input,
label,
select {
  display: block;
  font-family: $form-font-family;
  font-size: $form-font-size;
}

label {
  font-weight: 700;
  margin-bottom: $base-spacing / 4;

  &.required:after {
    content: "*";
  }

  abbr {
    display: none;
  }
}

textarea,
#{$all-text-inputs},
select[multiple=multiple],
select {
  box-sizing: border-box;
  @include transition(border-color);
  background-color: white;
  border: 1px solid $form-border-color;
  font-family: $form-font-family;
  font-size: $form-font-size;
  padding: ($base-spacing / 3) ($base-spacing / 3) ($base-spacing / 4);
  width: 100%;

	&:disabled {
    background: $lightest-gray;
  }

  &:not(:disabled):hover {
    border-color: $form-border-color-hover;
  }

  &:focus {
    border-color: $form-border-color-focus;
    box-shadow: $form-box-shadow-focus;
    outline: none;
  }
}

textarea {
  resize: vertical;
}

input[type="search"] {
  @include appearance(none);
  border-radius: 25px;
  padding-left: $base-spacing * 2;
  padding-right: $base-spacing;
}

input[type="checkbox"],
input[type="radio"] {
  display: inline;
  margin-right: $base-spacing / 4;
}

input[type="file"] {
  padding-bottom: $base-spacing / 2;
  width: 100%;
}

select {
	border-radius: 0;
	height: 36px;
	max-height: 240px;
  max-width: 100%;
}

select[size] {
	height: auto;
	max-height: none;
}

[id^="graduateForm"],
[id^="recruitingForm"] {
  margin-bottom: 25px;

  > form {
    > div {
      border: 1px solid $light-gray;
      margin-bottom: 25px;

      > table {
        margin: 0 !important;

        th {
          border-bottom: 1px solid white;

          label {
            margin-bottom: 0;
          }
        }
      }
    }

    select {
      height: 30px;
      line-height: 28px;
      margin-bottom: 0;
      width: 40%;
    }

    textarea {
      height: 150px;
    }

    > input[type="submit"] {
      background-color: $accented-background-color;
      color: white;
      border-color: $accented-border-color;

      &:hover {
        background-color: white;
        color: $accented-font-color;
      }
    }
  }
}

[data-toggled-formpart-id] {
	display: none;
}




// ******************************
// Additions for Styrolution
// 07-08, 2016
// ******************************
form {
	label {
		abbr.req {
			border: none;
			display: inline;
			text-decoration: none;
		}
	}
	
	.options {
		label {
			display: inline-block;
			font-weight: 500;
			margin: .2em 2em 0 -2px;
			max-width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			
			input {
				margin-left: 2px;
			}
		}
	}
	
	.validation-message {
		color: $styrolution-orange;
		display: none;
		font-size: 12px;
	}
	
	.help {
		float: right;
		font-size: 80%;
		position: relative;
		text-align: right;
		top: .2em;
	}
	
	> .note {
		padding: 0 1.5em 1.5em;
	}
	
	.note {
		p {
			margin: 0;
		}
		
		label {
			font-weight: 500;
		}
	}
	
	.fieldrow + .fieldrow,
	.inline-field + .inline-field {
		margin-top: 1em;
	}
	
	.fieldrow + .button {
		margin-top: .5em;
	}
	
	table.fieldmatrix {
		margin: 0 0 -10px;
		
		thead {
			display: none;
		}
		
		tr {
			display: block;
			
			&.validation-message {
				display: none;
			}
		}
		
		tr + tr {
			margin-top: 10px;
		}
		
		td,
		th {
			border: none;
			background: none;
			color: $base-font-color;
			display: block;
			padding: 0 0 10px;
		}
		
		td label {
			font-weight: 500;
		}
		
		@include media($medium-screen-up) {
			tr {
				display: table-row;
			}
			
			th,
			td {
				display: table-cell;
				padding-left: 10px;
			
				&:first-child {
					padding-left: 0;
				}
				
				&.col-s {
					width: 15%;
				}
				
				&.col-m {
					width: 20%;
				}
			}
			
			td label {
				display: none;
			}
		
			thead {
				display: table-header-group;
				
				td,
				th {
					font-weight: 500;
					padding-top: 0;
				}
			}
		}
	}
	
	.button-wrapper {
		border-top: 1px solid $lightest-gray;
		padding: 1em 1.5em 1.5em;

		&.button-wrapper--shop {
			padding-right: 0.7em;
		}
	}
	
	.incomplete-field,
	.invalid-field {
		label {
			color: $styrolution-orange;
		}
		
		.options label {
			color: $base-font-color;
		}
		
		textarea,
		#{$all-text-inputs},
		select[multiple=multiple],
		select {
			border-color: $styrolution-orange;
		}
		
		.validation-message {
			display: block;
		}
	}
	
	table.fieldmatrix.has-incomplete-field,
	table.fieldmatrix.has-invalid-field {
		tr.validation-message {
			display: block;
			
			td {
				color: $styrolution-orange;
				padding-bottom: 0;
			}
		}
		
		@include media($medium-screen-up) {
			tr.validation-message {
				display: table-row;
			}
		}
	}
	
	@include media($large-screen-up) {
		.inline-field + .inline-field {
			margin-top: 0;
		}
	}
	
	
	&.sign-in {
		background: $white-hued;
		
		fieldset {
			background: none;
			border: none;
			margin: 0;
		}
		
		.button-wrapper {
			border: none;
			padding-top: 0;
		}
	}
}


/** Filterable Select */
.filterable-select {
	position: relative;
	z-index: 2;
	
	ul,
	> input {
		border: 1px solid #ddd;
		background: #fff;
	}

	ul {
		border-top: none;
		display: none;
		max-height: 150px;  /* 10 items visible */
		overflow: auto;
		position: absolute;
		width: 100%;
	}

	li {
		padding: 0 8px;
	}

	li:first-child {
		display: none;
	}

	li:hover,
	li.selected {
		background: $styrolution-blue;
		color: $inverted-font-color;
	}

	li label {
		font-weight: 400;
		padding: 0;
	}

	li input {
		position: absolute;
		left: -9000px;
	}
}

.msg-error {
	color: #cc3300;
}
.msg-confirmation {
	color: $styrolution-green;
}