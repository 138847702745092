.latest-news {
	@extend %clearfix;
}

.shuffle-sizer {
	opacity: 0;
	visibility: hidden;
	display: inline-block;
	min-height: 30px;
	float: left;

	@include media(600px) {
		width: 50%;
		margin-left: 1%;
	}

	@include media($large-screen) {
		width: 24%;
		margin-left: 1%;
	}
}
