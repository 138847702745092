@charset "UTF-8";

// Default Variables

$slick-font-path: "./fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: $accented-font-color !default;
$slick-dot-color: #fff !default;
$slick-dot-color-active: $accented-font-color !default;
$slick-prev-character: '\f104' !default;
$slick-next-character: '\f105' !default;
$slick-dot-character: '\2022' !default;
$slick-dot-size: 26px !default;
$slick-opacity-default: .5 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: .5 !default;


@function slick-image-url($url) {
  @if function-exists(image-url) {
    @return image-url($url, false, false);
  }
  @else  {
    @return url($slick-loader-path + $url);
  }
}

@function slick-font-url($url) {
  @if function-exists(font-url) {
    @return font-url($url);
  }
  @else  {
    @return url($slick-font-path + $url);
  }
}

/* Slider */

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  .slick-loading & {
    //background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}
.slick-slider .slick-track {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  outline: none;
  [dir="rtl"] & {
      float: right;
  }

  > img {
    display: block;
    margin: 0 auto;

    @include media($medium-screen-up) {
      margin: 0;
    }
  }

  &.slick-loading img {
    display: none;
  }

  display: none;

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}

/* Arrows */

.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 60px;
  width: 40px;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  background: $accented-background-color;
  color: transparent;
  top: 30px;
  z-index: 999;
  text-align: center;
  padding: 0 15px;
  border: none;
  outline: none;

  &:hover, &:focus {
    outline: none;
    background: $accented-background-color;
    color: white;

    &:before {
      opacity: $slick-opacity-on-hover;
    }
  }

  &.slick-disabled:before {
    opacity: $slick-opacity-not-active;
  }

  .carousel__hero-news & {
    @include size(26px);
    background: transparent;
    box-shadow: none;
    color: $accented-font-color;
    top: 0;
  }
}

.slick-prev:before, .slick-next:before {
  font-family: Icomoon;
  font-size: 30px;
  line-height: 1;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .carousel__hero-news & {
    color: $accented-font-color;
    line-height: 0;
  }
}
.slick-prev {
  right: 40px;
  &:before {
    content: $slick-prev-character;
  }

  .carousel__hero-news & {
    right: 30px;
  }
}
.slick-next {
  right: 20px;
  &:before {
    content: $slick-next-character;
  }

  .carousel__hero-news & {
    right: 0;
  }
}

/* Dots */

.slick-slider {
  //margin-bottom: 30px;
}
.slick-dots {
  // position: absolute;
  bottom: -45px;
  list-style: none;
  display: block;
  // text-align: center;
  text-align: right;
  padding: 0;
  width: 100%;

  li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;

    button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0;
      font-size: 0;
      color: transparent;
      padding: 5px;
      cursor: pointer;
      &:hover, &:focus {
        outline: none;
        &:before {
          opacity: $slick-opacity-on-hover;
        }
      }

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: $slick-dot-character;
        width: 20px;
        height: 20px;
        font-family: $slick-font-family;
        font-size: $slick-dot-size;
        line-height: 20px;
        text-align: center;
        color: $slick-dot-color;
        opacity: $slick-opacity-not-active;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }

    &.slick-active button:before {
      color: $slick-dot-color-active;
      opacity: $slick-opacity-on-hover;
    }
  }
}

.slick-custom-directions {
  span {
    display: block;
    @include size(52px 80px);
    font-size: 50px;
    line-height: 0.7;
    padding: 20px;
    color: white;
    background-color: $accented-background-color;
    @include position(absolute, 50% null null null);

    &#slick-prev-snapshot {
      left: 0;
    }

    &#slick-next-snapshot {
      right: 0;
    }

    &:hover {
      cursor: pointer;
      cursor: hand;
    }

    @include media($large-screen-up) {
      opacity: 0;
      @include transition(opacity 0.2s);
    }
  }
}

.feature-snapshot {
  &:hover {
    span {
      @include media($large-screen-up) {
        opacity: 1;
      }
    }
  }
}
