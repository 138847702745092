.search-results {
	padding-top: 0;
}

.gstl_50.gssb_c {
  top: 105px !important;
}

.gsc-above-wrapper-area-container {
	margin: 0;
  position: relative;
  top: -75px;
  margin-bottom: -75px;

	td {
		border: none;
    padding: 1.35em 0px;
	}

  tr {
    background-color: transparent;
  }
}

.gsc-resultsHeader {
	display: none;
}

.gsc-table-result {
	margin: 0;

  tr {
    background-color: transparent;
  }
}

.gsc-control-cse .gs-spelling {
  font-size: 16px;
  font-weight: normal;
}

.gs-title {
	font-weight: 700;
	font-size: 18px;
	color: $accented-font-color;
	margin-bottom: 5px;

	b {
		padding: 4px 0;
		background-color: tint($accented-background-color, 70%);
	}
}


.gs-spelling a {
  font-size: 16px;
  font-weight: bold;
  color: #FAA634;
}

.gsc-table-cell-snippet-close {
  display: block;
  padding-top: 15px;
	padding-left: 0;
	padding-right: 0;

  @include media($large-screen-up) {
  	padding-left: 190px;
    width: 100%;
  }
}

.gsc-webResult .gsc-result {
  @include clearfix;
  margin: 0;
  padding: 0;
  position: relative;
}

.gsc-result-info {
  text-align: left;
  font-size: 0.8em;
  margin: 10px 0;
	position: relative;
	top: 5px;

	@include media($large-screen-up) {
		position: static;
		top: auto;
	}
}

.gsc-thumbnail {
	display: none;

	@include media($large-screen-up) {
		display: block;
		position: absolute;
	}
}

a.gs-image {
  position: absolute;
  top: 15px;
  left: 0;
  display:block;
  height:92px;
  width:174px;
  margin-right: 10px;
  overflow: hidden;
}

img.gs-image {
  max-width: 174px;
}

.gs-snippet {
	font-size: 14px;
  margin-bottom: 5px;
}

/* By default show url at the bottom of the snippet */
.gsc-url-top {
  display: none;
}

.gsc-url-bottom {
  display: block;
}

/* By default show thumbnail at the left of the snippet */
.gsc-thumbnail-left {
  display: block;
}

.gsc-thumbnail-inside {
  display: none;
}

/* specialized, result type specific, fine grained controls */
.gsc-result div.gs-watermark {
  display: none;
}

.gs-visibleUrl-short {
  display: none;
}

.gs-result a.gs-visibleUrl,
.gs-result .gs-visibleUrl {
  font-size: 14px;
  color: $light-gray;
}

/* tabbed mode of search control */
.gsc-tabsAreaInvisible,
.gsc-refinementsAreaInvisible,
.gsc-refinementBlockInvisible {
  display : none;
}

.gsc-tabData .gsc-resultsHeader .gsc-title {
  display: none;
  padding-left: 30px;
}

.gsc-tabData .gsc-resultsHeader .gsc-stats {
  display: none;
}

.gsc-tabData .gsc-resultsHeader .gsc-results-selector {
  display: none;
}

.gsc-cursor {
	@include clearfix;
}

/* pagination */
.gsc-results .gsc-cursor-box .gsc-cursor-page {
  cursor: pointer;
  padding: 10px 15px;
  margin-right:10px;
  float:left;
  position:relative;
  font-weight: 700;
  line-height:1;
  font-size:16px;
  color: $accented-font-color;

	&:hover {
		color: $gray;
	}
}

.gsc-results .gsc-cursor-box {
  padding: 10px 0;
  border-top: 2px solid $light-gray;
  margin-top: -1px;
}

.gsc-results .gsc-cursor-box .gsc-cursor-current-page,
.gsc-results .gsc-cursor-box .gsc-cursor-current-page:hover {
  cursor : default;
  font-weight: bold;
  color: white;
  background-color: $gray;
}

//--Typeahead--Dropdown
.gssb_c {
  z-index: 30 !important;
  margin: 0 0 0 -10px;
}
.gssb_e {
  padding: 5px 10px !important;
  background-color: white !important;
  border: 1px solid $accented-border-color !important;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.4) !important;
}
.gsc-completion-container {
  background :white;
  border-bottom: 1px solid $lightest-gray;
  border-top: 1px solid $light-gray;
  margin-top: 0;
  margin-bottom: 0;

}
.gsc-completion-container td {
  color: $gray;
  padding: 4px 10px;
  background-color: transparent;
}

.gsc-completion-container tr {
  background-color: transparent;
}

tr.gsc-completion-selected {
	background-color: $lightest-gray;

  td {
    color: $accented-font-color;
  }
}

/* remove branding */
.gcsc-branding {
  display:none;
}

.search-results {

	&__search-box {
		position: relative;
		height: 62px;
		margin-bottom: 1em;

		#inline-search {
			display: block;
			font-size: 1em;
			padding: 20px 20px 20px 60px;
		}
	}

	h3 {
		font-size: 1.5em;
	}

	.sty-results {
		margin-top: 1em;

		&>a {
			margin-bottom: 3em;
			display: block;
		}
	}

	.paging-navigation {
		float: right;
	}

	&__list {
		ul {
			border-bottom: 0;
			color: $styrolution-green;

			@include media($large-screen) {
				border-bottom: 1px solid #cccccc;
			}
		}
	}

	&__amount, &__filter-label {
		margin-top: 0.5em;
		padding-bottom: 0.5em;
		display: block;
	}

	&__amount {

		color: $light-gray;

		border-bottom: 1px solid $lightest-gray;

	}

	&__filter-label {
		text-transform: uppercase;
	}

	&__item {
		border-bottom: 1px solid $lightest-gray;
		padding-top: 1em;
	}
	&__meta-info {
		color: $light-gray;
		margin-bottom: 0;
	}
	&__path {
		color: $light-gray;
	}
	&__abstract {
		margin-bottom: 0;
	}
	.paging-navigation {
		margin-top: 2em;
	}

	#search-results__filter {
		fieldset {
			border: none;
			background-color: $white-hued;
		}
		label {
			font-weight: normal;
			display: inline;
		}
		.radio {
			display: inline-block;
			margin-right: 2em;
		}
	}

	.sec-nav {
		ul>li {
			a {
				text-transform: none;

				&.plus {
					&:before {
						@include icon-styles;
						@include icon(to-lower-case("add"));
					}
				}


				&.all-results {
					padding: 1.5em 0;
					&:before {
						content: "";
					}
				}
				&.inactive {
					color: $lightest-gray;
					&:before {
						content: "";
					}
				}
				&.minus {
					&:before {
						@extend %icon;
					}

					@extend %icon-minus;
				}
				&.current {
					&:before {
						@extend %icon;
					}

					@extend %icon-angle-right;
				}
			}

			ul>li {
				&:first-child {
					margin-top: 1.2em;
				}
				&:last-child {
					margin-bottom: 1.2em;
				}

				a {
					&:before {
						content: "";
					}
					border: none;
					padding: 0.3em 0 0.3em 1em;
				}
			}
		}
	}

}