.history-container {
	position: relative;
	overflow: auto;

	@include media($large-screen-up) {
		padding-top: 60px;
		padding-bottom: 60px;
	}

	&:before {
		content: '';
		@include position(absolute, 0 null null 0);
		width: 6px;
		height: 100%;
		background-color: $blue;

		@include media($large-screen-up) {
			left: 50%;
			margin-left: -3px;
		}
	}
}

.history__block {
	position: relative;
	color: $blue;
	border-bottom: 4px solid;
	@extend %clearfix;

	&:last-of-type {
		margin-bottom: 30px;
	}
}

.history__block__image,
.history__block__content {
	padding: 18px;
}

.history__block__content {
	font-weight: 500;
	font-size: 13px;
	text-transform: uppercase;
	width: 75%;
	box-shadow: 0 1px 0 $blue;
	position: relative;

	p {
		margin-bottom: 0;
	}

	strong {
		font-weight: normal;
		color: $accented-font-color;
	}
}

.history__block__year {
	font-weight: 700;
	font-size: 36px;
	@include position(absolute, null 0 0 null);
}

@include media($large-screen-up) {
	.history__block__image {
		position: absolute;
		left: -72%;
		width: 220px;
	}

	.history__block__content {
		font-size: 14px;
		line-height: 17px;
		width: 80%;
		padding-top: 50px;

		strong {
			font-size: 18px;
		}
	}

	.history__block__year {
		font-size: 50px;
		right: -110px;
		bottom: -23px;
		line-height: 1;
	}

	.history__block {
		width: 35%;

		&:nth-child(odd) {
			left: 50%;
		}

		&:nth-child(even) {
			left: 15%;

			.history__block__content {
				left: 20%;
				text-align: right;
			}

			.history__block__image {
				left: 100%;
			}

			.history__block__year {
				right: auto;
				left: -110px;
			}
		}
	}

	.history__block__image,
	.history__block__content,
	.history__block__year {
		opacity: 0;

		.ie8 &,
		.ie9 & {
			opacity: 1;
		}

		&.already-visible {
			opacity: 1;
		}
	}
}

@include media($max-width) {
	.history__block__image {
		left: -57%;
	}
}
