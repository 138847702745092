@font-face {
  font-family: "IconProp";
  src: url('../fonts/icons/IconProp.eot');
  src: url('../fonts/icons/IconProp.eot?#iefix') format('eot'),
  url('../fonts/icons/IconProp.woff') format('woff'),
  url('../fonts/icons/IconProp.ttf') format('truetype'),
  url('../fonts/icons/IconProp.svg#IconProp') format('svg');
}

@mixin icon-styles {
  position: relative;
  font-family: "IconProp";
  line-height: 1;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  speak: none;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@function icon-char($filename) {
  $char: "";

@if $filename == to-lower-case("Lightweight") {
$char: "\E001";
}
@if $filename == to-lower-case("Multicolor") {
$char: "\E002";
}
@if $filename == to-lower-case("add-thin") {
$char: "\E003";
}
@if $filename == to-lower-case("add") {
$char: "\E004";
}
@if $filename == to-lower-case("arrow_down") {
$char: "\E005";
}
@if $filename == to-lower-case("arrow_forward") {
$char: "\E006";
}
@if $filename == to-lower-case("arrowup") {
$char: "\E007";
}
@if $filename == to-lower-case("bookmark") {
$char: "\E008";
}
@if $filename == to-lower-case("calendar") {
$char: "\E009";
}
@if $filename == to-lower-case("cancel") {
$char: "\E00A";
}
@if $filename == to-lower-case("check") {
$char: "\E00B";
}
@if $filename == to-lower-case("chemical_resistance") {
$char: "\E00C";
}
@if $filename == to-lower-case("copy") {
$char: "\E00D";
}
@if $filename == to-lower-case("create") {
$char: "\E00E";
}
@if $filename == to-lower-case("delete") {
$char: "\E00F";
}
@if $filename == to-lower-case("dimensional_stability") {
$char: "\E010";
}
@if $filename == to-lower-case("document-search") {
$char: "\E011";
}
@if $filename == to-lower-case("download") {
$char: "\E012";
}
@if $filename == to-lower-case("edit") {
$char: "\E013";
}
@if $filename == to-lower-case("electroplating") {
$char: "\E014";
}
@if $filename == to-lower-case("extrusion") {
$char: "\E015";
}
@if $filename == to-lower-case("flowability") {
$char: "\E016";
}
@if $filename == to-lower-case("heat_resistance") {
$char: "\E017";
}
@if $filename == to-lower-case("high_gloss") {
$char: "\E018";
}
@if $filename == to-lower-case("impact_strength") {
$char: "\E019";
}
@if $filename == to-lower-case("info") {
$char: "\E01A";
}
@if $filename == to-lower-case("last-one") {
$char: "\E01B";
}
@if $filename == to-lower-case("long_term_property_retention") {
$char: "\E01C";
}
@if $filename == to-lower-case("low_emission") {
$char: "\E01D";
}
@if $filename == to-lower-case("low_gloss") {
$char: "\E01E";
}
@if $filename == to-lower-case("low_temperature_toughness") {
$char: "\E01F";
}
@if $filename == to-lower-case("mail") {
$char: "\E020";
}
@if $filename == to-lower-case("medical") {
$char: "\E021";
}
@if $filename == to-lower-case("minus") {
$char: "\E022";
}
@if $filename == to-lower-case("order") {
$char: "\E023";
}
@if $filename == to-lower-case("paintable") {
$char: "\E024";
}
@if $filename == to-lower-case("pdf") {
$char: "\E025";
}
@if $filename == to-lower-case("phone") {
$char: "\E026";
}
@if $filename == to-lower-case("print") {
$char: "\E027";
}
@if $filename == to-lower-case("reinforcement") {
$char: "\E028";
}
@if $filename == to-lower-case("rigidity_stiffness") {
$char: "\E029";
}
@if $filename == to-lower-case("scratch_resistance") {
$char: "\E02A";
}
@if $filename == to-lower-case("search") {
$char: "\E02B";
}
@if $filename == to-lower-case("selfcolorability") {
$char: "\E02C";
}
@if $filename == to-lower-case("transparency") {
$char: "\E02D";
}
@if $filename == to-lower-case("update") {
$char: "\E02E";
}
@if $filename == to-lower-case("upload") {
$char: "\E02F";
}
@if $filename == to-lower-case("user") {
$char: "\E030";
}
@if $filename == to-lower-case("uv_resistance_and_color_fastness") {
$char: "\E031";
}

@return $char;
}

@mixin icon-special-treatment($filename) {
  // add default modifications to specific icons here

  @if $filename == "chevron-right" {
    font-size: 65%;
    padding-right: 5px;
  }
}

@mixin icon($filename, $extend: false, $special: true) {
  @if $extend {
    @include icon-styles;
  }
  content: icon-char($filename);

  @if $special {
    @include icon-special-treatment($filename);
  }
}

[class^="iconprop-"],
[class*=" iconprop-"],
.icon {
  &:before {
    @include icon-styles;
  }
}


                                    .iconprop-#{to-lower-case("Lightweight")} {
                                      &:before {
                                        @include icon(to-lower-case("Lightweight"));
                                      }
                                    }

                                    .iconprop-#{to-lower-case("Multicolor")} {
                                      &:before {
                                        @include icon(to-lower-case("Multicolor"));
                                      }
                                    }

                                    .iconprop-#{to-lower-case("add-thin")} {
                                      &:before {
                                        @include icon(to-lower-case("add-thin"));
                                      }
                                    }

                                    .iconprop-#{to-lower-case("add")} {
                                      &:before {
                                        @include icon(to-lower-case("add"));
                                      }
                                    }

                                    .iconprop-#{to-lower-case("arrow_down")} {
                                      &:before {
                                        @include icon(to-lower-case("arrow_down"));
                                      }
                                    }

                                    .iconprop-#{to-lower-case("arrow_forward")} {
                                      &:before {
                                        @include icon(to-lower-case("arrow_forward"));
                                      }
                                    }

                                    .iconprop-#{to-lower-case("arrowup")} {
                                      &:before {
                                        @include icon(to-lower-case("arrowup"));
                                      }
                                    }

                                    .iconprop-#{to-lower-case("bookmark")} {
                                      &:before {
                                        @include icon(to-lower-case("bookmark"));
                                      }
                                    }

                                    .iconprop-#{to-lower-case("calendar")} {
                                      &:before {
                                        @include icon(to-lower-case("calendar"));
                                      }
                                    }

                                    .iconprop-#{to-lower-case("cancel")} {
                                      &:before {
                                        @include icon(to-lower-case("cancel"));
                                      }
                                    }

                                    .iconprop-#{to-lower-case("check")} {
                                      &:before {
                                        @include icon(to-lower-case("check"));
                                      }
                                    }

                                    .iconprop-#{to-lower-case("chemical_resistance")} {
                                      &:before {
                                        @include icon(to-lower-case("chemical_resistance"));
                                      }
                                    }

                                    .iconprop-#{to-lower-case("copy")} {
                                      &:before {
                                        @include icon(to-lower-case("copy"));
                                      }
                                    }

                                    .iconprop-#{to-lower-case("create")} {
                                      &:before {
                                        @include icon(to-lower-case("create"));
                                      }
                                    }

                                    .iconprop-#{to-lower-case("delete")} {
                                      &:before {
                                        @include icon(to-lower-case("delete"));
                                      }
                                    }

                                    .iconprop-#{to-lower-case("dimensional_stability")} {
                                      &:before {
                                        @include icon(to-lower-case("dimensional_stability"));
                                      }
                                    }

                                    .iconprop-#{to-lower-case("document-search")} {
                                      &:before {
                                        @include icon(to-lower-case("document-search"));
                                      }
                                    }

                                    .iconprop-#{to-lower-case("download")} {
                                      &:before {
                                        @include icon(to-lower-case("download"));
                                      }
                                    }

                                    .iconprop-#{to-lower-case("edit")} {
                                      &:before {
                                        @include icon(to-lower-case("edit"));
                                      }
                                    }

                                    .iconprop-#{to-lower-case("electroplating")} {
                                      &:before {
                                        @include icon(to-lower-case("electroplating"));
                                      }
                                    }

                                    .iconprop-#{to-lower-case("extrusion")} {
                                      &:before {
                                        @include icon(to-lower-case("extrusion"));
                                      }
                                    }

                                    .iconprop-#{to-lower-case("flowability")} {
                                      &:before {
                                        @include icon(to-lower-case("flowability"));
                                      }
                                    }

                                    .iconprop-#{to-lower-case("heat_resistance")} {
                                      &:before {
                                        @include icon(to-lower-case("heat_resistance"));
                                      }
                                    }

                                    .iconprop-#{to-lower-case("high_gloss")} {
                                      &:before {
                                        @include icon(to-lower-case("high_gloss"));
                                      }
                                    }

                                    .iconprop-#{to-lower-case("impact_strength")} {
                                      &:before {
                                        @include icon(to-lower-case("impact_strength"));
                                      }
                                    }

                                    .iconprop-#{to-lower-case("info")} {
                                      &:before {
                                        @include icon(to-lower-case("info"));
                                      }
                                    }

                                    .iconprop-#{to-lower-case("last-one")} {
                                      &:before {
                                        @include icon(to-lower-case("last-one"));
                                      }
                                    }

                                    .iconprop-#{to-lower-case("long_term_property_retention")} {
                                      &:before {
                                        @include icon(to-lower-case("long_term_property_retention"));
                                      }
                                    }

                                    .iconprop-#{to-lower-case("low_emission")} {
                                      &:before {
                                        @include icon(to-lower-case("low_emission"));
                                      }
                                    }

                                    .iconprop-#{to-lower-case("low_gloss")} {
                                      &:before {
                                        @include icon(to-lower-case("low_gloss"));
                                      }
                                    }

                                    .iconprop-#{to-lower-case("low_temperature_toughness")} {
                                      &:before {
                                        @include icon(to-lower-case("low_temperature_toughness"));
                                      }
                                    }

                                    .iconprop-#{to-lower-case("mail")} {
                                      &:before {
                                        @include icon(to-lower-case("mail"));
                                      }
                                    }

                                    .iconprop-#{to-lower-case("medical")} {
                                      &:before {
                                        @include icon(to-lower-case("medical"));
                                      }
                                    }

                                    .iconprop-#{to-lower-case("minus")} {
                                      &:before {
                                        @include icon(to-lower-case("minus"));
                                      }
                                    }

                                    .iconprop-#{to-lower-case("order")} {
                                      &:before {
                                        @include icon(to-lower-case("order"));
                                      }
                                    }

                                    .iconprop-#{to-lower-case("paintable")} {
                                      &:before {
                                        @include icon(to-lower-case("paintable"));
                                      }
                                    }

                                    .iconprop-#{to-lower-case("pdf")} {
                                      &:before {
                                        @include icon(to-lower-case("pdf"));
                                      }
                                    }

                                    .iconprop-#{to-lower-case("phone")} {
                                      &:before {
                                        @include icon(to-lower-case("phone"));
                                      }
                                    }

                                    .iconprop-#{to-lower-case("print")} {
                                      &:before {
                                        @include icon(to-lower-case("print"));
                                      }
                                    }

                                    .iconprop-#{to-lower-case("reinforcement")} {
                                      &:before {
                                        @include icon(to-lower-case("reinforcement"));
                                      }
                                    }

                                    .iconprop-#{to-lower-case("rigidity_stiffness")} {
                                      &:before {
                                        @include icon(to-lower-case("rigidity_stiffness"));
                                      }
                                    }

                                    .iconprop-#{to-lower-case("scratch_resistance")} {
                                      &:before {
                                        @include icon(to-lower-case("scratch_resistance"));
                                      }
                                    }

                                    .iconprop-#{to-lower-case("search")} {
                                      &:before {
                                        @include icon(to-lower-case("search"));
                                      }
                                    }

                                    .iconprop-#{to-lower-case("selfcolorability")} {
                                      &:before {
                                        @include icon(to-lower-case("selfcolorability"));
                                      }
                                    }

                                    .iconprop-#{to-lower-case("transparency")} {
                                      &:before {
                                        @include icon(to-lower-case("transparency"));
                                      }
                                    }

                                    .iconprop-#{to-lower-case("update")} {
                                      &:before {
                                        @include icon(to-lower-case("update"));
                                      }
                                    }

                                    .iconprop-#{to-lower-case("upload")} {
                                      &:before {
                                        @include icon(to-lower-case("upload"));
                                      }
                                    }

                                    .iconprop-#{to-lower-case("user")} {
                                      &:before {
                                        @include icon(to-lower-case("user"));
                                      }
                                    }

                                    .iconprop-#{to-lower-case("uv_resistance_and_color_fastness")} {
                                      &:before {
                                        @include icon(to-lower-case("uv_resistance_and_color_fastness"));
                                      }
                                    }
