.breadcrumbs {
	position: static;
	background-color: white;
	padding: 5px 0;

	&.breadcrumbs--sticky,
	&.breadcrumbs--sticky--single {
		@include position(fixed, 70px 0 null 0);
		z-index: 98;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);

		.no-rgba &:after {
			content: '';
			display: block;
			height: 3px;
			position: relative;
			bottom: -8px;
			background-image: url(../img/old-ie/ie-header-drop-shadow.png);
			background-repeat: repeat-x;
			background-position: 0 0;
		}
	}

	&.breadcrumbs--sticky--single {
		top: 0;
	}
}

.breadcrumbs-replace {
	height: 34px;
}

.breadcrumb-link {
	font-size: 14px;
	color: $gray;

	&:not(:last-of-type) {
		margin-right: $base-spacing / 2;

		&:after {
			content: '/';
			margin-left: $base-spacing / 2;
			color: $gray;
		}
	}

	&:last-of-type {
		color: $accented-font-color;
	}
}
