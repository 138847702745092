$menu-mobile-width: 260px;
$menu-tablet-width: 360px;
$menu-font-size: 13px;


// Main navigation
.nav {
	@include media($large-screen-up) {
		position: relative;
		background-color: $styrolution-green;
		z-index: 4;
	}

	@include media($medium-screen-up) {
		padding: 3px 10px 3px 20px;

		.ie8 &,
		.ie9 & {
			padding-top: 0;
			padding-bottom: 0;
		}
	}
}

.main-nav {
	@extend %clearfix;
	@include position(fixed, 0 (-$menu-mobile-width) null null);
	height: 100%;
	width: $menu-mobile-width;
	@include transition(right 0.3s);
	background-color: #fff;
	overflow: auto;
	z-index: 1;

	&:before {
		content: '';
		display: block;
		@include position(fixed, 0 0 0 0);
		background-color: white;
	}

	&.nav-is-visible {
		right: 0;
		z-index: 9; /* higher than main content (and .header-popup!) */
		&:before {
			content: none;
		}
	}

	ul {
		padding-top: 165px;

		&.subnav__links, &.subnav__secondary {
			padding-top: 0;
		}

		> li {
			&.external-link {
				display: list-item;

				@include media($large-screen-up) {
					display: none;
				}
			}
		}
	}

	li {
		a {
			font-size: $menu-font-size;
			color: $gray;
			text-transform: uppercase;
			padding: 12px 50px 12px 20px;
			border-top: 1px solid $lightest-gray;
			@include ellipsis;
			display: block;
			position: relative;

			&.selected {
				color: $styrolution-blue;
			}

			&.button--nav {
				font-weight: 700;
				font-size: $menu-font-size;
				color: $dark-gray;
				padding: .65em 1em .75em;
				border: 1px solid $light-gray;
				padding-left: 1em !important;

				&:hover {
					color: $styrolution-green;
					border-color: $styrolution-green;
				}
			}
		}

		&.current {
			> a,
			> a:after {
				color: $styrolution-blue !important;
			}
		}

		&.has-children {
			> a:after {
				content: '\e114';
				font-family: Icomoon;
				color: $styrolution-green;
				@include position(absolute, null 20px null null);
			}

			> a:before {
				bottom: -1px;
				content: '';
				border-bottom: 3px solid $styrolution-blue;
				display: none;
				left: 0;
				position: absolute;
				width: 100%;
			}

			> a.selected:after {
				color: $styrolution-blue;
				content: '\e115';

				@include media($large-screen-up) {
					content: '\f0d7';
				}
			}

			> a.selected:before {
				@include media($large-screen-up) {
					display: block;
				}
			}
		}
	}

	ul.tools {
		position: absolute;
		top: 0;

		padding: 0 12px .5em;
		text-align: center;

		> li {
			vertical-align: top;
			font-size: $menu-font-size;
			padding: .8em 0 .5em;
			text-align: left;

			> a {
				border: none;
				display: inline;
				padding: 0;
				text-transform: none;
			}

			> a,
			.dropdown__button {
				font-weight: 700;
			}

			.clipped {
				max-width: 80%;
			}

			.greeting {
				display: block;
				margin-bottom: .2em;
			}

			&.external-link {
				padding: 0;
				display: none;
				margin-left: 7px;

				a {
					font-size: 14px;
					line-height: 35px;
					padding: 0 0px;
					text-transform: uppercase;
					position: relative;
					font-weight: normal;
				}

				@include media($large-screen-up) {
					display: inline-block;
				}

			}
		}

		> li.icon {
			display: inline-block;
			padding: 0;
			text-align: center;

			a {
				border: none;
			}

			> a,
			.languages-dd .dropdown__button {
				font-size: 30px;
				font-weight: 400;
				line-height: 35px;
				margin: 0 4px 0 8px;
				padding: 4px;
				text-align: center;
				width: 1.2em;
			}

			.dropdown__menu {
				margin-top: 0;
			}

			.status {
				position: relative;
				top: 0.2em;

				& + i {
					margin-left: .2em;
				}
			}

			.icon-cart {
				position: relative;
				top: 0.12em;
			}

			[class="icon-bookmark"] {
				margin-left: -.2em;
				&:before {
					font-size: 1em;
					position: relative;
					top: 0.2em;
				}
			}
		}

		.language-indicator {
			line-height: 1;
			top: 4px;
		}

		.dropdown {
			text-align: left;
			a {
				border: none;
				padding-left: 0;
				padding-right: 0;
			}
			a.button {
				@extend %button;
				font-size: inherit;
			}
		}

		.dropdown__container {
			position: static;
		}

		.dropdown__menu {
			background: $base-background-color;
			border-top: none;
			box-shadow: 0 4px 4px 2px rgba(0, 0, 0, .4);
			left: 2%;
			margin-top: 0;
			position: absolute;
			right: auto;
			text-align: left;
			width: 96%;
			z-index: 1;

			li:first-child a {
				border-top: none;
			}
		}

		.dropdown__button {
			border: none;
			float: none;
			font-size: $menu-font-size;
			line-height: $base-line-height;

			&:after {
				display: inline;
				margin-left: .4em;
				position: static;
				vertical-align: top;
			}
		}
	}

	@include media($medium-screen-up) {
		right: (-$menu-tablet-width);
		width: $menu-tablet-width;
	}

	@include media($large-screen-up) {
		position: static;
		width: auto;
		padding-top: 0;
		max-width: $max-width;
		margin: 0 auto;
		overflow: visible;
		background-color: transparent;

		.ie8 &,
		.ie9 & {
			padding-top: 3px;
		}

		&:before {
			content: none;
		}

		li {
			display: inline-block;

			a {
				color: $inverted-font-color;
				display: inline-block;
				border-top: none;
				overflow: initial;
				padding: 0 10px;

				&:hover {
					color: $styrolution-blue;
				}

				&.selected {
					position: relative;
					z-index: 5;
				}
			}

			> a {
				height: 36px;
				line-height: 37px;
			}

			&:first-child {
				a {
					padding-left: 0;
				}
			}

			&.has-children {
				> a {
					padding-right: 18px;
				}

				> a:after {
					content: '\f0d7';
					color: $inverted-font-color;
					margin-left: 5px;
					font-size: 12px;
					top: 0;
					right: 5px;
				}

				> a:hover:after {
					color: $styrolution-blue;
				}
			}
		}

		.nav-groups {
			float: left;
			margin-left: -5px;
			width: 100%;

			> ul {
				float: left;
				padding: 0;
				white-space: nowrap;


				&.tools {
					position: relative;
					float: left;

					&.float-right {
						float: right;
					}
				}

				li {
					white-space: normal;
				}
			}
		}

		ul.tools {
			border: none;

			li.icon {
				> a,
				.languages-dd .dropdown__button {
					font-size: 24px;
					line-height: 1;
					padding: 0;
					vertical-align: text-top;
					width: 1em;
				}
			}

			li > a {
				padding: 0;
			}
		}
	}

	@include media($very-large-screen-up) {
		li {
			> a {
				//line-height: 35px;
				//padding: 0 8px;
			}
		}
	}
}

.subnav {
	background-color: #F1F1F1;

	.close {
		display: none;
	}

	li,
	li.has-children {
		a {
			text-transform: capitalize;

			&:after {
				content: '\f105';
				font-family: Icomoon;
				color: $light-gray;
				@include position(absolute, null 25px null null);
			}
		}
	}

	/**
	 * only for products!
	 */
	&.products .subnav__links>li>a {
		font-weight: bold;
	}

	@include media($large-screen-up) {
		.selected + & {
			padding: 30px 20px;
			box-shadow: 0 2px 2px rgba(0,0,0,0.2);
		}

		.close {
			color: $light-gray;
			cursor: pointer;
			display: block;
			position: absolute;
			right: 0;
			top: -16px;

			&:hover {
				color: $accented-font-color;
			}
		}
	}

	@include media($large-screen-up) {
		@include clearfix;
		background-color: #F8F8F8;
		@include position(absolute, 40px null null 0);
		width: 100%;
		z-index: 3;

		li,
		li.has-children {
			display: block;
			width: 240px;
			border-top: 1px solid $lightest-gray;
			border-bottom: 1px solid $lightest-gray;
			margin-bottom: -1px;

			a {
				color: $base-font-color;
				display: block;
				padding-right: 0;
				font-size: $menu-font-size;

				&:hover {
					color: $styrolution-green;
				}

				&:after {
					float: none;
					@include position(absolute, null 0 null null);
					color: $styrolution-green;
				}

				.ie8 & {
					background-image: url('../img/old-ie/ie-menu-right-arrow.png');
					background-repeat: no-repeat;
					background-position: center right;
				}

				.ie8 .nav-to-section & {
					background-image: none;
				}
			}

			> a {
				text-transform: uppercase;
				padding-left: 0;
				padding-right: 20px;
				height: 33px;
				display: block;
				position: relative;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				font-weight: 500;
			}
		}

		li {
			float: left;
			margin-right: 20px;
		}

		.selected + & {
			border-top: 2px solid $white;
		}

		.col-lg-3 {
			margin-right: 0;
			width: 260px;
		}
	}
}

.subnav__secondary {
	li {
		a {
			padding-left: 20px;

			&:before {
				content: '\e115';
				font-family: Icomoon;
				font-size: 12px;
				color: $styrolution-green;
				margin-right: 10px;
			}
		}
	}

	@include media($large-screen-up) {
		li {
			border-top: 1px dashed $lightest-gray;
			border-bottom: 1px dashed $lightest-gray;

			a {
				padding-left: 0;
				text-transform: none;

				&:before {
					margin-right: 5px;
				}
			}
		}
	}
}

.subnav__inner {
	@include media($large-screen-up) {
		max-width: $max-width;
		margin: 0 auto;
		position: relative; /* to position the “close” icon */
	}
}

.subnav__intro {
	display: none;

	@include media($medium-screen-up) {
		width: 50%;
	}

	@include media($large-screen-up) {
		display: inline-block;
		position: relative;
		width: 45%;
		float: left;
		margin-right: 50px;
	}
}

.subnav__intro__image {
	width: 170px;
	@include position(absolute, 0 null null 0);
}

.subnav__intro__text {
	padding-left: 190px;
	font-size: $menu-font-size;
}

.nav-overlay {
	@include transition(background-color .3s);
}

.main-header,
.main-content {
	background-color: white;
}

.main-content {
	margin-top: 60px;

	&.transparent-bg {
		@include media($large-screen-up) {
			background-color: transparent;
		}
	}

	@include media($large-screen-up) {
		margin-top: auto;
	}
}

.main-header,
.main-content,
.main-footer {
	position: relative;
	left: 0;
	z-index: 2;
	@include transition(left .3s, right .3s);

	&.nav-is-visible {
		left: (-$menu-mobile-width);

		.nav-overlay {
			display: block;
			@include position(absolute, 0 0 0 0);
			background-color: rgba(black, .7);
			z-index: 9999;
			// try to fix IE11 issue (IE11 assigns a width of 0 to the overlay)
			width: $very-large-screen;

			&:hover {
				cursor: pointer;
				cursor: hand;
			}
		}

		@include media($medium-screen) {
			left: (-$menu-tablet-width);
		}
	}
}

.main-content {
	z-index: 3;
}

.main-header {
	&.nav-is-visible {
		.icon-menu:before {
			content: '\e117';
		}
	}

	@include media($large-screen-up) {
		z-index: 7;
	}
}

.main-header,
.main-content,
.main-footer {
	@include media($large-screen-up) {
		@include transition(none);
	}
}

.main-header {
	@include position(fixed, 0 0 null null);
	height: 60px;
	z-index: 20;

	&.header--sticky {
		box-shadow: 0 2px 2px rgba(black, 0.2);
	}

	&.nav-is-visible {
		right: $menu-mobile-width;

		@include media($medium-screen) {
			right: $menu-tablet-width;
		}
	}

	@include media($large-screen-up) {
		@include position(relative, auto auto null auto);
		height: auto;
		box-shadow: none;
	}
}

/**
 * Paging navigation
 */
.paging-navigation {
	a, .current-page, span {
		font-size: 14px;
		font-weight: bold;
		border-top: 1px solid $light-gray;
		border-bottom: 1px solid $light-gray;
		border-right: 1px solid $light-gray;
		padding: 4px 10px 0;
		height: 30px;
		display: inline-block;
		position: relative;

		&:first-child {
			padding-left: 13px;
			border-radius: 35% 0 0 35%;
			border-left: 1px solid $light-gray;
		}

		&:last-child {
			padding-right: 13px;
			border-radius: 0 35% 35% 0;
		}

		&.prev {
			img {
				width: 6px;
				height: 9px;
				display: inline;
			}
		}
		&.next {
			img {
				width: 6px;
				height: 9px;
				display: inline;
			}
		}
	}
}
