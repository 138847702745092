/* ==========================================================================
   Print styles
   ========================================================================== */

@media all {
	.page-break	{
		display: none;
	}
}

@media print {
    * {
        background: transparent !important;
        color: #000 !important; /* Black prints faster: h5bp.com/s */
        box-shadow: none !important;
        text-shadow: none !important;
    }

	.page-break	{
		display: block;
		page-break-before: always;
	}

    a,
    a:visited {
        text-decoration: underline;
    }

    abbr[title]:after {
        content: " (" attr(title) ")";
    }

    /*
     * Don't show links for images, or javascript/internal links
     */

    a[href^="javascript:"]:after,
    a[href^="#"]:after {
        content: "";
    }

    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group; /* h5bp.com/t */
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }

	.no-print, .no-print * {
		display: none !important;
	}

	.main-header {
		position: relative !important;
	}

	.main-content {
		margin-top: 1em !important;
	}

	.main-header__buttons, .footer__upper, .footer__lower, .back-to-top {
		display: none !important;
	}

	.checkout {
		h3.checkout__subhead {
			padding-left: 0 !important;

			.checkout__label {
				position: relative !important;
				top: auto !important;
				left: auto !important;
				width: auto !important;
				height: auto !important;
				margin-right: 10px !important;
			}
		}
		.checkout__delivery-data {
			margin-top: 0 !important;
		}
		.separator:last-child {
			margin-bottom: 0 !important;
			padding-bottom: 0 !important;
		}
		.checkout__cart-container {
			font-size: 0.8em !important;

			.material-item {
				h3 {
					font-size: 1em;
				}
			}

			.material-item:last-child {
				margin-bottom: 0;
				padding-bottom: 0;
			}
		}
	}

	.checkout__cart-tbody.checkout__summary-tbody .rt2 .row div {
		padding: 6px 0 !important;
	}
}