.header-popup {
	background-color: $styrolution-blue;
	@include position(fixed, null 0 null null);
	z-index: 8;
	@include transition(top 0.3s, right .3s);
	width: 100%;

	@include media($large-screen-up) {
		position: relative;
		z-index: 24;
		padding: 36px 0;

		&.languages {
			.close {
				position: absolute;
				top: 50%;
				right: 0;
				z-index: 4;
			}
		}
	}
}

.nav-is-visible .header-popup {
	right: 260px;
	width: 100%;

	@include media($medium-screen) {
		right: 360px;
	}
}

.header-popup__inner {
	position: relative;

	.close {
		@include position(absolute, 50% 0 null null);
		color: $inverted-font-color;
		font-size: 20px;
		height: 2em;
		line-height: 2em;
		margin-top: -1em;
		position: absolute;
		top: 50%;

		&:hover {
			color: $styrolution-blue-medium;
		}

		@include media($large-screen-up) {
			font-size: 35px;
		}
	}
}

.header-popup__dropdown {
	text-align: left;

	.dropdown__container {
		width: auto;
		margin-bottom: -7px;

		@include media($large-screen-up) {
			position: relative;
			top: -3px;
			width: 100%;
		}

		.dropdown__button,
		.dropdown__menu {
			padding: 0 2em 0 10px;
			background: transparent;
			border: none;
			font-size: 14px;
			color: white;
		}

		.dropdown__button {
			&:after {
				content: none;
			}

			@include media($large-screen-up) {
				float: none;
				width: auto;
				display: inline-block;

				&:after {
					content: none;
				}
			}
		}

		.dropdown__menu {
			li {
				font-weight: 500;
				border-bottom: none;
				border-radius: 0;
				padding: 2px 5px;
			}

			@include media($large-screen-up) {
				display: inline-block;
				width: auto;
				margin-top: 4px;
				padding: 3px;
				background-color: #fff;
				border-radius: 25px;

				li {
					display: inline-block;
					padding: 3px 10px 5px;

					a {
						color: $gray;
						text-transform: capitalize;
					}

					&.is-selected,
					&:hover {
						background-color: $accented-background-color;
						border-radius: 25px;

						a {
							color: white;
						}
					}
				}
			}
		}
	}

	&.alt-lang-dropdown-container {
		@include media($large-screen-up) {
			a.js-lang-trigger {
				padding: 0;
			}
		}
	}
}

.global-search,
.global-search--products {
	.search {
		width: 90%;
		max-width: 740px;

		input[type="search"] {
			color: $inverted-font-color;
			background-color: $styrolution-blue;
			border-width: 2px;
			border-color: $styrolution-blue-medium;
			border-radius: 35px;
			padding: 5px 10px 5px 40px;
			display: none;

			&.is-active {
				display: block;
			}

			&:focus {
				box-shadow: none;
			}

			@include media($large-screen-up) {
				font-size: 21px;
				padding: 14px 20px 17px 60px;
			}
		}

		@include media($large-screen-up) {
			height: 62px;
			margin: 0 auto;

			&:before {
				font-size: 30px;
				top: 10px;
				left: 20px;
			}
		}
	}

	.prods-quicksearch {
		.autocomplete-suggestions {
			top: 70px;
			width: 100% !important;
		}
	}
}

.global-search--products {
	position: relative;

	.search {
		height: 35px;

		@include media($large-screen-up) {
			height: 62px;
		}
	}
}

.global-search-form {
	> input {
		@include position(relative, 0 null null 0);
	}

	.icon-search {
		font-size: 20px;
		color: $styrolution-green;
		@include position(absolute, 2px null null 15px);
		z-index: 3;

		@include media($large-screen-up) {
			font-size: 40px;
			left: 20px;
		}

		.ie8 & {
			@include size(27px 28px);
			background-image: url('../img/old-ie/ie-magnifying-glass.png');
			background-repeat: no-repeat;
			background-position: 0 0;
			top: 17px;

			&:before {
				content: none;
			}
		}
	}
}

.available-languages {
	color: white;

	p {
		margin-bottom: 0;
	}

	.selected-lang,
	.available-lang {
		font-weight: 500;
		text-transform: uppercase;
	}
}
