// Content
.content--highlighted {
	background: #F8F8F8 url(../img/pattern-tinted.png);
}

.content--highlighted--bordered {
	border-bottom: 1px solid $lightest-gray;

	@include media($large-screen-up) {
		display: none !important;
	}
}

.content--highlighted--dark {
	background: $gray url(../img/pattern-light-small.png);
	color: white;

	.title--small {
		color: white;
	}

	.button:not(.button--play) {
		color: $accented-font-color;
	}
}

.content--on-dark {
	background-color: $gray;
	color: white;

	.button:not(.button--play) {
		color: $accented-font-color;
	}
}

.content--on-pattern {
	position: relative;
	color: white;

	&:before {
		content: '';
		display: block;
		@include position(absolute, 0 0 0 0);
		background: rgba(black, .5) url(../img/pattern-light-small.png);
	}

	.button:not(.button--play) {
		color: $accented-font-color;
	}
}

.content--bg {
	min-height: 160px;
	background-size: cover;
	background-position: center;

	@include media($large-screen-up) {
		min-height: 300px;
	}

	@include media(1025px) {
		background-attachment: fixed;
	}
}

.content__block {
	padding: 10px 0;
	border-bottom: 10px solid $lightest-gray;
	margin-bottom: 20px;

	@extend %clearfix;

	p {
		width: 66%;
		margin-bottom: 0;
	}
}

.content--text-overlay--mobile-sibling {
	padding-bottom: 0;
}


// secondary navigation (on the left in desktop view)
.content .sec-nav {
	margin-bottom: 20px;
	
	a {
		@extend %icon;
		@extend %icon-angle-right;
		border-bottom: 1px solid $lightest-gray;
		border-top: 1px solid $lightest-gray;
		display: block;
		font-size: 14px;
		margin-top: -1px;
		padding: 9px 0;
		text-transform: uppercase;
		
		&:before {
			float: right;
			margin-left: .5em;
			position: relative;
			top: 0.3em;
		}
	}
	
	.current {
		a {
			color: $styrolution-blue;
		}
	}
	
	@include media($large-screen-up) {
		margin-bottom: 35px;
		padding-right: 2.5em;
	}
}


// sidebar (on the right in desktop view)
.content .sidebar {
	margin: 4em 0 0;
	
	p,
	ul {
		margin-bottom: .5em;
	}
	
	.side-content + .side-content {
		margin-top: 2em;
	}
	
	@include media($large-screen-up) {
		margin-top: 0;
		padding-left: 2.5em;
	}
}

.article {
	margin-bottom: 20px;
	
	h3, 
	h4 {
		font-weight: 700;
	}
	
	> h1, h2, h3, h4, h5, h6 {
		margin-bottom: 24px;
	}

	ul,
	ol {
		margin-bottom: 1.5em;

		li:not(:last-child) {
			margin-bottom: 12px;
		}

		li {
			ul,
			ol {
				margin-top: 12px;
				margin-bottom: 12px;
			}

			ul {
				list-style-type: circle;
			}

			ol {
				list-style-type: upper-roman;
			}
		}
	}

	ul {
		list-style-type: disc;
		padding-left: 1em;

		&.press-contact {
			padding-left: 0;
			list-style-type: none;
		}

		&.two-cols-list {
			@include columns(2);

			li:before {
				content: '';
        display: inline-block;
        @include size(4px);
        border-radius: 50%;
        background-color: $gray;
        margin-right: 10px;
        position: relative;
        top: -3px;
			}
		}
	}

	ol {
		list-style-type: decimal;
		padding-left: 1.5em;
	}

	p.caption {
		background: $lightest-gray;
		font-size: $marginal-font-size;
		margin: 0;
		padding: .4em .6em;
	}
	
	.lightbox-teaser {
		a {
			@extend %icon;
			@extend %icon-plus;
			display: none;
			position: relative;
			
			&:before {
				@include transition(background-color 0.2s linear);
				background: $accented-background-color;
				bottom: 10px;
				color: $inverted-font-color;
				font-size: 18px;
				height: 30px;
				right: 10px;
				line-height: 30px;
				position: absolute;
				width: 30px;
				z-index: 1;
			}
			
			&:hover:before {
				background: $hover-link-color;
			}
		}
		
		a:first-child {
			display: block;
		}
		
		.featurebox__media-holder {
			margin: 0;
		}
	}
	
	.toc {
		margin-bottom: 20px;
		
		ul {
			list-style: none;
			padding: 0;
			margin: 0;
			
			li {
				margin: 0 0 .4em;
			}
		}
		
		a {
			@extend %icon;
			@extend %icon-angle-right;
			
			&:before {
				margin-right: .4em;
			}
		}
		
		@include media($large-screen-up) {
			margin-bottom: 35px;
		}
	}
	
	@include media($large-screen-up) {
		h3, 
		h4 {
			font-weight: 500;
		}
		
		h4 {
			font-size: 20px;
		}
	}
}

.article-title {
	margin-bottom: 10px;
}

.article-heading {
	margin-bottom: 20px;
	
	> p {
		margin-bottom: 20px;
	}
	
	.actions {
		a + a {
			margin-left: 1.2em;
		}
	}

	@include media($large-screen-up) {
		margin-bottom: 35px;

		.article-title {
			margin-bottom: 20px;
		}
		
		> p {
			font-size: 24px;
		}
		
		.actions {
			float: right;
			margin-left: 10px;
		}
	}
}

.article-meta {
	@extend %clearfix;
	font-size: 14px;

	span {
		display: block;

		@include media($large-screen-up) {
			float: left;
			&:not(:last-child) {
				padding-right: 10px;
				margin-right: 10px;
				border-right: 1px solid $lightest-gray;
			}
		}

		.ie8 & {
			display: inline;
			margin-right: 15px;
		}
	}

	i {
		font-size: 16px;
		margin-right: 5px;
	}

	&__tag {
		ul {
			list-style-type: none;
			margin: 0;
			padding-left: 0;
			li {
				display: inline;
				display: inline-block;
				margin-bottom: 0 !important;
				margin-right: -3px;
				&:first-child:before {
					content: '\e601';
					font-family: 'Icomoon';
					display: inline-block;
					margin-right: 5px;
				}
				&:not(:first-child):before {
					content: ',';
					display: inline-block;
					margin-right: 5px;
					color: $accented-font-color;
				}
				.ie8 & {
					a {
						white-space: nowrap;
					}
				}
			}
		}
	}
}

.article-footer {
	margin: 40px 0;
	
	h4,
	p {
		font-size: 14px;
	}
	
	h4 {
		margin-bottom: .2em;
	}

	@include media($large-screen-up) {
		margin-bottom: 0;
	}
}

.press-contact {
	.press-contact__name {
		font-weight: 700;
	}


	@include media($large-screen-up) {
		li {
			display: inline-block;

			&.press-contact__name {
				margin-right: 40px;
			}
		}
	}
}

.goto-tile {
	> p {
		margin-bottom: 0;
	}
}

// Leadership
.content--leadership {
	[class*="col-lg"] {
		font-size: 14px;
	}

	.col-lg-3 {
		@include omega(4n);
	}

	.col-lg-4 {
		@include omega(3n);
	}

	.col-lg-6 {
		@include omega(2n);
	}

	.media-holder {
		margin-bottom: $base-spacing / 2;

		img {
			height: 137px;
		}

		+ .subtitle {
			font-weight: 500;
			margin-bottom: $base-spacing / 3;
		}
	}
}

.job-title {
	display: block;
	font-weight: 500;
	font-size: 14px;
}

.content--text-overlay--mobile {
	@include media($large-screen-up) {
		position: relative;

		&:before {
			content: '';
			display: block;
			@include linear-gradient(to left, rgba(black, 0), rgba(black, .5));
			@include position(absolute, 0 0 0 0);
		}
	}

	.no-multiplebgs & {
		&:before {
			background-image: url('../img/old-ie/ie-carousel-overlay.png');
			background-repeat: no-repeat;
		}
	}
}

.content--text-overlay--mobile-sibling {
	.subtitle:last-of-type {
		margin-bottom: 0;
	}

	@include media($large-screen-up) {
		color: white;
		position: relative;
		z-index: 3;
		padding: 0;

		.title {
			color: white;
		}

		.subtitle {
			margin-bottom: 0;
		}

		.read-more {
			display: none;
		}
	}
}

.jim-ratcliffe--company-intro {
	overflow: hidden;
	margin-bottom: -23px;

	img {
		display: block;
	}

	@include media($large-screen-up) {
		@include position(absolute, null -160px 0 null);
		margin-bottom: 0;
	}
}

// Stacked v2
.content__side-nav {
	@include outer-container;
	position: relative;
}

.content__side-nav__inner {
	@include media($large-screen-up) {
		padding-left: 260px;
		padding-top: 20px;
	}
}

.side-nav-container {
	@include size(220px 100%);
	@include position(absolute, null null null 0);
}

.side-nav {
	width: 220px;
	padding-left: 20px;
	padding-top: 30px;

	li {
		margin-top: -1px;
		a {
			display: block;
			border-top: 1px solid $lightest-gray;
			border-bottom: 1px solid $lightest-gray;
			margin-top: -1px;
			font-weight: 500;
			font-size: 13px;
			color: $gray;
			text-transform: uppercase;
			padding: 10px 10px 10px 0;
			position: relative;
			overflow: hidden;
			text-overflow: ellipsis;

			&:after {
				content: '\f105';
				font-family: Icomoon;
				color: $accented-font-color;
				@include size(5px 19px);
				@include position(absolute, 0 0 0 null);
				margin: auto;
			}

			&:hover,
      &.active {
				color: $accented-font-color;
			}
		}
	}

	@include media($max-width) {
		padding-left: 0;
	}
}



/** Contact Teaser in Sidebar */
.side-content {
	.contact {
		margin-bottom: $base-spacing;
		
		h5,
		p,
		ul {
			font-size: 14px;
			margin: 0;
		}
		
		h5 {
			font-weight: 700;
		}
		
		img,
		.role {
			margin-bottom: .25em;
		}
	}
}



/** Properties in Sidebar */
.side-content {
	.props {
		li {
			border: none;
			padding: 0;
		}
		
		[class^="icon-"] {
			display: inline-block;
			font-size: 2.6em;
			line-height: 1;
			margin-right: .25em;
			vertical-align: middle;
		}
	}
}



/** 
 * Summary and Overline
 * (small text above headline or above main content) 
 **/
.main-content {
	.summary,
	.overline {
		margin: 0 0 1em;
		
		p {
			margin: 0;
		}
		
		
		
		.icon,
		[class^="icon-"] {
			margin-right: 5px;
		}
		
		@include media($medium-screen-up) {
			.actions {
				float: right;
			}
		}
	}
	
	.summary {
		color:  $medium-gray;
		padding: 5px 0;
		
		a {
			color: $medium-gray;
			
			&:hover {
				color: $styrolution-green;
			}
			
			.icon,
			[class^="icon-"] {
				color: $styrolution-orange;
			}
		}
		
		@include media($large-screen-up) {
			p {
				display: inline-block;
			}
			
			p + p {
				border-left: 1px solid $styrolution-green-light;
				margin-left: 1em;
				padding-left: 1em;
			}
		}
	}
	
	.article-meta + .summary {
		margin: 10px 0 0;
	}
	
	.article-heading .summary {
		background: none;
		color: $base-font-color;
		padding: 0;
		
		p + p {
			border: none;
			padding-left: 0;
		}
	}
	
	.article-heading .overline {
		@extend %clearfix;
		margin-bottom: 1.8em;
		
		@include media($large-screen-up) {
			margin-bottom: 0;
			
			a {
				float: right;
			}
		}
	}
}


/** Embedded Google Maps */
.main-content {
	.map-embedded {
		background: $lightest-gray;
			
		p {
			margin: 0;
			padding-left: 0.6em;
			padding-right: 0.6em;
		}

		.link {
			padding-bottom: .4em;
		}
		
		iframe {
			display: none;
		}
	}
	
	@include media($large-screen-up) {
		.map-embedded {
			iframe {
				background: $lightest-gray;
				display: block;
				height: 348px;
			}
			
			.link {
				display: none;
			}
		}
	}
}



/** 
 * Data Lists
 * List of items that have a date and/or location, a title and an optional image 
 * (news teasers, event teasers) or of contacts.
 */
.data-list {
	> div,
	> li {
		padding-bottom: 2em;
		padding-top: 2em;
		position: relative;
	}
	
	> div + div,
	> li + li {
		border-top: 1px solid $lightest-gray;
	}
	
	a,
	.vcard {
		display: block;
		position: relative;
	}
	
	.preview,
	.portrait {
		max-height: 100px;
		overflow: hidden;
		margin: 0 0 .7em;
		width: 200px;
		
		img {
			display: block;
			width: 100%;
		}
	}
	
	h4 {
		color: $dark-gray;
		font-weight: 500;
		font-size: 20px;
		margin: 0;
	}
	
	html.no-touch &:not(.contacts) {
		> div:before,
		> li:before {
			@include transition (width 0.4s ease-out);
			@include transition-delay(.1s);
			background: $styrolution-green;
			content: '';
			height: 6px;
			left: 0;
			position: absolute;
			top: 0;
			width: 0;
		}
		
		> div:hover,
		> li:hover {
			background: #f6f6f6;
			
			&:before,
			&:before {
				width: 100%;
			}
			
			h4,
			.meta {
				color: $accented-font-color;
			}
		}
	}
	
	@include media($medium-screen-up) {
		> div,
		> li {
			padding-bottom: 1em;
			padding-top: 1em;
		}
		
		a,
		.vcard {
			min-height: 100px;
			padding-left: 220px;
		}
		
		.h-auto a,
		.h-auto vcard {
			min-height: 0;
		}
		
		.preview,
		.portrait {
			left: 0;
			margin: 0;
			position: absolute;
			top: 0;
		}
		
		.meta {
			p {
				margin-right: 1em;
			}
		}
	}
}

.data-list.contacts {
	.portrait {
		max-height: 200px;
	}
	
	a {
		display: inline-block;
		padding: 0;
	}
	
	p {
		margin: .8em 0 0;
	}
	
	p.title,
	p.tel + p.tel {
		margin-top: 0;
	}
	
	p.title {
		color: $base-font-color;
		margin-top: .2em;
	}
	
	@include media($medium-screen-up) {
		.vcard {
			min-height: 200px;
		}
	}
	
	@include media($large-screen-up) {
		p.title {
			font-size: 15px;
		}
	}
}



/** 
 * Preview Panels
 * List of items that have a thumbnail a title, some meta data and a detail or download link 
 * (items in the download center).
 */
.preview-panels {
	
	> div + div,
	> li + li {
		border-top: 3px solid $white;
	}
	
	a {
		background: $white-hued;
		display: block;
		padding: 2em 1em;
		width: 100%;
	}
	
	html.no-touch & a:hover {
		background: $lightest-gray;
	}
	
	.preview {
		margin: 0 0 .7em;
		
		img {
			display: block;
			margin-left: auto;
			margin-right: auto;
			max-width: 200px;
			width: 100%;
		}
	}
	
	html.objectfit & {
		.preview {
			height: 200px;
			
			img {
				height: 100%;
				max-height: 320px;
				max-width: 320px;
				object-fit: contain;
			}
		}
	}
	
	h4 {
		font-weight: 500;
		font-size: 15px;
		margin: 0;
	}
	
	.overline {
		color: $medium-gray;
		letter-spacing: .02em;
		margin-bottom: 0;
	}
	
	.meta {
		p {
			display: inline-block;
		}
		
		p + p:before {
			content: '| ';
		}
	}
	
	&.videos {
		.preview {
			@extend %icon;
			@extend %icon-play;
			position: relative;
		}
		
		.preview:before {
			@include transition (background-color 0.3s ease-out);
			background: $styrolution-green;
			border-radius: 30px;
			color: $inverted-font-color;
			font-size: 28px;
			height: 60px;
			left: 50%;
			line-height: 40px;
			margin: -30px 0 0 -30px;
			padding: 10px 8px 10px 12px;
			position: absolute;
			top: 50%;
			width: 60px;
		}
		
		.preview:hover:before {
			background-color: $styrolution-green-dark;
		}
	}
	
	@include media($medium-screen-up) {
		font-size: 0;
		line-height: 0;
		margin-left: -3%;
		
		> div,
		> li {
			display: inline-block;
			font-size: 15px;
			line-height: $base-line-height;
			margin: 0 0 3% 3%;
			vertical-align: top;
			width: 47%;
		}
		
		a {
			padding: 1em;
		}
		
		> div + div,
		> li + li {
			border: none;
		}
		
		.preview {
			border-bottom: 2px solid $white;
			margin-left: -1em;
			margin-right: -1em;
			padding-left: 1em;
			padding-right: 1em;
		}
		
		html.flexbox & {
			display: flex;
			flex-wrap: wrap;
			margin-left: 0;
			
			> div,
			> li {
				display: flex;
				flex: 0 0 auto;
				margin-bottom: 1.5em;
			}
		}
	}
	
	@include media($large-screen-up) {
		margin-left: -3.3%;
		
		> div,
		> li {
			width: 30%;
		}
		
		> div,
		> li,
		> div + div,
		> li + li {
			margin: 0 0 3% 3.3%;
		}
	}
}



/** 
 * META DATA in ITEM LISTS
 */
.data-list,
.preview-panels {
	.meta {
		color: $medium-gray;
		font-size: 13px;
		margin: .3em 0 0;
	
		p {
			margin-bottom: 0;
			margin-top: 0;
		}
	
		.icon,
		[class^="icon-"] {
			margin-right: .4em;
		
			&:before {
				font-size: 1em;
			}
		}
	}
	
	@include media($medium-screen-up) {
		.meta {
			p {
				display: inline-block;
			}
		}
	}
}



/**
 * Filtered Sections
 * (used for Terms & Conditions)
 */
.filtered-sections {
	.filter {
		h3 {
			font-size: 14px;
			text-transform: uppercase;
		}
		
		select {
			margin-top: .4em;
		}
	}
	
	section {
		display: none;
		
		&.selected {
			display: block;
		}
		
		h3 {
			margin-bottom: .5em;
			margin-top: 1.2em;
		}
		
		h3:first-child {
			margin-top: 0;
		}
	}
	
	@include media($large-screen-up) {
		.filter {
			h3 {
				margin: 0 0 .4em;
			}
		
			select {
				margin-top: 0;
			}
		}
	}
}



/**
 * Download List in Main Column
 */
p.download,
ul.downloads {
	.meta {
		color: #b9b9b9;
		display: block;
		font-size: 13px;
	}
}

ul.downloads {
	li + li {
		margin-top: 1em;
	}
	
	li {
		> a {
			display: block;
			position: relative;
		}
	}
}

.main-content {
	ul.downloads,
	ul.downloads li ul {
		list-style: none;
	}
}



/**
 * Containers with no to padding
 * (added on request by Intershop)
 */
.main-content {
	.no-top-padding {
		padding-top: 0 !important;
	}
}



/**
 * Panel
 * Simply a main content container that is somewhat accentuated.
 * 
 */
.main-content {
	.panel {
		border-bottom: 1px solid $light-gray;
		border-top: 1px solid $light-gray;
		margin: 0 0 1.5em;
		padding: 1em 0 0;
		
		h3 {
			margin-bottom: 1em;
		}
		
		@include media($large-screen-up) {
			div + div[class^="col-lg"] {
				padding-left: 1em;
			}
			
			h3 {
				font-size: 24px;
			}
		}
	}
}




/**
 * Panel List
 * Used for the product lists on “product-overview” and “industries-line”
 */
.main-content {
	.panel-list {
		h3 {
			background: $styrolution-blue-medium;
			color: $inverted-font-color;
			font-size: 16px;
			margin: 0;
			padding: .4em .6em;
		}
		
		ul {
			list-style: none;
			margin: 0;
			padding: 0;
		}
		
		li {
			@extend %clearfix;
			border-bottom: 1px solid $light-gray;
			padding: 1em 40px 1em 10px;
			position: relative;
				
			.detail {
				> .icon-angle-right:before {
					font-size: 36px;
					position: absolute;
					height: 46px;
					line-height: 44px;
					margin: -23px 0 0;
					right: 10px;
					text-align: right;
					top: 50%;
				}
			}
			
			h4 {
				font-size: 20px;
				font-weight: 500;
				text-transform: uppercase;
				margin: 0;
			}
			
			p {
				color: $gray;
				font-size: 14px;
				margin: 0;
			}
		}
		
		li:not(:last-child) {
			margin-bottom: 0;
			margin-top: 0;
		}
	}
}

html.no-touch .main-content {
	.panel-list {
		li {
			@include transition (background-color 0.08s linear);
			
			p {
				opacity: .8;
			}
			
			&:hover {
				background: $white-hued;
				
				p {
					opacity: 1;
				}
			}
		}
	}
}



/**
 * Locations
 */
#locations {
	figure {
		display: none;
		margin: 0;
		
		img {
			display: block;
			margin: 0 auto;
		}
	}
	
	.accordion__content {
		padding: 1em 10px 0;
		
		.row > div {
			padding-bottom: 1.2em;
		}
		
		em {
			font-style: normal;
			font-weight: 700;
		}
		
		h4 {
			color: $styrolution-blue;
			font-size: 1em;
			margin: 0 0 .4em;
			text-transform: uppercase;
		}
		
		p,
		ul {
			margin-bottom: .5em;
		}
	}
	
	@include media($large-screen-up) {
		figure {
			display: block;
			margin-bottom: 2em;
		}
		
		.accordion__content {
			.row {
				padding-bottom: 1.2em;
			}
			
			.row > div {
				padding-bottom: 0;
			}
		}
	}
}



/**
 * Mosaic Views
 * (using shuffle.js)
 */
.mosaic {
	&.shuffled {
		> [class*="col-lg"] {
			margin-right: 0;
		}
	}
	
	.sizer {
		opacity: 0;
		position: absolute;
		visibility: hidden;
	}
	
	@include media($large-screen-up) {
		.tile {
			margin-top: 12px;
			position: relative;
			
			.stretcher {
				height: 0;
				overflow: hidden;
				padding-bottom: 56.25%;
				position: relative;
			}
			
			.img {
				bottom: 0;
				left: 0;
				position: absolute;
				right: 0;
				top: 0;
				
				img {
					display: block;
					height: 100%;
					max-width: none;
					object-fit: cover;
					width: 100%;
				}
			}
			
			.text {
				bottom: 0;
				left: 0;
				position: absolute;
				width: 100%;
			}
			
			&.tile-square {
				.stretcher {
					padding-bottom: 80%;
				}
			}
	
			&.tile-landscape {
				.stretcher {
					padding-bottom: 39%;
				}
			}
		
			&.tile-square,
			&.tile-landscape {
				.text {
					bottom: auto;
					top: 0;
				}
			}
		}
	}
}

