.modal-overlay {
	@include position(fixed, 0 0 0 0);
	z-index: 999999;
	background: rgba(black, .7);
}

.modal {
	max-width: 646px;
	margin: auto;
	@include position(fixed, 30px 20px null 20px);
	z-index: 9999999;
	box-shadow: 0 0 10px black;

	.wrapper {
		padding: 0;
	}

	@include media($large-screen-up) {
		left: 50%;
		margin-left: -323px;
	}
}

.modal-header {
	padding: 10px 25px;
	color: white;
	background: $styrolution-blue;
	font-weight: 700;
	font-size: 14px;
	text-transform: uppercase;
	overflow: hidden;
	position: relative;

	h3 {
		margin-bottom: 0 !important;
	}
	
	@include media($large-screen-up) {
		padding: 15px 45px;
	}
	
	.close {
		color: $lightest-gray;
		font-size: 24px;
		line-height: .8;
		float: right;
		margin-right: -25px;
		padding: 0;
		background: none;
		border: none;

		.style2 & {
			font-size: 48px;
		}
	}
}

.modal-body {
	background-color: #fff;
	padding: 25px;
	position: relative;
	max-height: 80vh;
	overflow: auto;

	@include media($large-screen-up) {
		padding: 45px;
	}
}

.input-holder {
	position: relative;
	margin-bottom: $base-spacing;

	.input-message {
		@include position(absolute, null 0 -20px 0);
		color: white;
		font-size: 10px;
		padding: 3px 5px;
	}

	&.error {
		.input-message {
			color: red;
		}
	}

	&.succes {
		.input-message {
			color: green;
		}
	}

	@include media($large-screen-up) {
		width: 48%;
		float: left;
		margin-right: 2%;

		&:last-of-type {
			margin-right: 0;
		}
	}
}

.checkbox {
	position: relative;
	top: 4px;
}

.checkbox-label {
	font-size: 14px;
}

.modal-footer {
	padding: 10px 25px;
	
	@include media($large-screen-up) {
		padding: 15px 45px;
	}
}

#RememberMe ~ label {
	display: inline;
}

// Video player modal
.modal-open {
	overflow: hidden;
}
.modal-video {
	display: none;
	overflow: hidden;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1040;
	-webkit-overflow-scrolling: touch;
	outline: 0;

	.modal-body {
		position: relative;
		padding: 0;
		overflow: hidden;

		@include media($medium-screen-up) {
			height: 40vh;
		}

		@include media($large-screen-up) {
			height: 700px;
			height: 80vh;
		}
	}
}
.modal-video.fade .modal-dialog {
	-webkit-transform: translate(0, -25%);
	-ms-transform: translate(0, -25%);
	-o-transform: translate(0, -25%);
	transform: translate(0, -25%);
	-webkit-transition: -webkit-transform 0.3s ease-out;
	-o-transition: -o-transform 0.3s ease-out;
	transition: transform 0.3s ease-out;
}
.modal-video.in .modal-dialog {
	-webkit-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	-o-transform: translate(0, 0);
	transform: translate(0, 0);
	height: auto !important;
}
.modal-dialog {
	position: relative;
	width: auto;
}
.modal-content {
	position: relative;
	background-color: #ffffff;
	background-clip: padding-box;
	outline: 0;
}
.modal-backdrop {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	height: 100vh !important;
	background-color: $dark-gray;

	.ie8 & {
		min-height: 1200px;
	}

	table & {
		position: fixed;
		bottom: 0;
		height: 100vh !important;
	}

	.ie8 table & {
		position: fixed;
		bottom: 0;
		height: 900px;
	}
}
.modal-backdrop.fade {
	opacity: 0;
	filter: alpha(opacity=0);
}
.modal-backdrop.in {
	opacity: 1;
	filter: alpha(opacity=100);
}
.modal-title {
	margin: 0;
	line-height: 1.42857143;
}
.modal-footer {
	border-top: 1px solid #e5e5e5;
	@include media($large-screen-up) {
		text-align: right;
	}
}
.modal-footer .btn + .btn {
	margin-left: 5px;
	margin-bottom: 0;
}
.modal-footer .btn-group .btn + .btn {
	margin-left: -1px;
}
.modal-footer .btn-block + .btn-block {
	margin-left: 0;
}
.modal-scrollbar-measure {
	position: absolute;
	top: -9999px;
	width: 50px;
	height: 50px;
	overflow: scroll;
}

.close-media-modal {
	padding: 0;

	@include size(50px);
	@include position(absolute, 17px 17px null null);
	background: $accented-background-color;
	border: 1px solid $accented-border-color;
	padding: 0;

	&:before {
		content: '\e117';
		display: block;
		font-family: "Icomoon";
		font-size: 41px;
		line-height: 1;
		color: white;
		text-align: center;
	}

	&:hover {
		color: $accented-font-color;
		background: transparent;
		cursor: pointer;
		cursor: hand;
	}
}

// Modal fix when placed inside transformed element Webkit
.bx-wrapper {
	.modal {
		height: auto;
		@include position(absolute, auto auto auto 50%);
		@include transform(translate(-50%, -50%));
		padding-right: 0 !important;

		&-backdrop.in {
			opacity: 0;
		}

		&-header {
			overflow: hidden;

			.close {
				position: relative;
				top: 0;
				right: 0;
				float: right;
				font-size: 24px;
				padding: 0;
				background: none;
				border: none;
				margin-top: 0;

				&:focus {
					outline: none;
				}
			}

			h3 {
				float: left;
				margin-bottom: 0 !important;
			}
		}
	}
}

// Alert box
.alertbox {
	width: 90%;
	max-width: 360px;
	background: $accented-background-color;
	@include position(fixed, 30px null null 50%);
	@include transform(translateX(-50%));
	z-index: 99;

	&.error {
		background: #e74c3c;
	}

	&.info {
		background: #27ae60;
	}

	@include media($large-screen-up) {
		@include position(absolute, 45px 20px null auto);
		@include transform(none);
		z-index: 3;
	}

	@include media($max-width) {
		right: 0;

		.ie8 & {
			right: auto;
			left: 750px;
		}
	}
}

.close-alertbox {
	@include position(absolute, 12px 10px null null);
	font-size: 26px;
	line-height: 1;
	color: $gray;
}

.alertbox__title {
	font-size: 18px;
	text-transform: uppercase;
	color: white;
	padding: 10px 35px 10px 10px;
	margin-bottom: 0;

	&:before {
		content: '\e60a';
		font-family: Icomoon;
		display: inline-block;
		margin-right: 10px;
	}
}

.alertbox__body {
	margin: 0 5px 5px;
	padding: 20px 15px;
	background-color: $gray;
	color: white;
	max-height: 60vh;
	overflow: auto;

	p {
		font-size: 14px;
		margin-bottom: 10px;
	}

	.more-info {
		text-transform: none;
		padding-bottom: 0;

		i {
			margin-left: 5px;
		}
	}
}






// ******************************
// Additions for Styrolution
// 07-08, 2016
// ******************************

#notice-sign-in,
#global-modal {
	padding: 0 !important;
	max-width: 700px;
	
	.modal-backdrop {
		background: rgba(black, .7);
	}
	
	.modal-body {
		padding-top: 20px;
		padding-bottom: 0;
	}
}

// loading state of AJAX modal
.modal.pending {
	.modal-body {
		&:after {
			animation: spinner-rotate 0.8s infinite linear;
			border: 2px solid $styrolution-blue;
			border-right-color: transparent;
			border-radius: 50%;
			content: '';
			height: 50px;
			left: 50%;
			margin: -25px 0 0 -25px;
			position: absolute;
			top: 50%;
			width: 50px;
		}
	}
	
	@keyframes spinner-rotate {
		0%    { transform: rotate(0deg); }
		100%  { transform: rotate(360deg); }
	}
}



// custom styles for lightGallery
.lg {
	.lg-thumb.group {
		margin-left: auto;
		margin-right: auto;
	}
}



// styles for the modals showing popups with legacy markup 
// (i.e. markup taken as was from the old website)
.modal {
	.popup-content {
		margin-top: -20px;
		
		&#location-popup {
			padding: 0;
		}
		
		
		h1 {
			display: none;
		}
		
		section {
			padding: 0;
		}

		.vcard {
			margin: 0;
			p {
				margin: 0;
			}
		}

		h2 {
			background: $styrolution-green;
			color: $inverted-font-color;
			font-size: $h5-font-size;
			margin: 0 -25px 1em;
			padding: 10px 25px;

			@include media($large-screen-up) {
				margin-left: -45px;
				margin-right: -45px;
				padding-left: 45px;
				padding-right: 45px;
			}
		}

		h3,
		h4,
		p,
		li,
		label,
		button,
		.sty-order-item-summary,
		.fieldrow {
			font-size: $marginal-font-size;
			margin: 0;
		}
		
		.fieldrow * {
			font-size: 1em;
		}
		
		h3,
		h4 {
			font-weight: 700;
			margin: .8em 0 .5em;
			color: $dark-gray;
			letter-spacing: .02em;
			text-transform: uppercase;
		}

		label {
			font-weight: 500;
		}

		fieldset {
			margin: 0;
			padding: .9em 0 0;
		}
		
		fieldset + fieldset {
			border-top: 1px solid $base-border-color;
		}
		
		.fieldrow + .fieldrow {
			margin-top: .5em;
		}
		
		select {
			width: 100%;
		}
		
		.checkbox-wrapper .checkbox,
		.radio-wrapper .radio {
			display: inline-block;
			
			label {
				display: inline;
				margin-right: 1em;
			}
		}
		
		.sty-legend,
		.legend,
		.sty-important-message,
		#delete-users-list,
		fieldset + fieldset {
			margin-top: 1.2em;
		}
		
		label + .sty-legend {
			margin-top: 0;
		}


		.legend {
			font-size: 0.8em;
			font-weight: normal;
		}
		
		.sty-order-item-summary {
			border-top: 1px solid $base-border-color;
			margin-top: .9em;
			padding: .9em 0 0;
		}
		
		.button-wrapper {
			@extend %clearfix;
			background-color: $lightest-gray;
			margin: 1em -25px 0;
			padding: 10px 25px 0;

			.button {
				float: left;
				font-size: 12px;
				margin: 0 10px 10px 0;
			}

			@include media($large-screen-up) {
				margin: 2em -45px 0;
				padding: 15px 45px 5px;
			}
		}
		
		.button.submit-changes,
		.button.change-soldto {
			border-color: $styrolution-green;
			
			&:not(.button-inactive):hover {
				border-color: $styrolution-green-dark;
			}
		}
		
		.sty-important-message {
			.button-wrapper {
				top: 68px;

				@include media($large-screen-up) {
					top: 89px;
				}
			}
		}
		
		.locationWrap {
			@extend %clearfix;
			@include outer-container;
			margin: 0 0 1em -4%;
			padding: 0;
			
			> div {
				float: left;
				padding: 0 0 0 4%;
				width: 48%;
			}
			
			li {
				margin-top: .6em;
			}
			
			@include media($medium-screen-up) {
				margin-bottom: 2.5em;
				
				> div {
					width: 22%;
				}
			}
		}
		
		.location-close {
			display: none;
		}
		
		#sty-screen-success {
			display: none;
		}
		
		&.confirm-popup {
			fieldset {
				padding-top: 2em;
			}
		}
		
		&#share-bookmarks-popup {
			#comments {
				margin-top: 1.2em;
				margin-bottom: 1.2em;
				
				label {
					float: left;
				}
				
				.sty-legend {
					float: right;
				}
			}
			#sty-screen-success {
				.msg-confirmation {
					margin-top: 2em;
				}
			}
		}
	}
}