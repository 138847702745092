.businesses {
	position: relative;

	.content--highlighted {
		padding-bottom: 10px;

		@include media($large-screen-up) {
			padding: 3em 0;
			background: url('../img/windmills-big.jpg') center fixed;
			background-size: cover;
			padding-top: 0;
		}
	}

	@include media($large-screen-up) {
		.section-header {
			position: relative;
		}
	}

	.no-multiplebgs & {
		.content--highlighted {
			background-image: url('../img/old-ie/ie-dark-pattern-overlay.png');
		}
	}
}

.businesses--contacts {
	.section-header {
		color: $gray;
	}
}

.businesses-cards + .dropdown {
	@include media($large-screen-up) {
		display: none;
	}
}

.business-card {
	position: relative;

	.button {
		width: 100%;
		margin-bottom: $base-spacing / 2;

		i {
			float: right;
		}

		&:hover {
			color: white;
			background-color: $hover-link-color;
		}

		@include media($large-screen-up) {
			text-align: center;
		}
	}

	.businesses & {
		@include media($large-screen-up) {
			@include span-columns(3);
			@include omega(4n);

			.button i {
				float: none;
			}
		}
	}

	@include media($large-screen-up) {
		.list--arrow {
			position: absolute;
			background-color: white;
			width: 100%;
			z-index: 3;
			padding: 0 15px;
			border: 1px solid $accented-border-color;
			box-shadow: 0 0 4px 2px rgba(black, .4);

			li {
				&:last-child {
					border-bottom: none;
				}

				a {
					font-weight: 500;
					font-size: 13px;
					text-transform: uppercase;
					color: $gray;
					padding: 10px 0;

					i {
						right: 0;
					}

					&:hover {
						color: $accented-font-color;

						i {
							color: $gray;
						}
					}
				}
			}

			&:before {
				content: '';
				@include triangle(12px, white, up);
				@include position(absolute, -6px null null 50%);
				margin-left: -6px;
				z-index: 1;
			}

			&:after {
				content: '';
				@include triangle(16px, $accented-font-color, up);
				@include position(absolute, -8px null null 50%);
				margin-left: -8px;
				filter: drop-shadow(0px -2px 1px rgba(0,0,0,0.3));
			  -webkit-filter: drop-shadow(0px -2px 1px rgba(0,0,0,0.3));
			}
		}
	}
}

.business-card--captioned {
	> .button {
		display: block;
		border: none;
		color: $gray;
		margin-bottom: 0;
		@extend %clearfix;

		&:hover {
			color: white;
		}

		&:before {
			font-family: Icomoon;
			float: right;
			content: '\e114';
			color: $accented-font-color;
		}

		&.is-active {
			background-color: $accented-background-color;
			color: white;

			&:before {
				content: '\e115';
				color: $gray;
			}

			+ .is-open {
				display: block;
				border-bottom: 3px solid $accented-border-color;
			}
		}

		@include media($large-screen-up) {
			display: none;
		}
	}

	.business-card__content {
		display: none;

		@include media($large-screen-up) {
			display: block;

			.content__block {
				min-height: 160px;
				position: relative;

				.title {
					font-weight: 500;
					font-size: 21px;
				}

				p {
					width: 100%;
					font-size: 14px;
				}

				> img {
					@include position(absolute, null 0 6px null);
					opacity: .2;
				}
			}
		}

		@include media(1083px) {
			display: block;

			.content__block {
				min-height: 160px;

				.title {
					font-weight: 500;
					font-size: 21px;
				}

				p {
					width: 69%;
					font-size: 14px;
				}

				> img {
					opacity: 1;
				}
			}
		}
	}
}

.business-card__business-description {
	@include position(absolute, null 0 0 0);
	background: rgba(black, .6) url(../img/pattern-light-small.png);
	padding: 20px 0;
	font-size: 18px;
	line-height: 1.2;
	text-transform: uppercase;
	color: white;

	p {
		margin-bottom: 0;
	}

	@include media($large-screen-up) {
		font-weight: 500;
		font-size: 21px;

		p {
			display: inline-block;
			float: left;
			line-height: 1.7;
		}

		.button {
			width: auto;
			display: inline-block;
			margin-bottom: 0;
			padding-top: 9px;
			font-size: 14px;
			color: white;
			float: right;

			i {
				margin-left: 10px;
			}

			&:hover {
				background-color: transparent;
				color: $accented-font-color;
			}
		}
	}

	.no-multiplebgs & {
		background-image: url('../img/old-ie/ie-news-slider-bg.png');
	}
}

.business-card__logo {
	min-height: 160px;
	background-size: cover;
	padding: 20px;
	position: relative;

	> a {
		@include position(absolute, 0 0 0 0);
	}

	img {
		@include position(absolute, 0 null 0 20px);
		margin: auto;
	}

	.business-card--captioned & {
		min-height: 240px;
		padding: 20px 0;

		img {
			position: relative;
			top: 10px;
			left: 0;
		}

		@include media($large-screen-up) {
			min-height: 340px;
		}
	}

	&:before {
		content: '';
		@include position(absolute, 0 0 0 0);
		@include linear-gradient(to left, rgba(black, 0), rgba(black, .5));
	}

	@include media($large-screen-up) {
		min-height: 110px;

		.business-card--no-bg & {
			padding: 0;
		}
	}

	.no-cssgradients & {
		&:before {
			background-image: url('../img/old-ie/ie-carousel-overlay.png');
		}
	}
}

.business-card__site-link {
	padding: 10px 20px;
	display: block;

	@include media($large-screen-up) {
		.business-card--no-bg & {
			padding-left: 0;
			font-size: 14px;
			margin-bottom: 30px;
		}
	}
}

.business-card--no-bg {
	@include media($large-screen-up) {
		&:nth-last-child(-n+4) {
			.business-card__site-link {
				margin-bottom: 0;
			}
		}
	}
}

.carousel-with-nav {
	.bx-wrapper .bx-controls-direction a {
		top: 54%;
	}
}
