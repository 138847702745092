%button {
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  color: $gray;
  padding: 0.9em 1.6em 1em;
  text-decoration: none;
  text-transform: uppercase;
  border: 1px solid $light-gray;
  background-color: white;

  &:hover {
    background-color: white;
    color: $accented-font-color;
  }

	&.button-inactive:hover,
	&.button-inactive:active,
	&.button-inactive:focus {
    color: $gray;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
