$dropdown-color: $base-font-color;
$dropdown-description-color: lighten($dropdown-color, 40);
$dropdown-border: 1px solid $lightest-gray;
$dropdown-background: $base-background-color;
$dropdown-background-hover: darken($dropdown-background, 3);
$dropdown-inner-border: 1px solid $lightest-gray;
$dropdown-height: 35px;
$dropdown-padding: 1em;
$dropdown-distance-from-menu: 50px;

.dropdown {
  text-align: center;

  @include media($large-screen-up) {
    text-align: left;
  }
}

.dropdown--filter:not(:last-child) {
  .dropdown__menu {
    margin-bottom: $base-spacing;
  }
}

.dropdown__container {
  display: inline-block;
  position: relative;
  width: 100%;

	select {
		height: auto;
	}
}

.dropdown__description {
  background: $dropdown-background;
  border: $dropdown-border;
  color: $dropdown-description-color;
  float: left;
  font-size: 16px;
  line-height: $dropdown-height;
  margin-bottom: 0;
  padding: 0 ($dropdown-padding / 2) 0 $dropdown-padding;

  @include media($large-screen) {
    font-size: 1em;
  }
}

.dropdown__button {
  background: $dropdown-background;
  border: $dropdown-border;
  cursor: pointer;
  float: right;
  font-size: 16px;
  line-height: $dropdown-height;
  margin-bottom: 0;
  position: relative;
  width: 100%;

  @include media($large-screen) {
    font-size: 1em;
    border: none;
    line-height: 35px;
    padding: 0 10px;

    .dropdown__button:hover {
      color: $accented-font-color;
    }
  }
}

%expander,
.dropdown__button:after {
  content: "\25BE";
  display: inline-block;
  font-family: Icomoon;
  margin: 0 0 0 .4em;
}

.dropdown__menu {
  @include transition (all 0.2s ease-in-out);
  background: $dropdown-background;
  border: $dropdown-border;
  color: $dropdown-color;
  cursor: pointer;
  display: none;
  overflow: show;
  position: relative;
  width: 100%;
  padding: 0 15px;
  margin-top: 40px;

  @include media($large-screen-up) {
    position: absolute;
    margin-top: 45px;
    z-index: 11;
    border: 1px solid $styrolution-green;;
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.4);
  }

  .page-actions & {
    position: absolute;
    z-index: 99;
  }

  &.switch-view {
    border-radius: 0;

    li {
      a {
        &.active {
          color: $accented-font-color;
          background-color: transparent;
        }
      }
    }
  }
}

.dropdown__menu li {
  border-bottom: $dropdown-inner-border;
  color: $dropdown-color;
  line-height: 1;

  a {
    font-weight: 500;
    font-size: 13px;
    text-transform: uppercase;
    padding: 10px 0;
    display: block;
    color: $gray;
    position: relative;

    &:hover {
      color: $accented-font-color;
    }
  }

  .alt-lang-dropdown-container & {
    a {
      color: white;

      @include media($large-screen-up) {
        color: $gray;

        &:hover {
          color: $accented-font-color;
        }
      }
    }
  }

  .dropdown--with-title & {
    &.active {
      a {
        color: $accented-font-color;
      }
    }
  }

  .sections-jump & {
    a {
      &:after {
        content: '\f107';
        font-family: "Icomoon";
        @include position(absolute, 50% 0 null null);
        margin-top: -7px;
      }
    }
  }
}

.dropdown__menu li:last-child {
  border: none;
}

.dropdown__submenu {
  li,
  li:last-child {
    border-bottom: none;
    border-top: 1px dashed $lightest-gray;

    a {
      text-transform: none;

      &:before {
        content: '\e115';
        font-family: Icomoon;
        font-size: 12px;
        color: $accented-font-color;
        margin-right: 5px;
      }
    }
  }
}

.show-menu {
  display: block;
}

.advanced-filters {
  .dropdown__menu.show-menu {
    margin-bottom: $base-spacing / 3;
  }
}

.advanced-filters__holder {
  padding: 20px 0;

  @include media($large-screen-up) {
    @include position(absolute, null 0 null null);
    width: 220%;
    padding: 20px;
    background-color: whitesmoke;
    margin-top: 20px;
    z-index: 4;
    border: 1px solid $accented-border-color;
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.4);

    .dropdown__menu.show-menu {
      z-index: 3;
    }
  }

  .dropdown__menu {
    li a {
      &:after {
        content: none;
      }
    }
  }

  #find-button {
    display: block;
    width: 100%;
    text-align: center;
    color: $accented-font-color;
    background-color: transparent;
    border-color: $accented-border-color;

    &:hover {
      color: white;
      background-color: $accented-background-color;
    }
  }
}

.filter--large {
  .dropdown__button {
    min-width: 225px;
  }
}

.news-bu-filter {
  @include media($large-screen-up) {
    width: 260px !important;

    a {
      @include ellipsis;
      display: block;
    }
  }
}

.news-year-filter {
	text-align: center;
  @include media($large-screen-up) {
    width: 125px !important;
  }
}

.dropdown__button--filter__title {
  color: $gray;
  white-space: nowrap;
  display: inline-block;
  max-width: 130px;
  overflow: hidden;
  vertical-align: bottom;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.sections-jump {
  .dropdown__button {
    a {
      text-transform: capitalize;
    }
  }
}

.map-view-dd {
  display: none;

  @include media($large-screen-up) {
    display: inline-block;
  }
}

// Advanced filters dropdowns
[id^="recruitingForm"] select,
.advanced-filters__holder select.dropdown__button,
.location-filter select.dropdown__button {
  @include appearance(none);
  height: 35px;
  border: 1px solid $lightest-gray;
  border-radius: 0;
  line-height: 34px;
  position: relative;
  background: white url('../img/map/filter-caret.png');
  background-position: 95% 55%;
  background-repeat: no-repeat;
  padding-left: 10px;

  &:focus {
    outline: none;
  }

  .ie8 &,
  .ie9 & {
    padding-right: 0;
  }
}

.advanced-filters__holder select.dropdown__button {
  float: none;
  color: $gray;
  padding-right: 27px;

  .ie8 &,
  .ie9 & {
    background-image: none;
    padding-right: 0;
  }
}

.advanced-filters__holder__item {
  display: block;
  margin-bottom: 10px;
}

.autocomplete-suggestions {
  top: 56px;
  background-color: white;
  padding: 5px 10px;
  border: 1px solid $accented-border-color;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.4);
  overflow-y: scroll;
  max-width: 100%;

  .quicksearch-global & {
    width: 100% !important;
    top: 70px;
  }
}

.autocomplete-suggestion,
.autocomplete-suggestions li {
  display: block;
  font-size: 14px;
  padding: 5px 0;

	a {
		display: block;
		width: 100%;
	}

  &:not(:last-of-type) {
    border-bottom: 1px solid $lightest-gray;
  }

  &.autocomplete-selected,
  &:hover {
    background-color: $lightest-gray;
    color: $accented-font-color;
  }
}

.dropdown--default {
  @include appearance(none);
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
  color: $gray;
  padding: 3px 25px 3px 15px;
  border: none;
  border-radius: 25px;
  outline: none;
  box-shadow: none;
  background: white url('../img/dd-arrow.png') no-repeat;
  background-position: 87% 11px;

  .alt-lang-dropdown-container & {
    margin-top: 8px;
    margin-bottom: 20px;

    @include media($large-screen-up) {
      margin-bottom: 0;
    }
  }
}

.languages-dd {
  @include media($large-screen) {
    margin-right: 0;
    margin-left: 6px;
  }

  .dropdown__button {
    color: $base-font-color;
    font-size: 30px;
    line-height: 1;
    position: relative;
    top: 2px;
    border: none;
    padding: 0 8px;

    &:after {
      content: none;
    }

    &:hover {
      background-color: transparent;
    }

    .language-indicator {
      background: $white;
      color: $accented-font-color;
      position: absolute;
      left: 0;
      top: 3px;
      font-weight: 700;
      font-size: 10px;
    }

    @include media($large-screen-up) {
      &:hover {
        
      }
    }
  }

  .dropdown__menu {
    @include position(fixed, null 0 null null);
    width: 100%;
    text-align: left;

    li {
      display: block;

      a {
        white-space: nowrap;
      }
    }

    @include media($large-screen) {
      position: absolute;
      width: auto;
    }
  }

  .language-flag,
  .language-name {
    display: inline-block;
    vertical-align: middle;
  }

  .language-flag {
    width: 18px;
    margin-right: 5px;
    position: relative;
    top: 1px;
  }
}

.not-available .language-flag {
  filter: grayscale(1);
  opacity: 0.5;
}



.dropdown__quick-info {
	padding-top: 8px;
	padding-bottom: 8px;
	width: auto;
	
	h5,
	p,
	.button {
		font-size: 14px;
		line-height: 1.5;
		margin: 0;
		white-space: nowrap;
	}
	
	h5 {
		color: $styrolution-green;
	}
	
	.button-wrapper {
		margin: 8px 0 7px;
	}
	
	.button {
		padding: .7em;
	}
}