// to address problem on iPhone with mobile navigation: 
html,
body {
	max-width: 100%;
	overflow-x: hidden;
}


// Large screen typo
body {
	@include media($large-screen-up) {
		font-size: 1em;
	}

	&.nav-is-open {
		overflow: hidden;
	}
}

@include media($large-screen-up) {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-weight: 300;
	}

	h1 {
		font-size: 48px;
	}

	h2 {
		font-size: 36px;
	}

	h3 {
		font-size: 34px;
	}
}

// Grid system
.wrapper {
	padding: 0 20px;

	@include media($medium-screen-up) {
		@include outer-container;
	}

	@include media($max-width) {
		padding-left: 0;
		padding-right: 0;
	}
}

.row {
	&:before,
	&:after {
		content: ' ';
		display: table;
		overflow: hidden;
	}

	&:after {
		clear: both;
	}

	*zoom: 1;
}

@-moz-document url-prefix() {
	.row {
		@extend %clearfix;
	}
}

@include media($medium-screen-up) {
	@for $i from 1 through 4 {
		.col-md-#{$i} {
			@include span-columns($i of 4);
			@include omega(2n);
		}
	}
}

@include media($large-screen-up) {
	@for $i from 1 through 12 {
		.col-lg-#{$i} {
			@include omega-reset(2n);
			@include span-columns($i);
		}
	}

	@for $j from 1 through 6 {
		.col-lg-shift-#{$j} {
			@include shift($j);
		}
	}
}

.col-lg-8-half {
	@include media($large-screen-up) {
		@include span-columns(4 of 8);
	}
}

// General elements
section {
	padding: $base-spacing 0;

	@include media($large-screen-up) {
		padding: 30px 0;

		&.profile,
		&.she {
			padding: 2.25em 0;
		}
	}

	&.profile,
	&.she {
		padding-bottom: 0;
	}
}

.small-pad {
	padding: 10px 0;
}

.no-pad {
	padding: 0 !important;
}

.title {
	line-height: 1;
	color: $accented-font-color;
	text-transform: uppercase;
	margin-bottom: 20px;

	@include media($large-screen-up) {
		font-size: 44px;
	}
}

.title--dark {
	color: $light-gray;
}

.title--padded {
	padding: 0 20px;

	@include media($max-width) {
		padding: 0;
	}
}

.title--with-link {
	float: left;

	+ .section-header {
		padding: 0;
		margin-bottom: 0;

		@include media($large-screen-up) {
			padding: 10px 0;
		}
	}
}

.subtitle {
	font-weight: 300;
	font-size: 18px;

	@include media($large-screen-up) {
		font-size: 21px;
	}

	.content-heading & {
		margin-bottom: 0;
	}
}

.link {
	display: block;
	font-weight: 300;
	color: $accented-font-color;
	text-transform: uppercase;
	padding-bottom: 8px;

	.icon-arrow-right-circle {
		margin-right: 7px;
		font-size: 30px;
		@include position(relative, 2px null null null);
	}

	i {
		@include transition (margin 0.3s ease-out);
		margin-right: 5px;
	}

	&:hover {
		i {
			margin-right: 15px;
		}
	}
}

td.link {
	display: table-cell;
}

.link--download {
	&:before {
		content: '\e60b';
		display: inline-block;
		font-family: "Icomoon";
		color: $gray;
		margin-right: 5px;
	}
}

.link--icon-dark {
	i {
		margin-right: 10px;
		font-size: 20px;
		color: $gray;
	}
}

.link--bordered {
	border-bottom: 1px solid $lightest-gray;
}

.link--external {
	i {
		margin-left: 5px;
	}
}

.section-anchor {
	font-size: 0;
	line-height: 0;
	visibility: hidden;
}

.media-holder--video {
	position: relative;
}

.media-holder-caption {
	padding: 10px;
	font-weight: 300;
	font-size: 14px;
	background-color: #F1F1F1;
	margin-bottom: 15px;

	p {
		margin-bottom: 0;
	}

	@include media($large-screen-up) {
		padding: 15px;
		margin-bottom: 35px;
	}
}

.media-holder--bordered {
	border-bottom: 3px solid $accented-border-color;
}

.js-fit-image {
	position: relative !important;
	opacity: 1 !important;
	vertical-align: middle;
}

.button--play {
	padding: 0;
	@include size(45px);
	@include position(absolute, 50% null null 50%);
	margin-top: -22px;
	margin-left: -22px;
	border: 0;
	box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
	font-size: 28px;
	text-align: center;
	line-height: 1.5;
	@include linear-gradient(to bottom, $styrolution-green-light, $styrolution-green);
	@include transition(border-radius 0.2s ease-in-out);

	&:hover {
		color: white;
		border-radius: 50%;
	}

	.no-boxshadow & {
		line-height: 1.4;
		border: 2px solid white;
	}
}

.button--play--large {
	@include size(50px);
	margin-top: -25px;
	margin-left: -25px;
	font-size: 42px;
	line-height: 1;

	.icon-play {
		left: .05em;
		position: relative;
		top: .05em;
	}

	@include media($large-screen-up) {
		@include size(74px);
		margin-top: -37px;
		margin-left: -37px;
		font-size: 64px;
		line-height: 1;
	}

	.no-boxshadow & {
		line-height: 1;
	}
}

.read-more {
	i {
		color: $gray;
		margin-right: 5px;
	}
}

.section-header {
	padding: 10px 0;
	@extend %clearfix;

	.title,
	.section-header__title {
		float: left;
		margin-bottom: 0;
	}

	.external-link {
		float: right;
		font-weight: 500;
		font-size: 14px;
		line-height: 1.9;
		position: relative;
		padding-right: 14px;

		i {
			font-size: 30px;
			line-height: 1;
			position: absolute;
			top: -3px;
			right: 0;
			margin-left: 5px;
			color: $gray;
		}
	}

	.title + .external-link {
		top: 22px;
	}

	.tab-link & {
		color: $gray;

		&:before {
			content: '\e114';
			font-family: Icomoon;
			color: $accented-font-color;
			float: right;
		}
	}

	> p {
		margin-bottom: 0;
	}

	@include media($large-screen-up) {
		margin-bottom: 25px;
	}
}

.title--small,
.section-header__title {
	font-size: 16px;
	text-transform: uppercase;

	@include media($large-screen-up) {
		font-size: 21px;
	}
}

.title--small {

	+ p {
		margin-top: 20px;

		&:last-of-type {
			margin-bottom: 0;
		}
	}
}

.desktop-revealed {
	display: none !important;

	&.is-visible {
		display: block !important;
	}

	@include media($large-screen-up) {
		display: block !important;
	}
}

.lg-no-mb {
	@include media($large-screen-up) {
		margin-bottom: 0 !important;
	}
}

// Lists
.bulleted {
	@extend %default-ul;

	li:not(:last-child) {
		margin-bottom: 12px;
	}
}

.side-content ul {
	margin-bottom: 0;

	li {
		line-height: 21px;
		padding: 5px 0;
		border-top: 1px solid #EAEAEA;
		
		&:first-child {
			border-top: none;
		}
	}
	
	.meta {
		display: block;
		margin: 4px 0 10px;
		font-size: 13px;
		color: $light-gray;
	}
	
	&.downloads {
		h5 {
			font-size: 1em;
		}
		
		li + li {
			margin-top: 0;
		}
		
		a:before {
			float: left;
		}
	}
}

.side-content.highlighted {
	
	.teaser {
		background: $white-hued;
	}
	
	ul.downloads {
		background: $white-hued;
		border-top: 2px solid #fff;
		margin-top: -10px;
		
		li {
			border-color: #fff;
			border-width: 2px;
			margin: 0;
			padding: 0;
		}
		
		.meta {
			margin-bottom: 0;
		}
		
		a {


			> span {
				@extend %icon;
				@extend %icon-file-empty;
				display: block;
				padding: .6em 1em .6em 3em;
				position: relative;

				&:before {
					font-size: 1.5em !important;
					height: 2em;
					left: .6em;
					line-height: 2em;
					margin-left: 0;
					margin-top: -1em;
					position: absolute;
					top: 50%;
					width: 1em;
				}
			}
			
			html.no-touch &:hover {
				background: $lightest-gray;
				
				.meta {
					color: $gray;
				}
			}
		}
	}
}

.list-title {
	font-weight: 700;
}

.deflist {
	dt {
		@include media($large-screen-up) {
			font-size: 21px;
		}
	}
}

.list--arrow {
	> li {
		border-bottom: 1px solid $lightest-gray;

		> a {
			font-weight: 700;
			font-size: 16px;
			color: $dark-gray;
			padding: 10px;
			display: block;
			position: relative;

			i {
				color: $accented-font-color;
				@include position(absolute, 0 10px 0 null);
				font-size: 20px;
				width: 20px;
				height: 30px;
				display: block;
				margin: auto;
				text-align: right;
			}

			@include media($large-screen-up) {
				padding: 18px 10px;

				i {
					height: 36px;
					font-size: 24px;
					right: 10px;
				}
			}
		}

		&:last-child {
			border-bottom: none;
		}
	}

	.results &,
	.locations-list & {
		> li > a {
			padding: 10px 20px;

			i {
				right: 20px;
			}

			@include media($large-screen-up) {
				padding: 10px;

				i {
					right: 10px;
				}
			}
		}
	}
}

.list--comma-separated {
	font-size: 14px;
	margin-bottom: $base-spacing;

	li {
		display: inline-block;

		&:not(:last-child):after {
			content: ', ';
			display: inline-block;
		}
	}
}

.list--columnized {
	@include media($medium-screen-up) {
		& > ul {
			column-count: 2;
			column-gap: 15px;
			columns: 2;

			> li {
				border: none;
				width: 100%;
			}
		}
		
		&[data-cols="3"] > ul {
			column-count: 3;
			columns: 3;
		}

		html.no-csscolumns & > ul {
			overflow: hidden;

			li {
				display: inline-block;
				margin-left: 2%;
				width: 46%;
			}
			
			&[data-cols="3"] {
				li {
					width: 30%;
				}
			}
		}
	}
}

// GRID LIST
// (a list that will be converted to a set of separate lists that show as columns)
.grid-list {
	@include clearfix;
	
	@include media($large-screen-up) {
		ul {
			display: none;
			li {
				float: none;
			}
		}
		
		&._has-columns {
			ul {
				display: block;
				float: left;

				ul {
					float: none;
					margin: 0;
				}
			}
		}
	}
}

.results-counter {
	padding: 10px 20px;
	color: $light-gray;
	display: block;

	@include media($large-screen-up) {
		padding-left: 10px;
	}
}

// List with header
.list-header {
	padding: 2px 20px;
	font-weight: 500;
	font-size: 16px;
	background-color: $lightest-gray;

	@include media($large-screen-up) {
		padding-left: 10px;
		padding-right: 10px;
	}
}

.list-header--dark {
	color: white;
	background-color: $dark-gray;
}

.list--arrow__title,
.list--arrow__subtitle {
	display: block;
	padding-right: 20px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.list--arrow__subtitle {
	line-height: 1.2;
	color: $gray;
}

.list--contacts {
	li {
		padding: 10px 0;
		@extend %clearfix;

		&:not(:last-child) {
			border-bottom: 1px solid #dcdcdc;
		}
	}
}

.list--contacts__title {
	font-weight: 500;
	font-size: 14px;
	text-transform: uppercase;
}

.list-contacts__contact {
	float: right;
}

.list--pretitle {
	margin-bottom: $base-spacing / 2;

	> li {
		padding: 5px 0;
		border-bottom: 1px dashed #dcdcdc;
		position: relative;

		&:last-child {
			border-bottom: none;
		}

		@include media($large-screen-up) {
			padding: 10px 0;
		}
	}
}

.list--pretitle__pretitle {
	display: block;
	font-weight: 500;
	font-size: 14px;
	text-transform: uppercase;

	@include media($large-screen-up) {
		display: inline-block;
		width: 140px;
	}
}

.list--pretitle__title {
	font-weight: 700;
	font-size: 21px;

	@include media($large-screen-up) {
		display: inline-block;
	}
}

.list--pretitle__links {

	a:not(:last-child) {
		padding-right: 20px;
	}

	@include media($large-screen-up) {
		display: inline-block;
		margin-left: 20px;
	}
}

.list--details {
	li {
		margin-bottom: $base-spacing / 2;
	}
}

.list--details__title {
	display: block;
	font-weight: 500;
	font-size: 13px;
	text-transform: uppercase;
	line-height: 1;
	margin-bottom: 5px;
}

.switch-view-holder {
	display: inline-block;
	position: absolute;
	top: 10px;
	right: 0;

	> span {
		font-weight: 700;
		font-size: 14px;
		display: inline-block;
		margin-right: 10px;
	}
}

.table-switch-view {
	display: inline-block;
}

.product-icon--download {
	font-weight: 500;
	font-size: 13px;

	&:before {
		content: '\e60b';
		display: inline-block;
		font-family: Icomoon;
		margin-right: 5px;
	}
}

.product-icon--mail {
	font-weight: 500;
	font-size: 13px;

	&:before {
		content: '\f0e0';
		display: inline-block;
		font-family: Icomoon;
		margin-right: 5px;
	}
}

// Borders
.top-bordered {
	padding-top: 5px;
	border-top: 1px solid $accented-border-color;
}

.top-bordered__title {
	font-weight: 500;
	font-size: 18px;
	text-transform: uppercase;
	margin-bottom: 15px;
}

.mobile-top-pad {
	padding-top: $base-spacing;

	@include media($large-screen-up) {
		padding-top: 0;
	}
}

.desktop-faded-overlay {
	@include media($large-screen-up) {
		position: relative;

		&:before {
			content: '';
			display: block;
			background: rgba(black, .6) url('../img/pattern-small.png');
			@include position(absolute, 0 0 0 0);
			// version 2
			background: white;
		}
	}

	.no-multiplebgs & {
		&:before {
			background-image: url('../img/old-ie/ie-dark-pattern-overlay.png');
		}
	}
}

.parallax {
	overflow: hidden;
}

.parallax-bg {
	position: relative;
}

// back to top
.back-to-top {
	display: inline-block;
	@include size(50px);
	@include position(fixed, null 20px 20px null);
	z-index: 10;
	background-color: $styrolution-green;
	font-size: 50px;
	line-height: 46px;
	color: white;
	text-align: center;
	box-shadow: 0 0 10px rgba(black, .05);
	visibility: hidden;
	opacity: 0;
	transition: opacity .3s 0s, visibility 0s .3s;

	&:visited,
	&:hover {
		color: white;
	}

	&.btt-is-visible,
	&.btt-fade-out,
	.no-touch &:hover {
		transition: opacity .3s 0s, visibility 0s 0s;
	}

	&.btt-is-visible {
		visibility: visible;
		opacity: 1;
	}

	&.btt-fade-out { /* if the user keeps scrolling down, the button is out of focus and becomes less visible */
		opacity: .5;
	}

	.no-touch &:hover {
		opacity: 1;
	}

	span {
		font-weight: 700;
		font-size: 14px;
		display: block;
		line-height: 0.2;
	}

	.ie8 & {
		background-image: url('../img/old-ie/ie-btt-arrow.png');
		background-repeat: no-repeat;
		background-position: 13px 13px;
		padding-top: 33px;

		.icon-angle-up:before {
			content: none;
		}
	}
	
	@include media($medium-screen-up) {
		bottom: 3em;
	}
	
	@include media($large-screen-up) {
		bottom: 2.5em;
	}
}

.button-contact {
	@include media($large-screen-up) {
    display: inline-block;
    font-size: 13px;
    padding: 5px 10px 6px;
  }
}

a.link-block {
	display: inline-block;
	margin-bottom: 1em;
}

// Video background
.background {
	@include position(absolute, 0 0 0 0);
	z-index: 0;
	background-size: cover;
	background-position: center;

	&--video {
		background-image: none !important;
		@include transform(translate3d(0, 0, 0));

		video {
			@include position(absolute, 50% null null 50%);
			min-width: 100%;
			min-height: 100%;
			width: auto;
			height: auto;
			display: block;
			@include transform(translate(-50%, -50%));
		}
	}
}

.notice {
	border-left: 8px solid $styrolution-orange;
	margin: 2em 0 2em;
	padding: 1.2em 20px 0;

	h3 {
		color: $styrolution-orange;
		font-size: 24px;
		margin: 0 0 .8em;
	}
}

// =========================================== //
// 									Animations
// =========================================== //

.animated-from-center {
	@include transition (left 0.3s ease-in-out 0.8s);
}

.animated-from-sides {
	@include transition (all 0.3s ease-in-out 0.8s);
}

// animate only on large viewports
@include media($large-screen-up) {
	.animated-to-left {
		left: 26%;
	}

	.animated-to-right {
		left: -26%;
	}

	.animated-from-center {
		&.on-place {
			left: 0;
		}
	}

	.animated-from-left {
		right: 200px;
	}

	.animated-from-right {
		right: -200px;
	}

	.animated-from-sides {
		opacity: 0;

		&.on-place {
			opacity: 1;
			right: 0;
		}
	}

	// EPIServer edit mode fixes
	#applicationContainer {
		.animated-to-right,
		.animated-to-left {
			left: 0;
		}

		.animated-from-right,
		.animated-from-left {
			right: 0;
		}

		.animated-from-sides {
			opacity: 1;
		}
	}
}

.animated {
	@include animation-duration(1s);
	@include animation-fill-mode(both);
	@include animation-delay(0.6s);
}

@include keyframes(fadeIn) {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.fade-in {
	@include animation-name(fadeIn);
}

@include keyframes(fadeInLeft) {
  0% {
    opacity: 0;
    @include transform(translate3d(-100%, 0, 0));
  }

  100% {
    opacity: 1;
    @include transform(none);
  }
}

.fade-in-left {
	@include animation-name(fadeInLeft);
}

@include keyframes(fadeInRight) {
  0% {
    opacity: 0;
    @include transform(translate3d(100%, 0, 0));
  }

  100% {
    opacity: 1;
    @include transform(none);
  }
}

.fade-in-right {
	@include animation-name(fadeInRight);
}

@include keyframes(fadeInDown) {
  0% {
    opacity: 0;
    @include transform(translate3d(0, -100%, 0));
  }

  100% {
    opacity: 1;
    @include transform(none);
  }
}

.fade-in-down {
	@include animation-name(fadeInDown);
}

@include keyframes(fadeInUp) {
  0% {
    opacity: 0;
    @include transform(translate3d(0, 100%, 0));
  }

  100% {
    opacity: 1;
    @include transform(none);
  }
}

.fade-in-up {
	@include animation-name(fadeInUp);
}

// Footer social icons hover effect
@include keyframes(toBottomFromTop) {
  49% {
    @include transform(translateY(100%));
  }
  50% {
    opacity: 0;
    @include transform(translateY(-100%));
  }
  51% {
    opacity: 1;
  }
}

.already-visible {
  transform: translateY(0);
  animation: none;
}



// radiobuttons displayed in a row (should be located in “_forms.scss” but the SASS parser complains …)
.inline-options {
	label {
		display: block;
	}
	@include media($medium-screen-up) {
		margin-left: -2em;
		label {
			display: inline-block;
			margin-left: 2em;
			vertical-align: top;
		}
	}
}


// =========================================== //
// 									Components
// =========================================== //

// status display related to icon
.icon,
[class^="icon-"],
[class^="iconprop-"] {
	.status {
		background: $styrolution-blue;
		border-radius: 1em;
		color: $inverted-font-color;
		display: none;
		font-size: 11px;
		font-style: normal;
		line-height: 1;
		padding: .1em .4em;
		vertical-align: middle;
		
		@include media($large-screen-up) {
			display: inline-block;
		}
	}
}

// Icons
[class^="icon-"] {
	@extend %icon;
}

.icon-prop-chemical-resistance:before {
  @extend %icon-prop-chemical-resistance;
}
.icon-prop-flowability:before {
  @extend %icon-prop-flowability;
}
.icon-prop-medical:before {
  @extend %icon-prop-medical;
}
.icon-prop-transparency:before {
  @extend %icon-prop-transparency;
}
.icon-automotive {
  @extend %icon-automotive;
}
.icon-construction {
  @extend %icon-construction;
}
.icon-electronics {
  @extend %icon-electronics;
}
.icon-healthcare {
  @extend %icon-healthcare;
}
.icon-household {
  @extend %icon-household;
}
.icon-packaging {
  @extend %icon-packaging;
}
.icon-toys-leisure {
  @extend %icon-toys-leisure;
}
.icon-plus {
  @extend %icon-plus;
}
.icon-minus {
  @extend %icon-minus;
}
.icon-cross {
  @extend %icon-cross;
}
.icon-menu {
  @extend %icon-menu;
}
.icon-search {
  @extend %icon-search;
}
.icon-tags {
  @extend %icon-tags;
}
.icon-bookmark {
  @extend %icon-bookmark;
}
.icon-rss {
  @extend %icon-rss;
}
.icon-youtube {
  @extend %icon-youtube;
}
.icon-twitter {
  @extend %icon-twitter;
}
.icon-facebook {
  @extend %icon-facebook;
}
.icon-arrow-right-circle {
  @extend %icon-arrow-right-circle;
}
.icon-clock {
  @extend %icon-clock;
}
.icon-external {
  @extend %icon-external;
}
.icon-warning {
  @extend %icon-warning;
}
.icon-download {
  @extend %icon-download;
}
.icon-linkedin2 {
  @extend %icon-linkedin2;
}
.icon-grid {
  @extend %icon-grid;
}
.icon-list {
  @extend %icon-list;
}
.icon-globe {
  @extend %icon-globe;
}
.icon-earth {
  @extend %icon-earth;
}
.icon-close {
  @extend %icon-close;
}
.icon-play {
  @extend %icon-play;
}
.icon-chevron-left {
  @extend %icon-chevron-left;
}
.icon-chevron-right {
  @extend %icon-chevron-right;
}
.icon-chevron-up {
  @extend %icon-chevron-up;
}
.icon-chevron-down {
  @extend %icon-chevron-down;
}
.icon-twitter-flat {
  @extend %icon-twitter-flat;
}
.icon-facebook-flat {
  @extend %icon-facebook-flat;
}
.icon-rss-flat {
  @extend %icon-rss-flat;
}
.icon-google-plus {
  @extend %icon-google-plus;
}
.icon-caret-down {
  @extend %icon-caret-down;
}
.icon-caret-up {
  @extend %icon-caret-up;
}
.icon-mail {
  @extend %icon-mail;
}
.icon-linkedin {
  @extend %icon-linkedin;
}
.icon-angle-left {
  @extend %icon-angle-left;
}
.icon-angle-right {
  @extend %icon-angle-right;
}
.icon-angle-up {
  @extend %icon-angle-up;
}
.icon-angle-down {
  @extend %icon-angle-down;
}
.icon-youtube-flat {
  @extend %icon-youtube-flat;
}
.icon-language {
  @extend %icon-language;
}
.icon-newspaper {
  @extend %icon-newspaper;
}
.icon-checkmark {
  @extend %icon-checkmark;
}
.icon-file-empty {
  @extend %icon-file-empty;
}
.icon-cart {
  @extend %icon-cart;
}