.contact-details {
	.col-lg-3 {
		@include omega(4n);
	}
}

.contact-details-list {
	padding-bottom: 0;

	.col-lg-3 {
		margin-bottom: 40px;
		@include omega(4n);
	}

	.col-lg-4 {
		margin-bottom: 40px;
		@include omega(3n);
	}

	.col-lg-6 {
		margin-bottom: 40px;
		@include omega(2n);
	}
}

.contact-for {
	font-weight: 700;
	line-height: 1.2;
	margin-bottom: 10px;
}

.contact-details__address {
	font-size: 14px;
	margin-bottom: $base-spacing;
}

.contact-way {
	display: block;
	font-size: 14px;
	color: $gray;
	margin-bottom: 5px;

	span {
		display: inline-block;
		margin-right: 5px;
		font-weight: 700;
	}

	&:hover {
		span {
			color: $gray;
		}
	}

	a[href*="mailto"] {
		word-break: break-word;
	}
}

.contact-way--highlighted {
	color: $accented-font-color;

	span {
		color: $gray;
	}
}
