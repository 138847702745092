/** RESET AND LAYOUT
===================================*/

.bx-wrapper {
	position: relative;
	margin: 0 auto;
	padding: 0;
	*zoom: 1;

	img {
		max-width: 100%;
	}

	.bx-pager,
	.bx-controls-auto {
		position: absolute;
		bottom: 0;
		width: 100%;

		.bx-pager-item,
		.bx-controls-auto-item {
			display: inline-block;
			*zoom: 1;
			*display: inline;
		}
	}

	.bx-pager {
		text-align: center;
		font-size: .85em;
		font-family: Arial;
		font-weight: bold;
		color: #666;
		padding-top: 20px;
		display: none;

		&.bx-default-pager a {
			background: #666;
			text-indent: -9999px;
			display: block;
			width: 10px;
			height: 10px;
			margin: 0 5px;
			outline: 0;
			-moz-border-radius: 5px;
			-webkit-border-radius: 5px;
			border-radius: 5px;

			&:hover,
			&.active {
				background: #000;
			}
		}
	}

	.bx-prev {
		left: 0;

		&:hover {
			background-color: $hover-link-color;
		}

		&:after {
			content: '\f104';
			font-family: Icomoon;
		}
	}

	.bx-next {
		right: 0;

		&:hover {
			background-color: $hover-link-color;
		}

		&:after {
			content: '\f105';
			font-family: Icomoon;
		}
	}

	.bx-controls-direction a {
		position: absolute;
		top: 50%;
		margin-top: -40px;
		outline: 0;
		text-align: center;
		font-size: 30px;
		line-height: 76px;
		z-index: 10;
		@include size(52px 80px);
		background-color: $accented-background-color;
		color: white;
		opacity: 0;
		@include transition(opacity 0.3s ease-in-out);

		&.disabled {
			display: none;
		}
	}

	&:hover {
		.bx-controls-direction a {
			opacity: 1;
		}
	}

	.bx-controls-auto {
		text-align: center;

		.bx-start {
			display: block;
			text-indent: -9999px;
			width: 10px;
			height: 11px;
			outline: 0;
			margin: 0 3px;

			&:hover,
			&.active {
				background-position: -86px 0;
			}
		}

		.bx-stop {
			display: block;
			text-indent: -9999px;
			width: 9px;
			height: 11px;
			outline: 0;
			margin: 0 3px;

			&:hover,
			&.active {
				background-position: -86px -33px;
			}
		}
	}

	.bx-controls.bx-has-controls-auto.bx-has-pager {
		.bx-pager {
			text-align: left;
			width: 80%;
		}

		.bx-controls-auto {
			right: 0;
			width: 35px;
		}
	}

	.bx-caption {
		position: absolute;
		bottom: 0;
		left: 0;
		background: #666;
		background: rgba(80, 80, 80, 0.75);
		width: 100%;

		span {
			color: #fff;
			font-family: Arial;
			display: block;
			font-size: .85em;
			padding: 10px;
		}
	}
}

.custom-directions a {
	position: absolute;
	top: 50%;
	margin-top: -40px;
	outline: 0;
	text-align: center;
	font-size: 30px;
	line-height: 76px;
	z-index: 10;
	@include size(52px 80px);
	background-color: $accented-background-color;
	color: white;
	opacity: 0;
	@include transition(opacity 0.3s ease-in-out);

	@include media(max-width 1024px) {
		display: none;
	}

	&.disabled {
		display: none;
	}

	&.bx-prev {
		left: 0;

		&:hover {
			background-color: $hover-link-color;
		}

		&:after {
			content: '\f104';
			font-family: Icomoon;
		}
	}

	&.bx-next {
		right: 0;

		&:hover {
			background-color: $hover-link-color;
		}

		&:after {
			content: '\f105';
			font-family: Icomoon;
		}
	}
}

.desktop-slider-holder {
	&:hover {
		.custom-directions a {
			opacity: 1;
		}
	}
}

.desktop-slider-holder {
	.bx-viewport {
		&.on-right {
			@include media($medium-screen-up) {
				&:before {
					content: '';
					@include position(absolute, 1px null 0 0);
					z-index: 4;
					width: 240px;
					background: white;
				}
			}

			@include media($max-width) {
				&:before {
					width: 220px;
				}
			}
		}
	}

	&.content--highlighted--dark {
		.bx-viewport {
			&.on-right {
				@include media($large-screen-up) {
					&:before {
						background: $gray url('../img/pattern-light-small.png');
					}
				}
			}
		}
	}

	&.desktop-slider-holder--titled {
		.bx-viewport {
			&.on-right {
				@include media($large-screen-up) {
					&:before {
						top: 90px;
					}
				}
			}
		}
	}
}
