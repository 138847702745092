.search {
	position: relative;

	&:before {
    content: '\e600';
    font-family: Icomoon;
    font-size: 20px;
    color: $dark-gray;
    display: inline-block;
    @include position(absolute, 2px null null 15px);
  }
}

.search-results__topbar {
	padding: 28px 0 50px;
	font-weight: 700;
	background: #F8F8F8 url(../img/pattern-tinted.png);
	@include clearfix;

	p {
		float: right;
		margin-bottom: 0;
		position: relative;
    z-index: 2;
	}

	@include media($large-screen-up) {
		padding: 28px 0;
	}
}
