// Markets
.market {
	position: relative;
	overflow: hidden;
	@extend %clearfix;
	border-bottom: 1px solid $lightest-gray;

	&:hover {
		cursor: pointer;
		cursor: hand;
	}

	@include media(max-width 768px) {
		background-image: none !important;
	}

	@include media($large-screen-up) {
		border-bottom: none;
		background-size: cover;
		background-position: center;

		&:before {
			content: '';
			display: block;
			@include position(absolute, 0 0 0 0);
			background: rgba(black, .4) url('../img/pattern-small.png');
			@include transition (opacity 0.4s);
		}
	}

	.no-rgba &:before {
		content: '';
		@include position(absolute, 0 0 0 0);
		background-image: url('../img/old-ie/ie-markets-pattern-overlay.png');
	}

	.wrapper {
		position: relative;
		z-index: 2;
	}

	.market__toggle-icon {
		@include position(absolute, 50% 18px null null);
		margin-top: -12px;
		font-size: 30px;
		line-height: 0.8;
		color: $accented-font-color;

		@include media($large-screen-up) {
			height: 40px;
			margin-top: -20px;
			font-size: 50px;
			color: white;
		}

		@include media(1025px) {
			right: 0;
		}
	}

	@include media($medium-screen-up) {
		.title {
			display: inline-block;
			float: left;
			margin-bottom: 0;
			line-height: 1.6;
		}

		.button {
			padding-top: 5px;
			float: right;
			margin-bottom: 0;

			i {
				line-height: 1;
			}
		}
	}

	@include media($large-screen-up) {
		.title {
			line-height: 1;
			color: white;
		}
	}

	@include media(1025px) {
		.button {
			display: none;
		}

		&:hover {
			&:before {
				opacity: 0.35;
			}
		}

		&.is-open:hover:before {
			opacity: 1;
		}
	}

	.market__header,
	.market__content {
		@include clearfix;
	}

	.market__header {
		padding: 20px 0;

		@include media($large-screen-up) {
			padding: 42px 0;
		}

		.title {
			margin-bottom: 0;
		}
	}

	.market__content {
		height: 0;
		overflow: hidden;

		@include media($large-screen-up) {
			font-weight: 300;
			font-size: 20px;
			color: $gray;

			.market__content__text__description {
				width: 50%;
				margin-bottom: 50px;
			}

			.market__content__logos__heading {
				font-weight: 500;
				text-transform: uppercase;
				margin-bottom: 15px;
			}
		}

		&__image {
			height: 150px;
	    overflow: hidden;
	    vertical-align: middle;
	    margin-bottom: 20px;

			@include media($large-screen-up) {
				display: none;
			}
		}

		&__logos {
			padding: 10px 0;
	    background: rgba(0, 0, 0, 0.6) url(../img/pattern-light-small.png);
			position: relative;
			z-index: 9;
			margin-bottom: 30px;

			.no-rgba & {
				background: url(../img/old-ie/ie-dark-pattern-overlay.png);
			}
		}
	}

	.market__logos {
		li {
			display: inline;
			*zoom: 1;
			display: inline-block;
			font-size: 14px;
			margin: 5px 20px 5px 0;

			a {
				display: block;
				color: white;

				&:hover {
					color: $accented-font-color;
				}
			}

			@include media($large-screen-up) {
				margin-right: 30px;
			}
		}

		@include media($large-screen-up) {
			width: 50%;
		}
	}

	.market__shape {
		display: none;

		@include media($large-screen-up) {
			display: block;
			width: 0;
			@include position(absolute, 0 null 0 0);
			background-color: white;
			@include transition(width 0.3s);

			&:after {
				content: '';
				display: block;
				width: 10%;
				height: 100%;
				left: 100%;
				top: 0px;
				background: linear-gradient(to right bottom, white 50%, transparent 50%);
				position: absolute;
			}
		}
	}

	&.is-open {
		@include media($large-screen-up) {
			&:before {
				background : linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.2) 78.69%, rgba(0, 0, 0, 0.2) 100%);
			}

			.title {
				color: $gray;
			}

			.market__shape {
				display: block;
				width: 10px;
				@include position(absolute, 0 null 0 0);
				background-color: white;

				@include media($large-screen-up) {
					width: 55%;

					&:after {
						content: '';
				    display: block;
				    width: 30%;
				    height: 100%;
				    left: 100%;
				    top: 0px;
				    background: linear-gradient(to right bottom, white 50%, transparent 50%);
				    position: absolute;
					}
				}
			}
		}

		.market__content {
			height: auto;
		}
	}
}

.markets-businesses {
	margin-bottom: 0 !important;

	li:not(:last-child) {
		margin-bottom: $base-spacing / 3;
	}
}
