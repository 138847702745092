.carousel {
	padding: 0;

	+ .feature {
		@include media($large-screen-up) {
			margin-top: -120px;
			padding-top: 180px;
			// version 2
			margin-top: -215px;
			padding-top: 260px;
		}
	}
}

.carousel--single {
	padding-bottom: $base-spacing * 1.5;
}

.hero {
	min-height: 480px;
	background-size: cover;
	background-position: center;
	position: relative;
	overflow: hidden;

	@include media($large-screen-up) {
		min-height: 650px;
		// version 2
		min-height: 520px;

		&.hero--home {
			background-image: none !important;
		}

		& + .carousel__slider {
			margin-top: -100px;
			// version 2
			margin-top: 0;
		}
	}

	@include media(1025px) {

		// EPIServer edit mode fix
		#applicationContainer & {
			background-attachment: initial;
		}
	}

	&:before {
		content: '';
		display: block;
		@include position(absolute, 0 0 0 0);
		@include linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, .4));
	}

	.wrapper {
		position: relative;
	}

	.wrapper,
	.hero-news {
		z-index: 1;
	}

	.no-cssgradients & {
		.old-ie-overlay {
			@include position(absolute, 0 0 0 0);
			background-image: url('../img/old-ie/ie-carousel-overlay.png');
		}
	}
}

.hero-title {
	padding-top: 10px;
	color: white;
	text-transform: uppercase;
	margin-bottom: 20px;
	position: relative;
	top: -300px;
	opacity: 0;

	strong {
		font-weight: 500;
	}

	@include media($large-screen-up) {
		padding-top: 40px;
		line-height: 0.9;
		margin-bottom: 40px;
	}
}

.carousel-button {
	opacity: 0;
	color: $accented-font-color;
	background-color: white;
	border-color: white;

	&:hover {
		color: white;
		background-color: $accented-background-color;
		border-color: $accented-border-color;
	}
}

.conword {
	text-transform: uppercase;
	margin-bottom: 0;
	position: relative;
	z-index: 2;

	a {
		color: white;
		// fix firefox weight
		font-weight: 700;

		&:hover {
			cursor: pointer;
			cursor: hand;
		}
	}

	@include media($large-screen-up) {
		display: inline-block;
		float: left;
		padding-right: 10px;
		margin-right: 10px;
		border-right: 1px solid rgba(white, .3);
		line-height: 1.3;
		margin-bottom: 0;
	}
}

.pin-point {
	position: absolute;
	text-transform: uppercase;
	width: 100%;
	bottom: 80px;

	&--light {
		color: white;
	}

	&--dark {
		color: $base-font-color;
	}

	.col-lg-6 {
		float: right;
	}

	h4 {
		padding-top: 20px;
		padding-left: 10px;
		border-left: 2px solid currentColor;
		font-weight: 700;
		position: relative;
		right: -150%;
		opacity: 0;

		&:before {
			content: '';
			display: block;
			@include size(10px);
			border: 2px solid currentColor;
			border-radius: 50%;
			@include position(absolute, -10px null null -6px);
		}
	}

	&__message {
		font-family: $header-font-family;
	}
}

.carousel__hero-news {
	@include position(absolute, null 0 0 0);
	padding: 16px 0 10px;
	background: rgba(0, 0, 0, .5) url('../img/pattern-light-small.png');

	@include media($large-screen-up) {
		height: 50px;
		overflow: hidden;
		bottom: 100px;
		// version 2
		bottom: 0;
	}

	.no-multiplebgs & {
		background-image: url('../img/old-ie/ie-news-slider-bg.png');
	}
}

.scroll-down-arrow {
	font-size: em(60);
	line-height: 1;
	text-align: center;
	color: white;
	display: none;

	@include media($large-screen-up) {
		display: block;
		@include position(absolute, null 0 160px 0);
		// version 2
		bottom: 60px;
	}
}

.carousel__hero-news__slider {
	.news-heading {
		color: white;
		font-size: 14px;
		
		@include media($large-screen-up) {
			width: 90%;
			height: 21px;
			display: block;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}
	
	.slick-prev, 
	.slick-next {
		z-index: 2;
	}
}

.carousel__slider {
	.carousel__slide {
		margin: 20px 20px 0;

		.carousel__slide__media-holder {
			height: 125px;
			overflow: hidden;
			margin-bottom: 10px;

			@include media($large-screen-up) {
				height: 160px;

				img {
					width: 100%;
					position: relative;
					top: 50%;
					@include transform(translateY(-50%));
				}
			}
		}

		@include media($medium-screen-up) {
			margin-left: 5px;
			margin-right: 5px;
		}
	}

	.no-csstransforms & {
		.carousel__slide {
			.carousel__slide__media-holder {
				img {
					top: 0;
				}
			}
		}
	}

	.slick-prev,
	.slick-next {
		top: 40%;
		margin-top: -20px;

		@include media($large-screen-up) {
			opacity: 0;
			top: 42%;
			@include transition(opacity 0.3s ease-in-out);
		}
	}

	&:hover {
		.slick-prev,
		.slick-next {
			@include media($large-screen-up) {
				opacity: 1;
			}
		}
	}

	.slick-prev {
		right: auto;
		left: 0;
	}

	.slick-next {
		right: 0;
	}
}

.carousel__slide__title {
	font-weight: 500;
	color: $accented-font-color;
}

// Carousel with nav
.carousel-nav,
.nav-to-section {
	padding: 35px 20px;

	.subnav {
		position: relative;
		top: 0;
		background-color: transparent;

		li {
			a {
				color: $gray;
				padding: 5px 20px 5px 0;
				width: 100%;
				height: 31px;
				position: relative;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;

				&:after {
					position: absolute;
					right: 0;
					content: '\f107';
				}

				&:hover,
				&.active {
					color: $accented-font-color;
				}
			}

			&.subnav__child {
				a {
					text-transform: none;

					&:before {
						content: '\e115';
						font-family: Icomoon;
						font-size: 12px;
						color: $accented-font-color;
						margin-right: 10px;
					}
				}
			}
		}
	}
}
