/**
 * All Images
 * Prevent spacing below images.
 */
img {
	display: block;
}



/**
 * Floating Image
 * A floating image box not using the grid system (“col” classes).
 * It takes 100% width on mobile. From tablet upwards it starts to 
 * float, takes its natural width and has a maximum width of 480px.
 */
.image-to-left {
	margin: 0 0 1em;
	
	img {
		width: 100%;
	}
	
	@include media($medium-screen-up) {
		float: left;
		margin-right: 1em;
		
		img {
			width: auto;
			max-width: 480px;
		}
		
		& + ul,
		& + ol { /* to let lists flow aside the image (prevent bullets from being hiddenin IE) */
			overflow: hidden;
		}
	}
}



/**
 * Grid Image
 * Image floating by using the “col” classes.
 */
[class*=col-lg-] {
	> a > img,
	> img {
		max-width: none;
		width: 100%;
	}
}



/**
 * Images in Sidebar
 */
.sidebar {
	img {
		max-width: 320px;
		width: 100%;
	}
	
	img.truesize {
		width: auto;
	}
	
	img.centered {
		margin-left: auto;
		margin-right: auto;
	}
	
	.teaser {
		img + p,
		img + ul {
			margin-top: .4em;
		}
	}
}