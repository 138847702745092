.user-management {
	#order-management-application-details {
		.form-block {
			margin-top: 1em;
		}

		display: none;
	}
	.address-list-headline {
		font-weight: bold;
		margin-top: 1em;
	}
	.password-strength {
		float: right;
		font-size: 0.8em;
	}
	#password-success {
		margin-top: 1em;
	}

	.expandable {
		position: relative;

		ul.roles-list {

			li:before {
				content: "-";
				padding-right: 0.5em;
			}
		}
		.user-management__key {
			display: inline-block;
			width: 40%;
			vertical-align: top;
		}
		.user-management__value {
			display: inline-block;
			overflow: hidden;
			text-overflow: ellipsis;
			width: 50%;
			white-space: nowrap;
			vertical-align: top;

			ul.roles-list {
				margin-top: 1em;
			}
		}
		a.expand-link {
			display: inline-block;
			right: 0;
			position: absolute;
			font-size: 1em;
			cursor: pointer !important;

			.spaced-icon {
				margin-right: 6px;
			}

			&:before {
				content: "";
			}
		}
		.expandable-content {
			form {
				margin-top: 1em;
			}
			@include media($large-screen-up) {
				padding-left: 16.6666666666%; // 2 colums left
				padding-right: 16.6666666666%; // 2 columns right
			}

			.move-down {
				margin-top: 1em;
			}
			.button-wrapper {
				padding: 0;
				margin-top: 1em;
				border: none;

				.button {
					margin-right: 15px;
				}
			}
			th {
				background-color: transparent;
				color: $gray;
				padding: 0 10px 0 10px;
			}
			tr {
				background-color: transparent;
			}
			td {
				border: none;
			}
			fieldset {
				.legend {
					margin: 0.5em 0 0 0;
				}
			}
			.radio {
				label {
					font-weight: normal;
					vertical-align: top;

					.legend {
						font-size: 0.8em;
						color: $medium-gray;
						margin-top: 0;
						margin-bottom: 1em;
					}
				}
			}
		}
	}

	#assign-soldto-form > fieldset.expandable {
		padding: 1.5em;
		border-bottom: none;
		margin-bottom: 0;

		h5 {
			color: $styrolution-green;
		}
	}
	#assign-soldto-form > .button-wrapper {
		border-top: 1px solid $lightest-gray;
		margin-top: 0;
		padding-top: 1em;
	}
	.user-management__assigned-ship-tos {
		padding-left: 24px !important;
		padding-right: 24px !important;

		fieldset {
			border: none;
			margin: 0;
			padding: 1em;
			background-color: $white-hued;
			border-bottom: 1px solid #fdfdfd;
		}
	}

	.radio-wrapper {
		display: table;

		.radio {
			display: table-row;
			white-space: normal;

			input[type='checkbox'] {
				display: table-cell;
			}
			label {
				display: table-cell;
			}
		}
	}
}