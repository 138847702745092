.button-press-media {
  display: block;
  text-align: center;
  @include media($large-screen) {
    display: inline-block;
    position: relative;
    top: -5px;
  }
}

.media-centre {
  position: relative;
  overflow: hidden;

  &__card {
    margin-bottom: 10px;
    position: relative;
    overflow: hidden;
    width: 100%;

    &-sizer {
      opacity: 0;
      visibility: hidden;
      display: inline-block;
      min-height: 30px;
      float: left;

      @include media($large-screen) {
        width: 24%;
        margin-left: 1%;
      }
    }

    &-inner {
      position: relative;
      height: 100%;
    }

    &-carousel {
      &-title {
        font-weight: 700;
        font-size: 14px;
        margin-bottom: 10px;
        text-transform: uppercase;
      }

      li {
        a {
          color: white;
        }
      }

      .slick-prev,
      .slick-next {
        top: -5px;
        background: transparent;
        height: auto;
        &:before {
          color: $accented-font-color;
        }
        &:hover {
          background: transparent;
          &:before {
            color: white;
          }
        }
      }

      @include media($large-screen) {
        margin-top: $base-spacing / 2;
        width: 80%;

        .slick-prev {
          right: -60px;
        }

        .slick-next {
          right: -100px;
        }
      }
    }

    &-link {
      color: $base-font-color;
      .media-centre__card--featured & {
        color: white;
      }
    }

    &-header {
      background-color: white;
      position: relative;
      padding: 10px;
      border-top: 2px solid $accented-border-color;

      i {
        color: $accented-font-color;
        font-size: 24px;
        @include position(absolute, 3px 10px null null);
      }

      @include media($large-screen) {
        padding: 15px;

        i {
          font-size: 50px;
          top: 2px;
          right: 15px;
        }
      }
    }

    &-body {
      padding: 10px;

      @include media($large-screen) {
        padding: 0 15px 15px;
      }
    }

    &-image-holder {
      max-height: 150px;
      overflow: hidden;

      img {
        vertical-align: middle;
      }

      @include media($large-screen) {
        max-height: initial;
      }
    }

    &-desc {
      padding-top: 10px;
      padding-bottom: 10px;

      p {
        margin-bottom: 0;
      }

      @include media($large-screen) {
        padding-top: $base-spacing;
      }
    }

    &-title {
      text-transform: uppercase;

      @include media($large-screen) {
        font-size: 24px;
        line-height: 1;
        width: 140px;
      }
    }

    &-button {
      padding-left: 10px;
      @include media($large-screen) {
        padding-left: 15px;
        @include position(absolute, null null 15px null);
      }
    }

    &--featured {
      color: white;
      background-size: cover;
      background-position: center;

      &:before {
        content: '';
        @include position(absolute, 0 0 0 0);
        background: rgba(black, 0.4) url('../img/pattern-light-small.png');
      }

      .media-centre__card-header {
        background-color: transparent;
      }
    }

    @include media($large-screen) {
      @include span-columns(6);
      &--large,
      &--medium {
        width: 49.16%;
      }

      &.shuffle-item {
        margin-right: 0;
      }

      &--small {
        @include span-columns(3);
        .media-centre__card-image-holder {
          width: 100%;
          height: 145px;
        }
      }

      &--medium {
        height: 225px;
        .media-centre__card-header {
          width: 50%;
          height: 100%;
          float: left;
        }
        .media-centre__card-image-holder {
          width: 50%;
          height: 225px;
          float: right;
        }
      }

      &--large {
        height: 395px;
        .media-centre__card-title {
          font-weight: 300;
          font-size: 44px;
          width: 100%;
        }

        .media-centre__card-image-holder {
          height: 247px;
        }
      }
    }
  }
}
