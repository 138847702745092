/**
 * Industries (common)
 * miscellaneous elements
 */
.industries {
	.expand-link{
		padding-left: 10px;
		padding-right: 10px;
	}
	
	.expandable-content {
		padding-left: 10px;
		padding-right: 10px;
		
		.panel-list {
			border-top: 1px solid $light-gray;
		}
	}
	@include media($large-screen-up) {
		.expandable-content {
			padding-left: 30px;
		}
	}
	
	.expandable {
		position: relative;
		
		&.expanded {
			border-bottom: 1px solid $styrolution-blue;
			border-top: 2px solid $styrolution-blue;
			top: -1px;
			
			& + .expandable {
				border-top: 1px solid $styrolution-blue;
				top: -1px;
			}
			
			&:last-child {
				border-bottom-width: 2px;
			}
		}
	}
}



/**
 * Industries (common)
 * data tables
 */
.industries table {
	border-top: 1px solid $light-gray;
	
	thead {
		display: none;
		
		th {
			background: $lightest-gray;
			color: $base-font-color;
			font-size: 14px;
			font-weight: 400;
		}
	}
	
	tbody,
	tr,
	th,
	td {
		display: block;
		
		&.sec,
		&.secondary {
			display: none;
		}
	}
	
	tr {
		border-bottom: 1px solid $light-gray;
	}
	
	th,
	td {
		padding: 10px 0;
	}
	
	.icon:before,
	[class^="icon-"]:before {
		font-size: 1em;
	}
	
	h4 {
		font-size: 24px;
		font-weight: 700;
		margin: 0;
	}
	
	td.main {
		padding: 0;
		position: relative;
		
		a {
			display: block;
			padding: 10px 30px 10px 0;
		}
		
		p {
			color: $gray;
			margin: 0;
			opacity: .8;
		}
		
		.detail {
			> .icon-angle-right:before {
				font-size: 36px;
				position: absolute;
				height: 46px;
				line-height: 44px;
				margin: -23px 0 0;
				right: 0;
				text-align: right;
				top: 50%;
			}
		}
	}
	
	@include media($large-screen-up) {
		border: none;
		
		thead {
			display: table-header-group !important;
		}
		
		tbody {
			display: table-row-group !important;
		}
	
		tr {
			display: table-row !important;
		}
		
		td,
		th {
			display: table-cell !important;
		}
		
		tr {
			border: none;
		}
		
		thead td,
		thead th,
		td,
		th {
			border-bottom: 1px solid $light-gray;
			border-right: 1px solid $light-gray;
			padding: 10px;
			
			&:last-child {
				border-bottom-width: 1px;
				border-right-width: 0;
			}
		}
		
		td.main {
			padding: 0;

			a {
				padding: 10px 50px 10px 10px;
			}
			
			.detail {
				> .icon-angle-right:before {
					right: 20px;
				}
			}
		}
		
		th.icon,
		td.icon {
			text-align: center;
			
			[class^="icon-"] {
				color: $styrolution-blue;
				font-size: 1.6em;
			}
		}

		th.col-l,
		td.col-l {
			width: 55%;
		}
	}
}

html.no-touch .industries {
	table {
		a {
			@include transition (background-color 0.08s linear);
			
			p {
				opacity: .8;
			}
			
			&:hover {
				background: $white-hued;
				
				p {
					opacity: 1;
				}
			}
		}
	}
}




/**
 * Industries Overview
 * miscellaneous elements
 */
.industries-overview {
	h2,
	h3 {
		font-size: 20px;
		font-weight: 700;
		text-transform: uppercase;
	}
	
	.summary {
		background: $white-hued;
		color: $dark-gray;
		margin: -22px -20px 0;
		padding: 1.8em 20px;
		position: relative;
		z-index: 2;
		
		h2 {
			margin: 0 0 .6em;
		}
	}
	
	.panel-list {
		li {
			margin-left: -20px;
			margin-right: -20px;
			padding-left: 20px;
			padding-right: 50px;
		}
		
		li {
			.detail > .icon-angle-right:before {
				font-size: 3.2em;
				right: 20px;
			}
			
			h4 {
				font-weight: 700;
			}
		}
		
		h4 [class^="icon-"] {
			display: inline-block;
			font-size: 2.4em;
			height: 36px;
			line-height: 35px;
			margin-right: .4em;
			vertical-align: middle;
			width: 36px;
		}
	}
	
	.mosaic {
		margin-top: -1px;
		
		.tile {
			.text {
				border-top: 1px solid #d0d0d0;
			}
		}
		
		.stretcher,
		.text-only {
			display: none;
		}
		
		.text {
			@extend %clearfix;
			display: block;
			margin: 0 -20px;
			padding: 1em 20px;
			
			[class^="icon-"] {
				font-size: 3.2em;
				height: 36px;
				line-height: 35px;
				margin-right: .3em;
				width: 36px;
			}
			
			[class^="icon-"],
			div {
				display: inline-block;
				vertical-align: middle;
			}
			
			p {
				display: none;
			}
		}
		
		.tile > a,
		a.text {
			@extend %icon;
			@extend %icon-angle-right;
			
			&:before {
				float: right;
				font-size: 3.2em;
				height: 36px;
				line-height: 35px;
				margin-left: .5em;
				position: relative;
				top: 50%;
			}
		}
		
		.tile > a:before {
			top: 16px;
		}
	}
	
	@include media($large-screen-up) {
		.summary {
			display: none;
		}
		
		.mosaic {
			margin-top: 0;
			
			.col-lg-6 .col-lg-6 {
				width: 48.8%;
				margin-right: 2.2%;
				
				&:last-child {
					margin-right: 0;
				}
			}
			
			.tile {
				.text {
					border-top: none;
				}
			}

			.stretcher,
			.text-only {
				display: block;
			}
			
			.text {
				margin: 0;
				
				div {
					display: block;
					overflow: hidden;
					
					&:before {
						@include linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.4) 45%,rgba(0,0,0,0.4) 55%,rgba(0,0,0,0) 100%);
						background-color: transparent;
						content: '';
						height: 120%;
						left: 0;
						opacity: .3;
						position: absolute;
						top: -10%;
						width: 100%;
					}
					
					> * {
						position: relative;
						text-shadow: 0 0 4px rgba(0, 0, 0, .5);
						z-index: 1;
					}
				}
				
				[class^="icon-"] {
					float: left;
					font-size: 3.9em;
					height: 48px;
					line-height: 45px;
					margin-right: .2em;
					position: relative;
					width: 48px;
					
					&:before {
						color: $styrolution-green;
						position: relative;
						z-index: 2;
					}
					
					&:after {
						background: #fff;
						border-radius: 21px;
						content: '';
						height: 42px;
						left: 50%;
						margin: -21px 0 0 -21px;
						position: absolute;
						top: 50%;
						width: 42px;
						z-index: 1;
					}
				}
				
				h3 {
					margin: .5em 0 .2em;
				}
				
				p {
					display: block;
					line-height: 1.2;
					max-width: 20em;
				}
			}
			
			a {
				color: $inverted-font-color;
			}
			
			.tile > a:before,
			a.text:before {
				display: none;
			}
			
			.text-only {
				.text {
					background: $styrolution-green;
					color: $inverted-font-color;
					height: 100%;
				}
				
				h3 {
					margin: 0 0 .8em;
				}
			}
		}
	}
}



.industries-product {
	.article-heading {
		h2 {
			margin-bottom: 0;
		}
	}
}