.my-eshop, .checkout, .eshop {
	.button.button--shop.secondary {
		.iconprop-add:before {
			color: $styrolution-green;
		}
	}

	h3 {
		font-size: 24px;
		margin: 1em 0 1em;
		font-weight: 500;

		&.checkout__subhead {
			color: $styrolution-blue;
			text-transform: uppercase;
			position: relative;
			padding-left: 50px;

			.checkout__label {
				width: 40px;
				height: 40px;
				border-radius: 50%;
				background-color: $styrolution-blue;
				color: white;
				font-weight: bold;
				font-size: 20px;
				padding-top: 7px;
				text-align: center;
				display: inline-block;
				position: absolute;
				top: -5px;
				left: 0;
			}
		}
	}

	textarea {
		resize: none;
	}

	.calendar {
		display: inline-block;
		padding-left: 4%;
		position: relative;
		height: 20px;

		&:before {
			@include icon-styles;
			@include icon(to-lower-case("calendar"));

			position: absolute;
			margin-top: 0.44em;
		}

		&.button-inactive {
			color: $gray;
			cursor: default;
		}
	}

	.currency-selector {
		width: 50%;
	}

	.shopping-cart-list.qty-check-disabled {
		.order-quantity {
			.validation-message {
				color: $medium-gray;
			}
		}
	}

	.my-eshop__order-form {
		background-color: $styrolution-blue-lightest;
		padding: 1.5em;

		h3 {
			margin-top: 0;
		}

		p {
			font-size: $form-font-size;
			font-weight: bold;
			margin-bottom: 0;
		}

		.autocomplete-suggestions {
			position: absolute;
			z-index: 2;
			width: 95%;
		}

		table {
			table-layout: auto;
			width: 100%;
			margin: 0;

			tr {
				background-color: transparent;
			}

			td {
				border: none;
				display: block;
				padding: 0 10px 15px;

				&.my-eshop__radio-wrapper {
					padding-top: 0.2em;
				}

				@include media($medium-screen-up) {
					display: table-cell;
				}

				input[type=text] {
					padding: 0.8em 0.5em 0.75em;
				}
			}
			td:first-child {
				padding-left: 0;
			}
			td:last-child {
				padding-right: 0;
			}
		}

		label {
			font-weight: 500;
		}
	}
}

.my-eshop {
	h2 {
		margin-bottom: 35px;
	}

	input[type=text] {
		height: 45px;
	}

	&__order-quick-add {
		text-align: right;

		label {
			display: inline-block;
			color: white;
			position: relative;
			top: 2px;
		}

		.button {
			display: inline-block;
		}
	}

	&__quick-add-input-wrapper {
		position: relative;
		display: inline-block;
		vertical-align: top;

		input[type="text"] {
			width: 320px;
			height: 40px;
			margin: 0 20px;
		}

		.autocomplete-suggestions {
			text-align: left;
			position: absolute;
			z-index: 99;
			width: 95%;
		}
	}

	&__open-orders {
		h3 {
			margin-top: 2em;
		}
		table {
			margin: 2em 0;

			thead {
				border-bottom: 1px solid $lightest-gray;

				th {
					background-color: transparent;
					color: $medium-gray;
				}

				.my-eshop__th-customer {
					width: 14%;
				}
				.my-eshop__th-ordernum {
					width: 18%;
				}
				.my-eshop__th-material {

				}
				.my-eshop__th-date {
					width: 18%;
				}
				.my-eshop__th-status {
					width: 14%;
				}
			}

			tr {
				background-color: transparent;
			}
		}
	}

	&__radio-label {
		display: inline;
	}
	&__text-wrapper {
		position: relative;
	}

	&__search-results {
		display: none;
	}

	&__result-list {
		label {
			display: inline;
		}

		ul {
			margin-bottom: 1em;
		}

		.legend {
			font-weight: 500;
		}
	}

	@include media($medium-screen-up) {
		&__radio-wrapper {
			width: 1%;
			white-space: nowrap;
		}
		&__button-wrapper {
			width: 1%;
			white-space: nowrap;

			.button {
				width: 100%;
				text-align: center;
			}
		}
	}
}

.checkout, .eshop {
	.legend {
		font-size: 0.8em;
		font-weight: normal;
	}

	.separator {
		border-top: 1px solid #CCCCCC;
		padding: 30px 0;
	}

	fieldset:not(.my-eshop__order-form) {
		border: none;
		background: none;
		padding: 0;
	}
}

.checkout {
	.notice {
		margin: 2em 0 2em;
		padding: 0.5em 20px 0.5em;

		p {
			margin: 0;
		}
	}

	#ship-to {
		option {
			color: $gray;
		}
		&.selected {
			option {
				color: $light-gray;
			}
		}
	}
}

.checkout__freeze-pane {
	background-color: transparent;
	margin: 10px 0 0;
	border-bottom: 1px solid #cccccc;
	padding-bottom: 10px;

	h2 {
		float: left;
	}

	&.checkout__freeze-pane--sticky {
		position: fixed;
		top: 100px;
		right: 0;
		left: 0;
		z-index: 99;
		background-color: white;
		margin-top: -4px;

		.wrapper {
			margin-top: 10px;
		}
	}
}

.checkout__progress-bar {
	margin-bottom: 30px;

	.z1 {
		z-index: 1;
	}
	.z2 {
		z-index: 2;
	}
	.z3 {
		z-index: 3;
	}

	a {
		position: relative;
		background-color: $styrolution-green-half;
		font-weight: 700;
		color: white;
		padding: 14px 20px 0 40px;
		display: inline-block;
		width: 33.33333%;
		height: 60px;
		cursor: default;
		text-align: center;
		font-size: 20px;

		.checkout__progress-number {
			position: absolute;
			left: 40px;
		}

		&.is-active, &.is-complete {
			.checkout__progress-label:after {
				@include icon-styles;

				position: absolute;
				right: 10px;
				margin-top: 3px;
				font-size: 130%;
			}
		}

		&.is-active {
			background-color: $styrolution-blue;

			.checkout__progress-label:after {
				@include icon(to-lower-case("order"));
			}
		}

		&.is-complete {
			cursor: pointer;
			background-color: $styrolution-green;

			.checkout__progress-label:after {
				@include icon(to-lower-case("check"));
			}
		}

		&:first-child {
			.checkout__progress-number {
				left: 30px;
			}
		}
	}
	a:after, a:before {
		left: 100%;
		top: 50%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
	}

	a:after {
		border-color: rgba(0, 24, 66, 0);
		border-left-color: $styrolution-green-half;
		border-width: 30px 0 30px 20px;
		margin-top: -30px;


	}
	a.is-active:after {
		border-left-color: $styrolution-blue;
	}
	a.is-complete:after {
		border-left-color: $styrolution-green;
	}
	a:before {
		border-color: rgba(255, 255, 255, 0);
		border-left-color: #ffffff;
		border-width: 33px 0 33px 23px;
		margin-top: -33px;
	}

	a:first-child {
		padding-left: 25px;
	}
}

.checkout__continue-buttons {
	float: right;
	a.button {
		&.left-arrow {
			color: $gray;
			font-weight: bold;
		}
		&.button--shop {
			padding-top: 1em;
			.label {
				vertical-align: top;
			}
			.spaced-icon {
				top: 0;
			}
		}
	}
	a {
		margin-left: 2px;
		display: inline-block;
		height: 44px;
		position: relative;

		&.left-arrow {
			@extend .secondary;
		}
		&.right-arrow {
			@extend .primary2;
		}

		&.left-arrow:after {
			right: 100%;
			top: 50%;
			border: solid transparent;
			content: " ";
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
			border-color: rgba(227, 227, 227, 0);
			border-right-color: $lightest-gray;
			border-width: 22px 12px 22px 0;
			margin-top: -22px;
		}
		&.left-arrow:hover:after {
			border-color: rgba(185, 185, 185, 0);
			border-right-color: $light-gray;
		}

		&.right-arrow:after {
			left: 100%;
			top: 50%;
			border: solid transparent;
			content: " ";
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
			border-color: rgba(247, 168, 35, 0);
			border-left-color: $styrolution-orange;
			border-width: 22px 0 22px 12px;
			margin-top: -22px;
		}
		&.right-arrow:hover:after {
			border-color: rgba(184, 125, 26, 0);
			border-left-color: $styrolution-orange-dark;
		}

		&.disabled, &.button-inactive {
			opacity: 0.5;
			cursor: default;
			color: $medium-gray;

			&.left-arrow, &.right-arrow {
				color: $medium-gray;
			}

			&.left-arrow:hover {
				background: $lightest-gray !important;
				border-color: $lightest-gray !important;
			}

			&.left-arrow:hover:after {
				border-color: rgba(227, 227, 227, 0);
				border-right-color: $lightest-gray;
			}

			&.right-arrow {
				color: white;
			}

			&.right-arrow:hover {
				background: $styrolution-orange !important;
				border-color: $styrolution-orange !important;
			}

			&.right-arrow:hover:after {
				border-color: rgba(247, 168, 35, 0);
				border-left-color: $styrolution-orange;
			}
		}
	}
}

.checkout__quick-links {
	border-bottom: 1px solid #cccccc;

	li {
		width: 23%;
		display: inline-block;
		padding: 18px 0;
	}

	a {
		.spaced-icon {
			margin-left: 6px;
		}
	}
}

.checkout__delivery-data {
	margin-top: 30px;

	h3 {
		margin-bottom: 9px;
	}

	.legend {
		margin: 30px 0 0;
	}

	label {
		margin-top: 15px;
	}
}

.checkout__cart-container, .eshop__template-detail-toolbar, .eshop__template-detail-tbody {
	input[type="checkbox"] {
		margin-right: 0;
	}
	.material-item {
		margin-bottom: 3em;
	}
}

.checkout__cart-toolbar, .eshop__template-detail-toolbar {
	@include clearfix;

	height: 70px;
	margin-top: 30px;
	position: relative;

	.check-container {
		position: relative;

	}

	.check {
		float: left;
		width: 3.1%;
		height: 100%;
		text-align: center;
		padding: 0;
		margin-top: 8px;
		position: relative;
		z-index: 2;

		@include media($large-screen-up) {
			background: url("../img/bg-line-left.gif") -100% 0 no-repeat;
		}
	}

	.button {
		float: left;

		&.button-update {
			float: right;
		}
	}

	&:last-child {
		@include media($large-screen-up) {
			overflow: hidden;
			height: 70px;
			margin: 0;

			.check {
				margin-top: 35px;
			}
			.buttons {
				margin-top: 24px;
			}

			&:before {
				content: " ";
				position: absolute;
				width: 1px;
				height: 100px;
				bottom: 30%;
				left: 1.5%;
				background: url("../img/bg-line-vert.gif") 0 -50% repeat-y;
			}
		}
	}
}

.checkout__cart-thead, .eshop__template-detail-thead {
	@include clearfix;

	display: table;
	width: 100%;

	div {
		margin-top: 10px;
		color: #999;
		display: table-cell;
		font-size: 1em;
		font-weight: 700;
		height: 21px;
		padding: 0 0 8px 0;
	}

	.check {
		width: 3.1%;
		position: relative;

		@include media($large-screen-up) {
			&:after {
				content: " ";
				position: absolute;
				width: 1px;
				height: 96px;
				bottom: 0;
				left: 50%;
				background: url("../img/bg-line-vert.gif") 0 -50% repeat-y;
			}
		}
	}
	.article-number {
		width: 11.3%;
		padding-left: 6px;

	}
	.article-name {
		width: 39.5%;
	}
	.order-quantity {
		width: 17.8%;
	}
	.uom {
		width: 8.5%;
	}
	.requested-delivery-date {
		width: 19.8%;
	}

	&.checkout__summary-thead {
		.article-number {
			width: 11.7%;
			padding-left: 8px;

		}
		.article-name {
			width: 27.9%;
		}
		.order-quantity, .requested-delivery-date, .price, .price-per-unit {
			text-align: right;
		}
		.order-quantity {
			width: 12.3%;

		}
		.requested-delivery-date {
			width: 18.9%;
		}
		.price {
			width: 13.6%;
		}
		.price-per-unit {
			width: 15.6%;
		}
	}
}

.bookmarks {
	.eshop__template-detail-toolbar {
		&:first-child {
			height: 40px;
		}
	}
	.checkout__cart-thead, .eshop__template-detail-thead {
		.check {
			@include media($large-screen-up) {
				&:after {
					height: 50px;
				}
			}
		}
	}
}

.checkout__success-message {
	@include clearfix;
	border-top: 1px solid $styrolution-green;
	border-bottom: 1px solid $styrolution-green;
	text-align: center;
	color: $styrolution-green;
	width: 100%;
	display: block;
	padding: 15px 0;
}

.checkout__cart-tbody {
	@include clearfix;

	.rt1 {
		display: table;
		width: 100%;
		color: $gray;

		a {
			color: $gray;
		}

		div {
			display: table-cell;
			padding: 11px 0 8px 0;
			border-bottom: 1px solid #ccc;
		}

		.check {
			width: 3.1%;
			text-align: center;
			border-right: 1px solid white;
		}
		.article-number {
			width: 11.3%;
			padding-left: 14px;

		}
		.article-name {
			width: 85.6%;
		}

		&.selected {
			background-color: $form-color-orange;
		}
	}

	.rt1 {
		background-color: $styrolution-blue-lightest;
	}

	.replicant-items {
		display: block;
		width: 100%;
	}

	.replicant-item {
		width: 100%;
		display: table;

		.row {
			display: table-row;
			width: 100%;

			div {
				display: table-cell;
				padding: 0;
				margin: 0;
				border-bottom: 1px solid #ccc;

				.customer-request {
					font-size: 0.8em;
					display: block;
					border: none;
					margin: 0.5em 0 0.3em 0;
				}
			}

			.validation-message {
				display: block;
				border: none;
				padding: 6px 0;
				width: 78%;
			}

			.check {
				width: 3.1%;
				background-color: $white-hued;
				text-align: center;
				border-right: 1px solid white;
			}

			.article-number {
				width: 11.3%;

			}
			.article-name {
				width: 39.5%;
				padding: 15px 0 15px 0;

				select {
					margin-bottom: 15px;
					width: 217px;
				}
			}

			.order-quantity {
				width: 26.8%;

				input {
					padding: 10px;
					text-align: right;
					margin: 5px 0 0 0;
					width: 48.8%;
					display: inline-block;
				}

				a {
					height: 20px;
					display: inline-block;
					padding-left: 3%;
					position: relative;
				}

				.unit {
					display: inline-block;
					padding-left: 14%;
				}
			}
			.requested-delivery-date, .bid-amount {
				width: 19.8%;

				input {
					padding: 10px;
					margin: 0;
					width: 63%;
					display: inline-block;
				}
			}

			.bid-amount {
				input {
					margin-right: 10px;
				}
			}

			&.selected {
				background-color: $form-color-orange-light;

				.check {
					background-color: $form-color-orange-light;
				}
			}

		}

	}

	.replicant-repeat {
		display: none;
	}

	.add-line {
		background-color: $white-hued;
		text-align: center;
		border-bottom: 1px solid #ccc;

		.button {
			border: none;
			color: $styrolution-green;
			font-weight: normal;

		}
	}

	&.checkout__summary-tbody {
		.rt1 {
			.article-number {
				width: 11.7%;

			}
			.article-name {
				width: 88.3%;
			}
		}

		.rt2 {
			display: table;
			width: 100%;

			.row {
				display: table-row;
				width: 100%;

				div {
					display: table-cell;
					padding: 15px 0;
					margin: 0;
					border-bottom: 1px solid #ccc;
					text-align: right;
				}

				&:last-child {
					div {
						border-bottom: none;
					}
				}

				.order-item-label {
					width: 39.6%;
					border-bottom: none;
					text-align: right;
					font-weight: bold;
					color: $medium-gray;
					padding-right: 15px;
				}
				.order-quantity {
					width: 12.3%;
				}
				.requested-delivery-date {
					width: 18.9%;
				}
				.price {
					width: 13.6%;
					position: relative;

					.notice {
						position: absolute;
						top: 7px;
						left: 15px;
						width: 200%;
						border-bottom: none;
						font-size: 0.8em;
						padding: 0 15px 0 15px;
						margin-left: 15%;

						text-align: left;
					}
				}
				.price-per-unit {
					width: 15.6%;
				}
			}
		}

		.rt3 {
			display: table;
			width: 100%;

			.row {
				display: table-row;
				width: 100%;
				div {
					display: table-cell;
					padding: 8px 0 0 0;
					margin: 0;
					background-color: $white-hued;
					text-align: left;
					//border-bottom: 1px solid #ccc;
					font-size: 0.8em;
					font-weight: normal;
				}

				&:first-child {
					div {
						border-top: 1px solid #ccc;
					}
				}
				&:last-child {
					div {
						border-bottom: 1px solid #ccc;
						padding-bottom: 8px;
					}
				}

				.footer-1 {
					width: 11.7%;
					padding-left: 14px;
				}
				.footer-2 {
					width: 28.9%;
				}
				.footer-3 {
					width: 59.4%;
					text-align: right;
					padding-right: 15px;
				}
			}
		}


	}
}

.checkout__csv-form {
	&>p {
		margin-bottom: 0;
	}
	.button-wrapper {
		border: none;
		padding-left: 0;
	}

	li {
		position: relative;
	}

	li a {
		@extend %icon;
		@extend %icon-file-empty;

		padding: 0.8em 0 0.8em 2.2em;
		display: inline-block;
		position: relative;
	}

	ul li a:before {
		font-size: 1.5em;
		height: 2em;
		left: 0;
		line-height: 2em;
		margin-left: 0;
		margin-top: -1em;
		position: absolute;
		top: 50%;
		width: 1em;
	}

	.meta {
		color: #b9b9b9;
		display: block;
		font-size: 13px;
	}
}

.checkout__comment-box {

}

.checkout__comment-block {
	@include clearfix;

	.separator {
		padding: 0;
	}

	textarea {
		height: 160px;
	}
}

.checkout__comment-block-main {
	padding: 30px 0;
}

.checkout__footer {
	padding-top: 10px;

	.checkout__continue-buttons {
		float: none;
		text-align: right;
	}

	.checkout__progress-bar {
		margin-top: 30px;
	}
}

.order-acknowledgment {
	h2 {
		float: none;
		display: block;
		padding-bottom: 1em;
	}

	.checkout__freeze-pane {
		border-bottom: none;
	}
}

.eshop__template-detail-buttonbar {
	margin-bottom: 1em;

	a {
		margin-right: 1.5em;
	}
}

.eshop__template-detail-toolbar {
	.button {
		margin-right: 15px;
	}
	//&:last-child {
	//	overflow: hidden;
	//	height: 70px;
	//	margin: 0;
	//}
}

.eshop__template-detail-thead {
	div {
		//background-color: lightgrey;
		//border-right: 1px solid white;
		vertical-align: bottom;
	}
}
.eshop__template-detail-thead, .eshop__template-detail-tbody > .row {
	.full-width {
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.check {
		width: 3%;
		text-align: center;
		padding: 0;
		position: relative;
	}
	.article-number {
		width: 9%;
	}
	.article-name {
		width: 51%;
		padding-right: 15px;
	}

	.min-order-quantity {
		width: 7%;
	}

	.download {
		text-align: center;
		width: 5.5%;

		a>span {
			font-size: 1.5em;
			position: relative;
			top: 3px;
		}
	}

	.add-to-cart {
		width: 19%;
		text-align: right;

		.button {
			margin-right: 10px;
			position: relative;
			top: 10px;
			white-space: nowrap;
		}
	}
}

.eshop__template-detail-tbody {
	display: table;
	table-layout: fixed;
	width: 100%;

	.row {
		display: table-row;

		.article-name, .article-number {
			a {
				color: $gray;
			}
		}

		&:first-child {
			div {
				border-top: 1px solid #ccc;
			}
		}

		div {
			display: table-cell;
			padding: 15px 0;
			border-bottom: 1px solid #ccc;
		}

		&.selected {
			background-color: $form-color-orange-light;

			.check {
				background-color: $form-color-orange-light;
			}
		}
	}
}

.eshop {
	h2 {
		margin-bottom: 1em;
	}
	.sty-singleline {
		display: block;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	#template-article-list {
		margin-bottom: 42px;
	}
}

.order-approval {
	.order-approval__button-bar {
		a {
			margin-right: 15px;
			height: 40px;
		}
	}
	.radio-box {
		display: block;
		margin-left: 15px;

		&:last-child {
			margin-bottom: 22px;
		}
	}
}

.auction {
	.checkout__cart-thead {
		.check {
			&:after {
				background: none;
			}
		}
	}
	.checkout__cart-toolbar {
		float: right;

		.button-buy {
			margin-right: 20px;
		}
		.button-bid {

		}
	}
	.notice.no-price {
		display: none;
	}
}