.mobile-hidden {
	@include media($medium-screen-less) {
		display: none !important;
	}
}

.tablet-hidden {
	@include media($large-screen-less) {
		display: none !important;
	}
}

.desktop-hidden {
	@include media($large-screen-up) {
		display: none !important;
	}
}

.sprite {
	background-image: url('../images/sprite.png');
	background-repeat: no-repeat;
	overflow: hidden;
}

.center {
	text-align: center;
}

.left {
	float:left;
}

.right {
	float:right;
}

.is-hidden {
  display: none;
}

.is-invisible {
  visibility: hidden;
}

.clipped {
	display: inline-block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.visuallyhidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}