// HeadTitle
.headtitle {
	padding: 10px 0;
	background-image: none;
	background-color: $styrolution-blue;
	background-size: cover;
	@include clearfix;
	
	[class^="icon-"] {
		display: none;
		font-size: 2.8em;
		height: 36px;
		line-height: 35px;
		margin-right: .3em;
		position: relative;
		vertical-align: middle;
		width: 36px;
		
		&:before {
			color: $styrolution-green;
			position: relative;
			z-index: 2;
		}
		
		&:after {
			background: #fff;
			border-radius: 15px;
			content: '';
			height: 30px;
			left: 50%;
			margin: -15px 0 0 -15px;
			position: absolute;
			top: 50%;
			width: 30px;
			z-index: 1;
		}
	}

	@include media($large-screen-up) {
		padding: 16px 0;
		
		[class^="icon-"] {
			display: inline-block;
			font-size: 2.0em;
			height: 48px;
			line-height: 45px;
			margin: -14px .4em -10px 0;
			width: 48px;
			
			&:after {
				border-radius: 21px;
				height: 42px;
				margin: -21px 0 0 -21px;
				width: 42px;
			}
		}

		&.sticky {
			@include position(fixed, 0 0 null 0);
			z-index: 99;
		}
	}
}

.headtitle-replace {
	// height set through javascript
}

.page-title {
	color: white;
	text-transform: uppercase;
	display: inline-block;
	float: left;

	@include media($large-screen-up) {
		font-size: 32px;
		line-height: 1.2;
	}
}

.news-view-controls {
	float: right;
	position: relative;
	top: 2px;
	padding: 3px;
	background-color: white;
	border-radius: 25px;
	margin-right: 20px;

	a {
		text-transform: uppercase;
		color: $accented-font-color;
		display: inline-block;
		padding: 3px 15px;

		&.active {
			color: white;
			background-color: $accented-background-color;
			border-radius: 25px;
		}
	}
}

.page-actions {
	white-space: nowrap;
	
	.dropdown {
		text-align: left;
		
		.label {
			display: none;
		}
	}
	
	.dropdown__button {
		height: 29px;
		line-height: 28px;
		overflow: hidden;
		padding: 0 22px 0 10px;
		white-space: normal;
		
		&:after {
			position: absolute;
			right: 5px;
			top: -2px;
		}
	}
	
	.dropdown__menu {
		box-shadow: 0 4px 3px 0 rgba(0, 0, 0, .5);
		margin-top: 29px;
		
		li a {
			padding-right: 10px;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
	
	.dropdown__button--filter__title {
		max-width: 100%;
	}
	
	@include media($medium-screen-up) {
		float: right;
		height: 29px;
		
		.dropdown__button {
			display: inline-block;
			float: none;
			width: auto;
		}
		
		.dropdown {
			display: inline-block;
			
			p {
				margin: 0;
				vertical-align: middle;
			}
			
			.label {
				color: $inverted-font-color;
				display: inline-block;
				margin-right: .5em;
			}
		}
		
		.dropdown__button--filter__title {
			display: inline-block;
		}
		
		.dropdown__container {
			width: auto;
		}
		
		.dropdown__menu {
			left: auto;
			margin-top: 0;
			max-width: 20em;
			min-width: 100%;
			right: 0;
			width: auto;
		}
	}
	
	@include media($large-screen-up) {
		display: inline-block;
		float: right;
		min-width: 180px;
		height: 35px;
		margin-top: 2px;
		margin-bottom: 0;
		position: static;
		
		.dropdown__button {
			height: auto;
			line-height: 35px;
			width: auto;
			
			&:after {
				position: static;
			}
		}

		.search {
			width: 340px;
			@include transition (width 0.3s);

			input[type="search"] {
				height: 35px;
				border: none;
			}

			&:before {
				top: 2px;
			}

			&.is-expanded {
				width: 380px;
			}
		}

		.dropdown {
			display: inline-block;

			.dropdown__button {
				padding-right: 40px;
				white-space: nowrap;
				text-transform: uppercase;

				a {
					text-transform: uppercase;
				}
			}

			.dropdown__button--filter {
				padding: 0 30px 0 10px;

				+ .dropdown__menu.show-menu {
					z-index: 3;
				}
			}
		}
	}
}

.headtitle__filter {
	position: relative;
	display: inline-block;
	float: right;

	@include media($large-screen-up) {
		margin-left: 20px;
		line-height: 38px;
	}
}

.headtitle__filter__trigger {
	font-weight: 500;
	font-size: 14px;
	color: white;
	@include position(relative, 1px null null null);

	&:hover {
		color: $gray;
	}

	&:focus {
		color: white;
	}
}

.switch-view {
	background-color: white;
	border-radius: 25px;
	padding: 3px;

	li {
		display: inline-block;

		a {
			display: block;
			font-weight: 500;
			font-size: 16px;
			padding: 5px 15px;
			color: $gray;
			border-radius: 25px;

			&.active {
				color: white;
				background-color: $gray;
			}

			&:not(.active):hover {
				color: $accented-font-color;
			}
		}
	}

	&.table-switch-view {
		background-color: $gray;

		label {
			position: relative;
			padding: 0;
			display: inline-block;
			border-radius: 25px;
			font-size: 15px;
			color: white;
			padding: 2px 15px;
			margin-bottom: 0;

			&.active {
				background-color: $styrolution-green;
			}

			&:not(.active):hover {
				color: $styrolution-green;
				cursor: pointer;
				cursor: hand;
			}

			input {
				position: absolute;
			  clip: rect(0, 0, 0, 0);
			  pointer-events: none;
			  display: none;
			}

		}
	}
}
