$height-social-links: 32px;
$width-social-links: 180px;



.main-footer {
	a {
		color: white;

		&:hover {
			color: $accented-font-color;
		}
	}
}

.footer__upper {
	padding: 26px 0;
}

.footer__upper {
	background-color: $styrolution-blue-medium;

	ul li {
		border-bottom: 1px solid $styrolution-blue-light;

		&:last-child {
			border: none;
		}

		a {
			display: block;
			padding: 10px 0;
		}
	}

	@include media($large-screen-up) {
		padding: 16px 0;
	}
}

.footer__lower {
	background: $styrolution-blue;
	color: $inverted-font-color;
	padding: 26px 0;

	.wrapper {
		position: relative;
	}

	li {
		border-bottom: 1px solid $styrolution-blue-medium;
		
		a {
			display: block;
			margin: 0 5em 0 0;
			padding: 10px 0;
		}
	}

	@include media($medium-screen-up) {
		.wrapper {
			padding-right: $width-social-links;
		}
		
		li {
			border: none;
			display: inline-block;
			margin: 0 1.6em .5em 0;
			vertical-align: top;
			
			a {
				display: inline;
				margin: 0;
			}
		}
	}

	@include media($large-screen-up) {
		li {
			font-size: em($basefont);
		}
	}
}

$social-colors: (linkedin #007bb6) (facebook #3b5998) (twitter #00aced) (youtube #bb0000) (googleplus #dc4e41);

.footer__lower__social {
	font-size: em(43);
	line-height: 0;
	margin-bottom: .5em;

	a {
		display: inline-block;
		@include size($height-social-links);
		background-color: $styrolution-blue;
		color: $inverted-font-color;
		font-size: $height-social-links;
		line-height: 30px;
		overflow: hidden;
		text-align: center;
		@include transition (background-color 0.2s ease-in-out);

		@each $color in $social-colors {
			&.#{nth($color,1)} {
				&:hover {
					color: white;
					background-color: nth($color,2);

					i:before {
						@include animation(toBottomFromTop 0.3s forwards);
					}
				}
			}
		}

		&.youtube {
			i {
				position: relative;
				left: 2px;
			}
		}

		&:not(:last-child) {
			margin-right: .5em;
		}
	}

	@include media($medium-screen-up) {
		height: $height-social-links;
		line-height: $height-social-links;
		margin: -15px 0 0;
		position: absolute;
		right: 20px;
		text-align: right;
		top: 50%;
		width: $width-social-links;
	}

	@include media($large-screen-up) {
		right: 0;
	}
}

.footer__upper {
	.wrapper {
		position: relative;
	}

	ul {
		@include media($large-screen-up) {
			font-size: em($basefont);

			> li {
				border: none;
				float: left;

				& + li {
					margin-left: 90px;
				}
			}
		}
	}

	.footer__upper__quick-links {
		margin-bottom: .5em;
		padding-bottom: .5em;

		@include media($large-screen-up) {
			border: none;
			float: left;
			margin-bottom: .5em;
			margin-right: 8%;
			padding-bottom: .5em;

		}
	}
}

.copy {
	margin: 1em 0 0;
	
	a,
	span {
		display: block;
	}

	a {
		text-decoration: underline;
	}
	
	@include media($medium-screen-up) {
		margin: 0;
		
		a,
		span {
			display: inline;
		}
	}

	@include media($large-screen-up) {
		font-size: em($basefont);
	}
}

.developer {
	@include media($large-screen-up) {
		float: right;
		font-size: 12px;
	}
}
