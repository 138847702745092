.cookie-banner {
	display: none;
	position: fixed;
	bottom: 0;
	z-index: 999;
	color: #f7a823;
	background: #fdeed3;
	width: 100%;

	.wrapper {
		padding: 10px 10px;
	}

	p {
		margin: 0;
		display: inline-block;

		@include media($large-screen-up) {

			width: 75%;
			float: left;

		}
	}

	.button {
		margin-top: 15px;
		background: $styrolution-green;
		border-color: $styrolution-green;
		color: $inverted-font-color;

		&:hover {
			background: $styrolution-green-dark;
			border-color: $styrolution-green-dark;
		}

		@include media($large-screen-up) {

			float: right;
			margin-top: 0;

		}
	}
}