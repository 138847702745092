.no-border {
	border: none !important;
}

.order-management, #cancel-order-item-popup {
	.sty-key-values {
		display: table;
		width: 100%;

		.key-value-line {
			display: table-row;
		}

		.label, .value {
			display: table-cell;
			padding-right: 40px;
		}

		.label {
			color: $dark-gray;
			white-space: nowrap;
		}

		.value {
			width: 100%;
		}
	}
}

.order-management {
	.eshop__template-detail-buttonbar {
		.iconprop-add {
			color: $styrolution-green;
		}
	}
	.calendar {
		padding-left: 4px;
	}

	.sty-results table, .order-detail__item-list, .sty-open-orders-table {
		th, td {
			vertical-align: top;
		}

		th {
			background-color: $styrolution-green;
			border-right: 1px solid $light-gray;
			border-bottom: 1px solid $light-gray;
			font-size: 14px;
			position: relative;

			&:last-child {
				border-right: none;
			}

			a {
				color: white;
			}

			.sort {
				&.desc {
					.arrow {
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 7px 5.5px 0 5.5px;
						border-color: #ffffff transparent transparent transparent;
					}
				}
				.arrow {
					display: inline-block;
					margin-left: 10px;

					width: 0;
					height: 0;
					border-style: solid;
					border-width: 0 5.5px 7px 5.5px;
					border-color: transparent transparent #ffffff transparent;
				}
			}

			.iconprop-info {
				position: absolute;
				right: 11px;
				top: 14px;
			}

		}
	}

	.sty-results, .customer-po__sales-item {
		table {
			margin-bottom: 1em;

			.sty-tbl-c1 {
				width: 15%;
			}

			.sty-tbl-c2 {
				width: 17%;
			}

			.sty-tbl-c3 {
				width: 30%;
			}

			.sty-tbl-c4 {
				width: 14%;
			}

			.sty-tbl-c5 {
				width: 13%;
			}

			.sty-tbl-c6 {
				width: 11%;
			}

			tbody {
				tr:last-child {
					td {
						border-bottom: 1px solid $light-gray;
					}
				}

				td {
					border: none;
					border-top: 1px solid $light-gray;

					&.empty {
						border: none;
					}

					p {
						margin-bottom: 0;

						&.article-material {
							color: $medium-gray;
						}
					}
				}
			}
		}
	}

	.sty-results {
		table {
			tbody {
				.sty-main-row, .sty-secondary-row {
					background-color: white;
				}

				tr:nth-child(4n+0) {
					background-color: $white-hued;
				}
				tr:nth-child(4n-1) {
					background-color: $white-hued;
				}
			}
		}
	}

	.customer-po__sales-item {
		margin-bottom: 4em;

		table {
			tbody {
				tr {
					background-color: transparent;
				}
				tr:nth-child(odd) {
					background-color: $white-hued;
				}
			}
		}
	}

	&__header {
		@include clearfix;

		.sty-syndication {
			float: left;
		}
		.paging-navigation {
			float: right;
		}
	}

	&__footer {
		@include clearfix;

		.buttons {
			float: left;
		}
		.paging-navigation {
			float: right;
		}
	}

	.sty-order-items-filter {
		background-color: $styrolution-blue-lightest;
		padding: 1.5em;

		label {
			font-weight: normal;
		}


		#keywords {
			padding: 20px 10px 20px 60px;
			display: inline-block;
			border-radius: 1.5em;


		}

		.sty-search-fieldset {
			position: relative;

			&:after {
				@include icon-styles;
				@include icon(to-lower-case("search"));

				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 20px;
				font-size: 2em;
				color: $styrolution-green;

			}

			display: table;
			width: 100%;
			vertical-align: middle;

			#keywords {
				display: table-cell;
				width: 100%;
			}
			a.iconprop-info {
				cursor: default;
				width: 1%;
				display: table-cell;
				font-size: 2em;
				padding-left: 10px;
				position: relative;
				top: 10px;
				color: $gray;

			}
		}

		.order-management__dropdowns {
			display: table;
			.row {
				display: table-row;

			}
			label {
				display: table-cell;
				font-weight: normal;
				padding: 0;
			}
			select {
				margin: 30px 0 0 0;
			}
			label {
				white-space: nowrap;
				padding: 30px 20px 0 0;
			}
			.hr {
				padding-top: 20px;
				display: table-cell;
				border-bottom: 1px solid $light-gray;
			}
		}

		.accordion-tabs-minimal {
			border-top: 1px solid $light-gray;
			border-bottom: 1px solid $light-gray;
			padding-top: 30px;
			margin-top: 20px;

			a.tab-link {
				padding-right: 1em;
			}
		}

		.date-filter-dialog {
			.radio, input, label, select, .fieldcol, .date {
				display: inline;
				font-weight: normal;
			}
			select {
				width: 10%;
			}
			input[type="text"] {
				width: 15%;
			}
			.fieldcol {
				margin-right: 20px;
			}
			.date-label {
				margin-left: 25px;
			}
		}

		.sty-submit-options {
			@include clearfix;

			margin-top: 45px;
			border-top: 1px solid $light-gray;
			padding-top: 20px;
			position: relative;

			.sty-submit-item {
				display: inline-block;
				margin-right: 20px;

				&.checkbox {
					display: block;
					position: absolute;
					top: 50%;
					right: 0;
					margin-right: 0;

					label {
						display: inline;
					}
				}
			}
		}
	}

	.expand-container {

		text-align: center;

		a {
			background-color: $styrolution-blue-lightest;
			padding: 10px 28px 6px 49px;
			display: inline-block;
			margin-top: 1px;
			position: relative;

			.spaced-icon {
				font-size: 6px;
				position: absolute;
				top: 50%;
				left: 31px;
				transform: translateY(-50%);
			}
		}
	}

	.sty-key-values {
		border-top: 1px solid $light-gray;
		border-bottom: 1px solid $light-gray;
		padding: 20px 0;

		&.order-detail__invoice-info {
			border-bottom: none;
			margin-bottom: 2em;
		}
	}

	#sty-screen-filter-view p.no-options {
		margin-top: 20px;
	}

	.expandable {
		.expandable-content>:first-child {
			margin: 0;
		}

		table {
			margin: 0;
		}
		table.order-detail__batch-table {
			margin: 4em 0 4em 0;
		}

		td {
			vertical-align: top;
		}

		.order-detail__hide-details-button {
			display: none;
			margin: 0.5em 0 3em 0;
		}

		&.expanded {
			.order-detail__hide-details-button {
				display: inline-block;
			}
		}
	}
}

.order-detail {
	.msg-submission-error {
		color: #cc3300;
		display: none;
	}
	.expandable .expand-link:before {
		float: none;
	}
	h2 {
		margin-bottom: 1em;
	}
	h3 {
		text-transform: uppercase;
		font-weight: bold;
	}
	h4 {
		text-transform: uppercase;
		font-size: 24px;
	}
	h5 {
		font-size: 24px;
		margin: 1em 0 0.5em 0;
	}
	h6 {
		font-size: 18px;
		font-weight: bold;
		color: $medium-gray;
		margin: 1em 0 0.5em 0 !important;
	}
	.contact p {
		margin: 0;
	}
	.separator {
		margin: 2em 0;
		padding: 0;
	}
	.sty-key-values .label {
		color: $light-gray;
	}
	.order-detail__meta {
		display: table;
		width: 100%;


		&>div {
			display: table-row;

			.label, .value {
				display: table-cell;
			}
			.label {
				color: $light-gray;
			}
		}
	}
	.order-detail__contact {
		.vcard p {
			margin: 0;
		}
	}

	.order-detail__item-list {
		margin: 0 !important;
		padding: 0;

		.expandable-content > table > tbody > tr > td {
			border-bottom: none;
		}

		tr {
			background-color: transparent;
		}

		td {
			p {
				margin-bottom: 0;

				&.haulier-name {
					margin-bottom: 1em;
				}
			}
		}

		.sty-tbl-c0 {
			width: 5%;
			border-right: none;
		}
		.sty-tbl-c1, .sty-tbl-c2 {
			width: 13%;
		}
		.sty-tbl-c3 {
			width: 29%;
		}
		.sty-tbl-c4 {
			width: 12%;
		}
		.sty-tbl-c5 {
			width: 9%;
		}
		.sty-tbl-c6 {
			width: 8%;
			border-right: none;
		}
		.sty-tbl-c7 {
			width: 11%;

			a {
				display: block;
				margin-bottom: 0.5em;

				.iconprop-delete {
					color: #cc3300;
				}
			}
		}

		a.button-inactive:not(.button--shop) {
			cursor: default;
			opacity: 0.5;

			&:hover {
				color: $base-link-color;
			}
			&:active, &:focus {
				color: $base-link-color;
			}
		}
	}

	.order-detail__item-data {
		border-top: 1px solid $light-gray;
		padding-top: 2em;

		h4 {
			margin-bottom: 0.5em;
		}

		.key-value-line {
			.label, .value {
				border-bottom: 1px solid $light-gray;
				padding: 1em 100px 1em 0;
			}
			.value {
				padding-right: 0;
				position: relative;

				.order-detail__view-fields {
					position: absolute;
				}
			}
		}
		.legend {
			padding-top: 1em;
		}
		form {
			border-bottom: none;
		}
		.order-detail__edit-button {
			float: right;
		}
		.order-detail__view-fields {
			display: inline;
		}
		.order-detail__edit-fields {
			display: block;

			.button-wrapper {
				padding: 0;
				margin-top: 1em;
				border: none;

				.button {
					margin-right: 15px;
				}
			}

			input {
				width: 50%;
				display: inline-block;
			}
		}
	}
	.order-detail__item-data--editable {

	}

	.order-detail__delivery {
		border-top: 1px solid $light-gray;
		border-bottom: 1px solid $light-gray;

		.expandable-content {
			margin-left: 5%;
		}
		.expand-link {
			&:before {
				margin-right: 1.5em;
			}
		}
	}

	.order-detail__shipment-info {
		.spaced-icon {
			font-size: 1.4em;
		}
		.key-value-line {
			.label {
				padding-right: 4em;
			}
			.value {
				width: 100%;
			}
		}
		.sty-haulier-data {
			border-bottom: none;

			.key-value-line {
				.label, .value {
					border-bottom: none;
					padding-top: 0.5em;
					padding-bottom: 0.5em;
				}
			}
		}
	}

	.order-detail__batch-table {
		td {
			border-bottom-color: $light-gray;
		}
		.sty-tbl-c1, .sty-tbl-c2 {
			width: 14%;
		}
		.sty-tbl-c3 {
			width: 8%;
		}
		.sty-tbl-c4 {
			width: 6%;
		}
		.sty-tbl-c5 {
			width: 23%;
		}
		.sty-tbl-c6 {
			width: 35%;
		}
	}

	.eshop__template-detail-buttonbar {
		margin-bottom: 1em;
	}

	p.download {
		margin: 0;
	}

	.order-detail__item-title {
		td {
			border-bottom: none;
		}
	}



}

.order-customer-po {
	h4 {
		font-size: 1.2em;
	}
}