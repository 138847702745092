$rangeSliderHeight: 20px;
$rangeSliderLine: 4px;
$rangeSliderInputWidth: 90px;
$iconFontSize: 1.7rem;
$labelFontSize: 0.9rem;
$filtersItemPadding: 10px 16px;
$titleFontSize: 20px;
$titleLineHeight: 30px;
$copyFontSize: 16px;
$copyLineHeight: 24px;
$paddingResultsItem: 40px;
$searchIconFontSize: 30px;
$sliderBackgroundColor: #FBFBFB;

.pathfinder {
  &__filters {
    //max-height: 100vh;
    overflow-y: auto;

    &Head h2 {
      font-size: 28px;
      margin-left: 16px;
    }

    &Item {
      padding: $filtersItemPadding;
      border-bottom: 1px solid $light-gray;

      &Title {
        font-size: $titleFontSize;
        line-height: $titleLineHeight / $titleFontSize;
        margin-bottom: 4px;
        text-transform: uppercase;
      }

      &--button {

        .button {
          background: $white;
          color: $gray;
          border-color: $light-gray;
          width: 11%;
          margin-right: 5%;
          text-align: center;

          &.selected {
            background: $styrolution-green;
            color: $white;
          }

          &:hover {
            color: $white;
          }
        }
      }

      &--icon {

        .icon-label {
          font-size: $labelFontSize;
          margin-bottom: 0;
          cursor: default;
        }

        .pathfinder__icon {
          position: relative;
          top: 4px;
          margin-right: 10px;
          cursor: pointer;
          font-size: $iconFontSize;
          line-height: 26px;

          &.selected {
            color: $styrolution-green;
          }
        }
      }

      &--range, &--sliders {
        // overriding noUiSlider styles
        .noUi {

          &-horizontal {
            height: $rangeSliderHeight;
            // width: calc(100% - #{$rangeSliderHeight});
            // margin-left: #{$rangeSliderHeight / 2};
            border: 0;
            border-left: 10px solid $sliderBackgroundColor;
            border-right: 10px solid $sliderBackgroundColor;
            background: $sliderBackgroundColor;
            border-radius: 0;
            box-shadow: none;
          }

          &-base {
            height: $rangeSliderLine;
            top: calc((#{$rangeSliderHeight} - #{$rangeSliderLine}) / 2);
          }

          &-connects {
            background: $light-gray;
            margin-left: -1px;
            width: calc(100% + 2px);
            border-radius: 0;
          }

          &-handle {
            background: $styrolution-green;
            height: $rangeSliderHeight;
            width: $rangeSliderHeight;
            border: 0;
            border-radius: 50%;
            box-shadow: none;
            top: calc((#{$rangeSliderLine} / 2) - (#{$rangeSliderHeight} / 2));
            right: calc((#{$rangeSliderHeight} / 2) * (-1)) !important;

            &:before, &:after {
              display: none;
            }
          }

          &-connect {
            background: $styrolution-green;
          }

          &-pips {
            top: 0;
            height: auto; // prevents an overflow bug
          }

          &-marker {
            height: 10px;
            background: $light-gray;
          }

          &-value {
            font-size: 14px;
            color: $light-gray;
            transform: translate(-50%, 50%);
          }
        }

        .rangeInput {
          width: $rangeSliderInputWidth;
          display: inline-block;
          margin-top: 10px;
          font-size: 0.9rem;

          &Max {
            float: right;
          }
        }
      }

      // on single sliders, add bottom margin to allow for pips
      &--sliders .noUi-horizontal {
        margin-bottom: 30px;
      }

      &Inner {
        margin-top: 5px;

        input[type="radio"],
        input[type="checkbox"] {
          float: left;
          margin: 5px 10px 0 0;
        }

        label {
          font-size: $labelFontSize;
          font-weight: normal;
          margin-bottom: 6px;
        }

        &Title {
          margin: 16px 0 5px;
          font-size: 16px;
        }
      }
    }
  }

  &__results {
    &Item {
      // first result that is not hidden (cool workaround because there is no "select the first element without the class"
      &:not(.hidden) {
        padding: 0 0 $paddingResultsItem;
        border-top: 0;
      }

      // all following results that are not hidden
      &:not(.hidden) ~ &:not(.hidden) {
        padding: $paddingResultsItem 0;
        border-top: 1px solid $light-gray;
      }

      &.hidden {
        display: none;
      }

      &Description {
        display: flex;
      }

      &Title {
        font-size: $titleFontSize;
        line-height: $titleLineHeight / $titleFontSize;
        font-weight: bold;
        color: $styrolution-green;
      }

      &Copy {
        font-size: $copyFontSize;
        line-height: $copyLineHeight / $copyFontSize;
      }

      &Image {
        display: inline-block;
        width: 30%;
        margin: 0 16px 16px 0;
        flex-shrink: 0;
      }

      &Attributes {
        &Item {
          display: inline-block;
          width: 50%;
          font-size: $copyFontSize;
          margin-bottom: 5px;

          .pathfinder__icon {
            position: relative;
            top: 7px;
            margin-right: 15px;
            color: $styrolution-green;
            font-size: $iconFontSize;
          }
        }
      }

      &Product {
        &Grades {
          margin-top: 40px;

          table {
            margin: 0;
          }
        }
      }
    }
  }

  &__search {
    margin-bottom: 50px;

    &Wrapper {
      position: relative;
    }

    &Icon {
      position: absolute;
      top: 3px;
      left: 10px;
      color: $styrolution-green;
      font-size: $searchIconFontSize;
    }

    input[type="text"] {
      padding: 15px 0 12px 40px;
      border-radius: 20px;
    }
  }
}
