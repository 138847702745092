// Feature
.feature {
	background-color: white;
	background-size: cover;
	font-weight: 300;
	font-size: 18px;

	@include media($large-screen-up) {
		font-size: 21px;
	}

	.article-title {
		color: $styrolution-green;
	}
}

.feature--dark {
	position: relative;
	color: white;
	background-size: auto, cover;

	&:before {
		content: '';
		display: block;
		background-color: rgba(0, 0, 0, .7);
		@include position(absolute, 0 0 0 0);
	}

	.button {
		color: white;

		&:hover {
			color: $accented-font-color;
		}
	}

	.no-multiplebgs & {
		background-image: url('../img/old-ie/ie-feature-wwd-bg.png') !important;
		background-repeat: no-repeat;
	}
}

.feature--patterned {
	background-color: transparent;
	color: white;
	position: relative;

	&:before {
		content: '';
		@include position(absolute, 0 0 0 0);
		background-color: rgba(black, .7);
	}

	.no-rgba & {
		background-image: url(../img/old-ie/ie-news-slider-bg.png);
	}

	.article-title {
		color: $white;
	}
}

.what-we-do {
	overflow: hidden;
}

.featurebox {
	position: relative;

	&:not(:last-child) {
		margin-bottom: 30px;

		@include media($large-screen-up) {
			margin-bottom: 0;
		}
	}
}

.featurebox__subheading {
	display: block;
	font-size: 14px;
	color: $gray;
	margin-bottom: 10px;
}

.featurebox--linktop {
	margin-bottom: $base-spacing;

	&__title {
		display: block;
		overflow: hidden;
		height: 45px;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.link {
		font-size: 21px;

		&.link-small {
			font-size: 15px;

			.featurebox--linktop__title {
				height: 36px;

				i {
					font-size: 24px;
				}

			}
		}
	}

	> p {
		font-size: 14px;
		margin-bottom: 0;
	}

	.link--bordered + p {
		margin-top: 15px;
	}
}

.featurebox--linkbottom {
	margin-bottom: $base-spacing;
}

.featurebox--linkbottom__title {
	font-weight: 500;
	font-size: 16px;
	line-height: 21px;
}

.featurebox__media-holder {
	position: relative;
	overflow: hidden;

	@include media($large-screen-up) {
		max-height: 350px;


		img {
			display: block;
			@include transition-property(transform);
			@include transition-duration(0.4s);

			.ie9 & {
				min-width: 100%;
			}
		}

		&:not(.fmh-no-effect):hover,
		.link:hover & {
			img {
				@include transform(scale(1.1, 1.1));
			}
		}

		&:hover {
			.button--play {
				border-radius: 50%;
			}
		}
	}

	.ie8 & {
		.button--play--large {
			top: 50%;
			bottom: auto;
		}
	}
}

.fmh--natural {
	height: auto;

	@include media($large-screen-up) {
		min-height: 300px;
	}

	.no-backgroundsize & {
		height: 315px;
	}
}

.featurebox--video {
	.fmh--natural {
		height: 170px;

		@include media($large-screen-up) {
			min-height: 300px;
		}

		.ie8 & {
			height: 300px;
		}
	}
}

.featurebox__media-video {
	height: 180px;
}

// Feature snapshot
.feature-snapshot {
	background: url('../img/pattern-small.png'), url('../img/feat-snapshot-bg.jpg');
	background-repeat: repeat, no-repeat;
	background-position: center;
	background-size: auto, cover;
	position: relative;
	@include user-select(none);

	.content-heading {
		padding: 0 0 30px;

		.wrapper {
			padding: 0;
		}
	}

	.slick-prev,
	.slick-next {
		top: 50%;
		margin-top: -17px;
	}

	.slick-prev {
		right: auto;
		left: -20px;
	}

	.slick-next {
		right: -20px;
	}

	.no-multiplebgs & {
		background-image: url('../img/old-ie/ie-feature-snapshot-bg.png');
	}
}

.feature-snapshot__list-item {
	text-align: center;
}

.cards {
	@extend %clearfix;
}

.news-card {
	width: 100%;
	position: relative;
	margin-bottom: 10px;
	overflow: hidden;
  background-color: rgba(128, 128, 128, 0.3);

	.no-rgba & {
		background-color: $lightest-gray;
	}

	@include media(600px) {
		@include span-columns(6);
	}
}

.card-inner,
.news-card__image {
	display: block;
	height: 100%;
}

.news-card__image {

	img {
		vertical-align: middle;
	}
}

.card-inner {
	position: relative;
}

.card-inner__link {
	display: block;
	height: 100%;
	position: relative;

	@include media(1025px) {
		&:before {
			content: '';
			display: block;
			@include position(absolute, 0 null null 0);
			z-index: 1;
			background-color: $accented-background-color;
			width: 0;
			height: 5px;
			@include transition(width 1s cubic-bezier(0.5,0.2,0.5,1));
		}
	}

	&:hover:before {
		width: 100%;
	}
}

@include media($large-screen-up) {
	.news-card--large,
	.news-card--medium {
		@include span-columns(6);
	}

	.news-card--large {
		width: 49.16%;
	}

	.news-card--medium,
	.news-card--small,
	.news-card--extra-small {
		@include span-columns(3);
	}

	.news-card--small {
		margin-right: 0;
	}
}

.news-card__title {
	font-weight: 500;
	color: $dark-gray;
	padding: 10px;
	z-index: 2;

	.news-card--large.shuffle-item & {
		@include media($large-screen-up) {
			bottom: 30px;
		}
	}
}

.news-card__excerpt {
	font-size: 13px;
	line-height: 1.3;
	color: $gray;
	padding: 0 10px;
	display: none;
}

.card-inner {
	.meta {
		background-color: #ECECEC;
		border-top: 1px solid rgba(128, 128, 128, 0.12);
		padding: 2px 10px 3px;
	
		> div {
			color: $gray;
			display: inline-block;
			margin-right: 1em;
			z-index: 2;
		}
	
		p {
			font-size: 13px;
			margin-bottom: 0;

			&:before {
				font-family: Icomoon;
				display: inline-block;
				margin-right: 5px;
			}
		}

		.news-card__date {
			p:before {
				content: '\e608';
			}
		}

		.news-card__location {
			p:before {
				content: '\e905';
			}
		}
	}
}


.news-card {
	.js-fit-image {
		top: 0 !important;
	}
}

.feature-news {
	.news-card {
		position: relative;
		height: 210px;

		&--medium {
			margin-right: 0;
		}

		@include media($large-screen-up) {
			&--large,
			&--medium {
				height: 320px;
			}

			&--small {
				height: 210px;
			}

			&--extra-small {
				height: 100px;
			}

			&--medium {
				margin-right: 1.11685%;
			}
		}

		&__title {
			@include position(absolute, null 0 0 0);
			background-color: $lightest-gray;
		}
	}
}

.cards.news-list-view {
	.news-card {
		width: 100%;
		margin-bottom: 10px;
		overflow: hidden;

		&__image {
			height: 100px;
			width: 200px;
			float: left;
			margin-right: 20px;
			position: relative;
			overflow: hidden;
		}

		&__title {
			font-size: 20px;
		}

		&__excerpt {
			font-size: 16px;
			display: block;
		}
	}
}

// INCH card - Media Centre
.inch-card {
	background-color: #fff;
	margin-bottom: $base-spacing;
	@extend %clearfix;

	@include media($large-screen-up) {
		margin-bottom: $base-spacing / 2;
	}
}

.inch-card__image {
	max-height: 160px;
	overflow: hidden;
	position: relative;

	img {
		position: relative !important;
	}

	@include media($large-screen-up) {
		height: 202px;
		@include span-columns(2 of 6);
	}
}

.inch-card__content {
	@include media($large-screen-up) {
		@include span-columns(4 of 6);
	}
}

.inch-card__body,
.inch-card__footer {
	padding: 10px;
}

.inch-card__body {
	max-height: 140px;
	overflow: hidden;
	position: relative;

	> p {
		font-size: 14px;
		margin-bottom: 0;
	}

	&:after {
		content: '';
		height: 40px;
		@include linear-gradient(to bottom, rgba(white, .0), white);
		@include position(absolute, null 0 0 0);
	}

	@include media($large-screen-up) {
		height: 104px;
	}
}

.inch-card__title {
	font-weight: 500;
	font-size: 18px;
	padding: 5px 0;
	text-transform: uppercase;
	display: block;
	margin-bottom: 10px;
}

.inch-card__footer__item {
	position: relative;
	display: inline-block;
	font-weight: 500;
	font-size: 14px;

	i {
		font-size: 24px;
		margin-right: 5px;
		position: relative;
		top: 3px;

		&.icon-caret-down {
			margin-left: 5px;
		}
	}

	&:not(:last-child) {
		padding-right: 10px;
		margin-right: 10px;

		&:after {
			content: '';
			display: inline-block;
			width: 1px;
			height: 60%;
			background-color: $lightest-gray;
			@include position(absolute, null 0 3px null);
		}
	}
}

.tags {
	&:hover {
		cursor: pointer;
		cursor: hand;
	}
}

.tags-list {
	text-align: center;
	background-color: #fff;
	border: 1px solid $lightest-gray;
	padding: 5px 10px;
	@include position(absolute, null null 30px 0);
}

.tag:not(:last-child) {
	border-bottom: 1px dotted $lightest-gray;
}

.inch-issue {
	position: relative;
	border: 8px solid white;
	height: 360px;
	background-size: cover;

	@include media($large-screen-up) {
		height: 504px;
	}
}

.issue-cover {
	&:before {
		content: '';
		@include position(absolute, 0 0 0 0);
		@include linear-gradient(to bottom, rgba(black, .08), rgba(black, .4));
	}

	.no-cssgradients & {
		&:before {
			height: 100%;
			background-image: url('../img/old-ie/ie-inch-issue-bg.png');
		}
	}
}

.inch-logo {
	width: 16%;
	@include position(absolute, -2px -2px null null);
}

.issue-title {
	line-height: 1;
	color: white;
	padding: 15px 20px;
	width: 70%;
	@include position(relative, 0 null null 0);

	+ .button {
		position: relative;
		margin-top: 20px;
		margin-left: 20px;

		i {
			font-size: 28px;
			line-height: 0;
			margin-left: 10px;
			position: relative;
			top: 3px;
		}
	}
}

.inch-issue__articles {
	padding: 20px;
	@include position(absolute, null null 0 0);
	color: white;

	> p {
		font-weight: 500;
		font-size: 13px;
		margin-bottom: 5px;
		text-transform: uppercase;
	}

	.articles-list {
		li {
			font-weight: 500;
			font-size: 14px;
			text-transform: uppercase;
		}
	}
}

// Feature icon list
.feature-iconlist__key-values {
	padding: ($base-spacing / 2) 0;

	@include media($large-screen-up) {
		padding-top: 70px;
		text-align: center;
		background: url('../img/company/vision-values-top-curly-bracket-blue.png') top center no-repeat;
		background-size: 0;
		@include transition (background-size 0.5s ease-in-out);
	}
}

.title--small--feature-iconlist {
	@include media($large-screen-up) {
		font-weight: 500;
		font-size: 18px;
		text-align: center;
		padding-top: $base-spacing;
		border-top: 1px solid $lightest-gray;
	}
}

.key-values__item {
	position: relative;
	padding: 10px 0;
	display: table;
	width: 100%;
	@include clearfix;

	.icon-holder {
		width: 50px;
		display: table-cell;
		vertical-align: middle;
		text-align: center;
	}

	> p {
		padding-left: 20px;
		margin-bottom: 0;
		display: table-cell;
		vertical-align: middle;
	}

	&:not(:last-child) {
		border-bottom: 1px solid $lightest-gray;
	}

	@include media($large-screen-up) {
		padding: 0;
		display: inline-block;
		vertical-align: top;
		width: 176px;

		&:not(:last-child) {
			margin-right: 55px;
			border-bottom: none;
		}

		.icon-holder {
			display: block;
			width: auto;
			height: 106px;
			position: relative;
			border-bottom: 1px solid $lightest-gray;
			margin-bottom: 10px;

			img {
				position: relative;
			}
		}

		> p {
			padding-left: 0;
			display: block;
			font-size: 13px;
		}
	}
}




.feature-group {
	.featurebox--linktop {
		max-width: 480px;
	}
	
	.featurebox__media-holder {
		height: 180px;
		
		img {
			max-width: none;
			min-height: 100%;
			min-width: 100%;
		}
		
		html.objectfit & {
			img {
				height: 100%;
				min-height: 0;
				min-width: 0;
				object-fit: cover;
				width: 100%;
			}
		}
	}
	
	@include media($large-screen-up) {
		.featurebox--linktop {
			max-width: none;
		}
	}
}
