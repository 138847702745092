/**
 * Standard Sliders
 * (using bxSlider)
 */
.slider {
	li {
		position: relative;
		display: none; // to avoid all slides from being visible up on load and before the slider has been initialised
	}
	
	li:first-child {
		display: block;
	}
	
	img {
		display: block;
		width: 100%;
	}
	
	.bx-wrapper {
		li {
			display: block;
			margin: 0 !important;
			
			img {
				object-fit: cover;
				height: 100%;
				max-width: none;
			}
		}
	}
	
	@include media($max-width) {
		.bx-wrapper {
			li {
				img {
					width: 100%;
				}
			}
		}
	}
}



/**
 * Stage Slider
 */
#stage {
	overflow: hidden;
	
	.carousel__hero-news {
		background: $styrolution-blue-medium;
		position: relative;
		
		ul {
			padding: 0;
		}
		
		li {
			display: block;
			margin: 0;
			padding-right: 50px;
		}
		
		.slick-dots {
			margin-top: 1em;
			
			li {
				display: inline-block;
			}
		}
	}
}


#stage.slider {
	.content {
		height: 100%;
		left: 0;
		overflow: hidden;
		padding: 0 !important;
		position: absolute;
		top: 0;
		width: 100%;
		
		h4,
		p {
			color: $inverted-font-color;
			line-height: .9;
			text-transform: uppercase;
		}
		
		h4 {
			font-size: em($baseh1);
			font-weight: 500;
			margin: .9em 0 0;
		}

		p {
			font-size: 14px;
			display: none;
			margin: 1em 0 0;
		}
	}
	
	.button {
		background: $styrolution-green;
		border-color: $styrolution-green;
		color: $inverted-font-color;

		margin: 2em 0 0;
		
		&:hover {
			background: $styrolution-green-dark;
			border-color: $styrolution-green-dark;
		}
	}
	
	li.animated-content {
		.wrapper {
			top: -100%;
		}
		
		.button {
			opacity: 0;
		}
	}
	
	.bx-wrapper {
		li {
			height: 240px;
			z-index: 1;
			
			.content {
				@include transition(opacity 2s ease-out);
				@include transition-delay(.5s);
				display: block;
				opacity: 0;
				z-index: 1;
			}
		}
		
		li.on {
			z-index: 2;
			
			.content {
				opacity: 1;
			}
		}
	}
	
	.bx-controls-direction a {
		font-size: 40px;
		height: 54px;
		line-height: 50px;
		margin-top: -27px;
		width: 40px;
	}
	
	@include media($medium-screen-up) {
		.content {
			p {
				display: block;
			}
		}
	}
	
	@include media($max-width) {
		.wrapper {
			height: 100%;
			overflow: hidden;
			position: relative;
		}
		
		.content {
			h4 {
				font-size: 3vw;
				width: 50%;
			}
		
			p {
				bottom: 2em;
				font-size: 1.2vw;
				position: absolute;
				right: 0;
				width: 40%;
			}
		}
		
		li.animated-content {
			p {
				right: -100%;
			}
		}
		
		.bx-wrapper {
			li {
				height: auto;
			}
		}
		
		.bx-controls-direction a {
			height: 84px;
			line-height: 80px;
			margin-top: -42px;
			width: 60px;
		}
	}
}

.content  #stage {
	@include media($max-width) {
		.bx-wrapper,
		.carousel__hero-news {
			.wrapper {
				padding: 0 20px;
			}
		}
	}
}

header.crisis + main {
	#stage.slider {
		> ul > li {
			height: 240px;
			
			img {
				object-fit: cover;
				height: 100%;
				max-width: none;
			}
		}
	}
}


html.no-touch .slider {
	.bx-wrapper:hover .bx-controls-direction a {
		opacity: .6;
	}
	
	.bx-wrapper .bx-controls-direction a:hover {
		background: $accented-background-color;
		opacity: 1;
	}
}