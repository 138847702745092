.button,
.button-contact,
button,
input[type="submit"] {
	@extend %button;
	@include appearance(none);
	cursor: pointer;
	user-select: none;
	vertical-align: middle;

	i.icon-chevron-right, i.icon-chevron-down {
		display: none;
	}

	&.button--shop {
		text-transform: none;
		padding: 0.7em 1.7em 0.85em;
	}
}

.spaced-icon {
	margin-right: 6px;
	top: 2px;
	position: relative;
}

.button.submit-changes,
.button.change-soldto,
button[type="submit"],
.button.download,
.button.primary {
	background: $styrolution-green;
	border-color: $styrolution-green;
	color: $inverted-font-color;

	&:not(.button-inactive):hover {
		background: $styrolution-green-dark;
		border-color: $styrolution-green-dark;
	}

	&.button-inactive:hover {
		color: $inverted-font-color;
	}
}

.button.primary2 {
	background: $styrolution-orange;
	border-color: $styrolution-orange;
	color: $inverted-font-color;

	&:not(.button-inactive):hover {
		background: $styrolution-orange-dark;
		border-color: $styrolution-orange-dark;
	}

	&.button-inactive, &.button-inactive:hover {
		color: $inverted-font-color;
	}
}

.button.secondary {
	//background: $lightest-gray;
	//border-color: $lightest-gray;
	//color: #000000;
	//font-weight: 500;

	//&:not(.button-inactive):hover {
	//background: $light-gray;
	//border-color: $light-gray;
	//}

	//&.button-inactive, &.button-inactive:hover {
	//	color: $medium-gray;
	//}
}

.button.order {
	background: $styrolution-orange;
	border-color: $styrolution-orange;
	color: $inverted-font-color;
	opacity: .8;

	&:not(.button-inactive):hover {
		opacity: 1;
	}

	&.button-inactive:hover {
		color: $inverted-font-color;
	}
}

.button.sign-in,
.button.sign-out,
.button.registration {
	padding: 0.5em 0.9em 0.7em;
	text-transform: none;
	background-color: $styrolution-blue;
	color: white;
	border: none;
	height: 33px;
	margin-left: 0.3em;

	&:hover {
		background-color: $styrolution-blue-medium;
		color: white;
	}
}

.button.registration {
	padding-top: 0.65em;
}

.button.cancel,
button.cancel {
	color: $gray;
	border-color: $light-gray;

	&:hover {
		border-color: $accented-border-color;
	}
}

.button.button-inactive {
	opacity: .5;
	cursor: default;
}

.button--faded {
	color: $gray;
	border-color: $light-gray;

	&:hover {
		border-color: $accented-border-color;
	}
}

.button--highlighted {
	color: $accented-font-color;
	border-color: $accented-border-color;

	&:hover {
		color: $hover-link-color;
	}
}

.button-contact,
.button--plain {
	background-color: $accented-background-color;
	color: white;
	border-color: $accented-border-color;

	&:hover {
		background: $styrolution-green-dark;
		border-color: $styrolution-green-dark;
		color: white;
	}

	&:visited {
		//color: white;
	}

	i.icon-angle-right {
		display: none;
	}
}

input[type="submit"] {
	&.button--plain {
		color: white;
		border-color: $accented-border-color;
	}
}

.button-contact {
	text-align: center;
	display: block;
	font-size: 16px;
	font-weight: 500;
	padding: 12px 10px;
}

.button--more {
	display: block;
	text-align: center;
}

.button--headtitle {
	background-color: white;
	color: $accented-font-color;
	border-color: white;

	&:hover {
		color: white;
	}
}

.button--download {
	padding-top: 10px;
	margin-bottom: $base-spacing;
	position: relative;
	padding-left: 50px;

	&:before {
		content: '\e60b';
		display: inline-block;
		font-family: "Icomoon";
		color: $gray;
		margin-right: 10px;
		@include position(absolute, 50% null null 20px);
		margin-top: -7px;
	}
}

.button--media-centre-card {
	margin-top: 20px;
	margin-bottom: 10px;
}
