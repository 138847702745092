// Header
.main-header {
	height: 60px;
	padding: 12px 0;
	@extend %clearfix;
	
	&.nav-is-visible {
		z-index: 1;
	}

	@include media($large-screen-up) {
		height: 100px;
		padding: 25px 0;
	}
}

.logo {
	display: inline-block;
	height: 37px;

	img {
		max-width: initial;
		max-height: 100%;
		vertical-align: middle;
		
		&.black {
			display: none;
		}
		
		.main-header.crisis & {
			display: none;
		}
		
		.main-header.crisis &.black {
			display: block;
		}
	}

	@include media($large-screen-up) {
		height: 50px;
		margin-right: 25px;

		.parent-business {
			text-transform: uppercase;
			color: #001842;
			position: relative;
			top: -5px;

			span {
				color: $accented-font-color;
			}
		}

		+ .sitename {
			position: relative;
		}
	}
}

.sitename {
	display: none;

	@include media($medium-screen-up) {
		display: inline-block;
		float: left;
		font-weight: 700;
		font-size: 24px;
		color: $accented-font-color;
		padding: 7px 25px;
		border-left: 1px solid $lightest-gray;

		&.child-business {
			font-size: 14px;
			line-height: 32px;
			color: $gray;

			a:hover {
				color: $gray;
			}
		}
	}
}

.main-header__links {
	display: none;

	li {
		display: inline-block;
		vertical-align: middle;
		font-size: 14px;

		a {
			padding: 0;
			color: $base-font-color;

			&:hover {
				color: $accented-font-color;
			}
			
			&:not(.button)[data-link-type="popup"]:after,
			&:not(.button)[data-toggle="modal"]:after {
				@extend %expander;
			}
		}

		> span {
			font-size: 14px;
			
			&:not(:last-child) {
				margin-right: -4px;
			}
		}
		
		> a + span {
			margin-left: -4px;
		}
	}
	
	a[data-toggle="modal"],
	.dropdown__button {
		position: relative;
	}

	.dropdown__button {
		color: $gray;
		line-height: 1.3;
		top: 3px;

		&:hover {
			color: $accented-font-color;
			background-color: transparent;
		}
	}
	
	.dropdown__menu {
		margin-top: 30px;
		width: 110%;

		li {
			display: block;
			padding-left: 0;
			padding-right: 0;
			
			a {
				&:after {
					content: none;
				}
			}
		}
	}
	
	.dropdown__quick-info {
		width: auto;
	}

	@include media($large-screen-up) {
		display: inline-block;
		position: relative;
		top: -4px;
		
		li {
			> a {
				padding: 0 10px;
			}
			
			> a,
			.dropdown__button {
				font-weight: 700;
			}
		}
	}
}

.main-header__buttons {
	display: inline-block;
	position: relative;
	top: -4px;

	li {
		display: inline-block;

		a {
			font-size: 30px;
			color: $base-font-color;
			padding: 0 8px;

			&:hover,
			&.active {
				color: $accented-font-color;
			}
			
			@include media($large-screen-up) {
				&.nav-trigger {
					display: none;
				}
			}
		}
	}

	.nav-is-visible & {
		li a.nav-trigger {
			color: $accented-font-color;
		}
	}

	@include media($large-screen-up) {
		top: 0;

		.main-header__links + & {
			margin-left: 10px;
		}
	}
}
