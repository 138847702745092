/**
 * Products (common)
 * miscellaneous elements
 */
.products {
	.expandable {
		.expand-link {
			font-size: 1em;
		}
	}
	
	.props {
		[class^="icon-"], [class^="iconprop-"] {
			font-size: 2.6em;
			line-height: 1;
			margin-left: .4em;
			
			&:first-child {
				margin-left: 0;
			}
		}

		[class^="iconprop-"] {
			font-size: 2em;
		}
	}
}



/**
 * Products (common)
 * download links
 */
.products {
	p.download,
	ul.downloads li {
		> a {
			@extend %icon;
			@extend %icon-file-empty;
			background: $white-hued;
			display: block;
			padding: .8em 1em .8em 3em;
			position: relative;
		}
		
		html.no-touch & a:hover {
			background: $lightest-gray;
			
			.meta {
				color: $gray;
			}
		}
		
		a:before {
			font-size: 1.5em;
			height: 2em;
			left: .6em;
			line-height: 2em;
			margin-left: 0;
			margin-top: -1em;
			position: absolute;
			top: 50%;
			width: 1em;
		}
	}
	
	ul.downloads {
		margin: 0 0 1.5em;
		padding: 0;
		
		li {
			margin: 0;
		}
		
		li + li {
			margin-top: 2px;
		}
	}
	
	.button.download {
		margin-bottom: 1.5em;
	}
}



/**
 * Products (common)
 * data tables
 */
.products table {
	margin: 0 0 2em;
	position: relative;


	thead {
		display: block;
		font-style: italic;
		
		&:before {
			content: attr(title);
			float: left;
			font-size: 14px;
			padding: 0 .5em 0 0;
		}
		
		tr {
			background: $base-background-color;
			padding: 0 0 .4em;
		}
		
		td,
		th {
			background: none;
			border: none;
			color: $base-font-color;
			display: inline-block;
			font-size: 14px;
			padding: 0;
			vertical-align: top;
			
			&:after {
				content: '\00a0\00b7';
			}
			
			&:last-child:after {
				content: '';
			}
			
			br {
				position: absolute;
			}
		}
	}
	
	tbody,
	tr,
	th,
	td {
		display: block;
		
		&.sec,
		&.secondary {
			display: none;
		}

		.download-link-list {
			float: right;
			top: 0;
			margin-right: 1em;

			.download-link {
				text-align: center;
				margin-left: 1em;
			}
		}

		.download-link {
			display: inline-block;
			position: relative;
			right: 0;
			.label {

			}
		}
	}
	
	tr {
		border-bottom: 2px solid $light-gray;
	}
	
	tr.subheader {
		background: $lightest-gray;
	}
	
	th,
	td {
		border-bottom: 1px solid $lightest-gray;
		padding: 10px 0;
		position: relative;

		
		&:last-child {
			border-bottom-width: 0;
		}
	}

	td:after {
		content: "";
		clear: both;
		box-sizing: border-box;
		display: block;
		height: 0;
	}
	
	.icon:before,
	[class^="icon-"]:before, [class^="iconprop-"]:before {
		font-size: 1em;
	}
	
	&.list {
		.button {
			margin-bottom: .4em;
			text-align: center;
		}
	}
	
	@include media($large-screen-up) {
		thead {
			display: table-header-group !important;
		}
		
		tbody {
			display: table-row-group !important;
		}
	
		tr {
			display: table-row !important;
		}
		
		td,
		th {
			display: table-cell !important;
		}
		
		thead {
			font-style: normal;
			
			&:before {
				display: none;
			}
			
			tr {
				padding: 0;
			}

			td,
			th {
				&:after {
					content: '';
				}
				
				br {
					position: static;
				}
			}
			
			th.main {
				font-size: 20px;
				font-weight: 500;
				vertical-align: bottom;
			}
		}
		
		tr {
			border: none;
		}
		
		thead td,
		thead th,
		td,
		th {
			border-bottom: 1px solid $light-gray;
			border-right: 1px solid $light-gray;
			padding: 10px;
			
			&:last-child {
				border-bottom-width: 1px;
				border-right-width: 0;
			}
		}
		
		td.num,
		td.icon {
			text-align: center;
		}
		
		th.col-s,
		td.col-s {
			width: 10%;
		}

		th.col-m,
		td.col-m {
			width: 32%;
		}

		th.col-l,
		td.col-l {
			width: 51%;
		}

		td {
			.download-link-list {
				display: none;
			}
		}
		
		&.list {
			thead td,
			thead th,
			td,
			th {
				border-left: none;
				border-right: none;
			}
			
			.button {
				width: 100%;
			}
		}
	}
}






/**
 * Product Overview
 */
.product-overview {
	.panel-list {
		li {
			padding-right: 10px;
			
			.props {
				display: none;
			}
			
			.detail {
				> .icon-angle-right:before {
					display: none;
				}
			}
			
			.notice {
				@include transition (opacity 0.2s ease-out);
				@include transition-delay(.1s);
				background: $styrolution-orange;
				border: none;
				height: 100%;
				left: 0;
				margin: 0;
				opacity: 0;
				padding: 0 10px;
				position: absolute;
				top: 0;
				width: 100%;
				
				p {
					@include transform(translateY(-50%));
					color: $inverted-font-color;
					font-weight: 700;
					position: relative;
					text-align: center;
					top: 50%;
				}
			}
		}
		
		li.disabled {
			.props {
				color: #d0d0d0;
			}
			
			.icon,
			[class^="icon-"],
			[class^="iconprop-"] {
				opacity: 1;
			}
			
			.icon {
				background: $light-gray;
			}
			
			&.show-notice,
			html.no-touch &:hover {
				.notice {
					opacity: 1;
				}
			}
		}
	}
	
	@include media($medium-screen-up) {
		.panel-list {
			li {
				padding-right: 40px;

				.detail {
					> .icon-angle-right:before {
						display: block;
					}
				}
				
				.props {
					display: block;
					
					> a,
					> span {
						margin-left: 1em;
						
						&:first-child {
							margin-left: 0;
						}
					}
					
					span.disabled {
						color: #d0d0d0;
					}
				}
				
				.info {
					float: left;
					margin: 0 2em 0 0;
				}
				
				.props {
					float: right;
				}
			}
			
			li.disabled {
				padding-right: 40px;
			}
		}
	}
}






/**
 * Product Group
 * (todo: if we have more sliders we should move these styles to a more generic class)
 */
.product-group {
	.article-heading {
		.subtitle {
			margin-top: -20px;
		}
	}
	
	.presentation {
		margin: 0 0 1.5em;
		.slider {
			li {
				height: 200px;
			}
			
			.bx-pager {
				display: block;
				position: static;
			}
			
			.bx-controls-direction a {
				margin-top: -60px;
			}
		}
	}
	
	ul.props {
		list-style: none;
		padding: 0;
		
		li {
			overflow: hidden;
			
			&:not(:last-child) {
				margin-bottom: 5px;
			}
		}
		
		.icon,
		[class^="icon-"],
		[class^="iconprop-"] {
			color: $styrolution-green;
			display: inline-block;
			margin-right: .5em;
			vertical-align: middle;
		}
	}
	
	table {
		thead {
			font-style: normal;
			
			&:before {
				content: '';
				display: none;
			}

			tr {
				padding: 0;
			}

			td,
			th {
				background: $styrolution-blue-medium;
				color: #fff;
				font-weight: 400;
				&:after {
					content: '';
				}
			}
		}
		
		tr {
			border-bottom: 1px solid $light-gray;
		}
		
		td,
		th {
			border-bottom-width: 0;
		}
		
		th.main,
		tr.subheader {
			background: $styrolution-blue-medium;
		}
		
		th.main,
		.subheader td {
			color: #fff;
			display: block;
			padding: 20px 10px 10px;
		}
		
		td.group {
			background: #cbd5e5;
		}
		
		td.subgroup {
			font-size: 80%;
			padding-bottom: 0;
			
			& + td {
				padding-top: 0;
			}
			
			br {
				position: absolute;
			}
		}
		
		em {
			font-size: 90%;
			font-style: normal;
			font-weight: 700;
			text-transform: uppercase;
			white-space: nowrap;
		}
	}
	
	@include media($medium-screen-up) {
		.presentation {
			.slider {
				float: left;
				margin-right: 1em;
				width: 48%;
			}
		}
	}
	
	@include media($large-screen-up) {
		.presentation {
			.slider {
				padding-right: 30px;
			}
		}
		
		table {
			thead {
				td,
				th {
					font-size: 12px;
				}
			}
			
			td,
			th {
				border-bottom-width: 1px;
			}
				
			.subheader td {
				font-size: 20px;
			}
			
			td.subgroup {
				background: #e5eaf2;
				font-size: 14px;
				padding-bottom: 10px;

				& + td {
					padding-top: 10px;
				}

				br {
					position: static;
				}
			}
		}
	}
}






/**
 * Product Details (Grade)
 * miscellaneous content elements
 */
.product-details {
	.legend {
		font-style: italic;
	}
	
	select {
		margin-bottom: 1em;
	}
	
	.button {
		padding-left: 1em;
		padding-right: 1em;
	}
	
	ul.props {
		list-style: none;
		padding: 0;
		
		li {
			overflow: hidden;
			
			&:not(:last-child) {
				margin-bottom: 5px;
			}
		}
		
		.icon,
		[class^="icon-"], [class^="iconprop-"] {
			color: $styrolution-green;
			display: inline-block;
			margin-right: .4em;
			vertical-align: middle;
		}
	}
	
	.col-lg-4 > .button:first-child {
		margin-top: 2.1em;
		width: 100%;
	}
	
	@include media($large-screen-up) {
		.tab-content {
			h2 {
				font-size: 24px;
			}
		}
	}
}






/**
 * Document Search
 */
.doc-search {
	ol.form-steps {
		counter-reset: form-steps-counter;
		list-style: none;
		margin: 0;
		padding: 0;
		
		> li {
			border-bottom: $base-border;
			
			&:first-child {
				border-top: $base-border;
			}
		}
		
		.hidden {
			display: none;
		}
		
		fieldset {
			border: none;
			padding-left: 0;
			padding-right: 0;
			margin: 0;
		}
		
		fieldset + fieldset {
			margin-top: -1em;
			padding-top: 0;
		}
		
		.options label {
			margin-top: 0;
		}
		
		h4 {
			color: $styrolution-blue;
			font-size: 20px;
			line-height: 25px;
			margin: 0 0 1em;
			position: relative;
			text-transform: uppercase;
		}
		
		h4:before {
			background: $styrolution-blue;
			border-radius: 1em;
			color: $inverted-font-color;
			content: counter(form-steps-counter, decimal);
			counter-increment: form-steps-counter;
			display: inline-block;
			font-size: 0.9em;
			height: 1.6em;
			line-height: 1.6em;
			margin-right: .8em;
			text-align: center;
			width: 1.6em;
		}
		
		h5 {
			font-weight: 700;
			text-transform: uppercase;
		}
		
		#productgroup-select {
			h5 {
				margin-bottom: .5em;
			}
			
			> div,
			ul + h5 {
				margin-top: 3.5em;
			}
			
			ul {
				border-bottom: 1px solid $light-gray;
				border-top: 1px solid $light-gray;
			}
			
			li {
				border-top: 1px solid $lightest-gray;
				padding: .3em 0;
			}
			
			li:first-child {
				border-top: none;
			}
			
			a.disabled {
				color: $light-gray;
				cursor: default;
			}
		}
		
		.inline-title {
			@extend %clearfix;
		}
		
		.inactive {
			h4 {
				color: $light-gray;
			}

			h4:before {
				background: $light-gray;
			}
		}
	}
	
	.results {
		margin: 4em 0 0;
		
		h3 {
			font-size: 24px;
			margin: 0 0 .8em;
		}
		
		h4 {
			font-size: 16px;
			font-weight: 700;
			line-height: 25px;
			margin: 0 0 1em;
		}
		
		label {
			font-size: 14px;
			font-weight: 400;
		}
		
		h4 + label {
			margin-top: -1.5em;
		}
		
		select {
			margin: 0 0 1em;
		}
	}
	
	@include media($large-screen-up) {
		ol.form-steps {
			.inline-title {
				h4 {
					float: left;
					margin-bottom: 0;
					margin-right: 2em;
				}
				
				h4 + select,
				h4 + .filterable-select,
				.selection {
					float: right;
					width: 55%;
				}
			}
			
			#productgroup-select {
				clear: both;
				padding-top: 2em;
				
				> div {
					margin-top: 0;
				}
			}
		}
		
		.results {
			.result-header {
				min-height: 4em;
			}
			
			.downloads li {
				font-size: 14px;
			}
		}
	}
}




/**
 * Notice / Error
 */
.products {
	.notice {
		border-left: 8px solid $styrolution-orange;
		margin: 4em 0 0;
		padding: 1.2em 20px 0;
		
		h3 {
			color: $styrolution-orange;
			font-size: 24px;
			margin: 0 0 .8em;
		}
	}
}