body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $base-background-color;
  color: $base-font-color;
  font-family: $base-font-family;
  font-size: em($basefont);
  line-height: $base-line-height;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $header-font-family;
  font-weight: normal;
  line-height: $header-line-height;
  margin: 0;
}

h1 {
  font-size: em($baseh1);
}

h2 {
  font-size: em($baseh2);
}

p {
  margin: 0 0 $base-spacing;
}

a {
  @include transition(color 0.1s linear);
  color: $base-link-color;
  text-decoration: none;

  &:hover {
    color: $hover-link-color;
  }

  &:active, &:focus {
    color: $hover-link-color;
    outline: none;
  }
}

hr {
  border-bottom: $base-border;
  border-left: none;
  border-right: none;
  border-top: none;
  margin: $base-spacing 0;
}

img,
picture {
  margin: 0;
  max-width: 100%;
}

blockquote {
  font-style: italic;
  border-left: 2px solid $base-border-color;
  margin: $base-spacing 0;
  padding-left: $base-spacing / 2;
}

cite {
  color: lighten($base-font-color, 25);
  font-style: italic;

  &:before {
    content: "\2014 \00A0";
  }
}

q {
  font-style: italic;
}
