.article {
	ul.accordion-tabs-minimal {
		li:not(:last-child) {
			margin-bottom: 0; // yeah, long live the strong selectors produced by SASS …
		}
	}
}

.accordion-tabs-minimal {
	$tab-content-background: $base-background-color;
	$tab-active-background: $tab-content-background;
	$tab-inactive-color: $base-background-color;
	$tab-inactive-hover-color: darken($light-gray, 5);
	$tab-mode: $large-screen;

	@include clearfix;
	margin-bottom: $base-spacing;

	li.tab-header-and-content {
		border-bottom: 2px solid $base-border-color;
		border-top: 2px solid $base-border-color;
		margin-bottom: 0;
		margin-top: -2px;
		
		@include media($tab-mode) {
			border-bottom: none;
			border-top: none;
			display: inline;
		}
	}

	a.tab-link {
		font-weight: 700;
		font-size: 16px;
		text-transform: uppercase;
		color: $styrolution-green;
		display: block;
		padding: $base-spacing/2 0;

		@include media($tab-mode) {
			display: inline-block;
			border-top: 0;
			padding-left: 10px;
			padding-right: 3em;
		}

		&:hover {
			color: $styrolution-green-dark;
		}

		&:focus {
			outline: none;
		}

		&.is-active {
			border-bottom: 0;
			color: $styrolution-blue;

			@include media($tab-mode) {
				border-bottom: 4px solid $styrolution-blue;
				margin-bottom: -1px;
			}
		}
	}

	.tab-content {
		display: none;
		padding: $base-spacing 0;
		width: 100%;

		h4 {
			font-weight: 500;
		}

		@include media($tab-mode) {
			border-top: 1px solid #cccccc;
			float: left;
		}
	}
}


.article {
	ul.accordion-tabs-minimal {
		list-style: none;
		padding-left: 0;
	}
}