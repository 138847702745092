.homepage-search {
    h2.article-title {
        text-align: center;
        margin-bottom: 20px;
    }

    .search {
        max-width: 650px;
        margin: 0px auto;

        &:before {
            top: 3px;
            color: $styrolution-green;
        }

        input {
            padding-top: 8px;
            font-size: 16px;
        }

        .autocomplete-suggestions {
            position: absolute;
            width: 100%;
            z-index: 100;
            top: 37px;

            p {
                font-size: 16px;
                margin-bottom: 10px;
            }
        }
    }

    .filters {
        text-align: center;
        margin-top: 20px;

        .filter-col {
            display: inline-block;
            text-align: left;
            vertical-align: top;
            margin: 0 30px;
    
            h5 {
                margin-bottom: 8px;
            }
    
            ul {
                li {
                    line-height: 20px;
        
                    input {
                        display: inline-block;
                        margin: 0;
                    }
            
                    label {
                        display: inline-block;
                        font-size: 14px;
                        font-weight: normal;
                        vertical-align: top;
                        margin-top: 1px;
                    }
                }
            }
        }
    }
    
    .start-search-wrapper {
        text-align: center;

        input {
            margin-top: 20px;
            background: $styrolution-green;
            border-color: $styrolution-green;
            color: $white;

            &:hover {
                background: $styrolution-green-dark;
                border-color: $styrolution-green-dark;
            }
        }
    }
}
