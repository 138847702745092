@include font-face(Icomoon, '../fonts/icomoon',  $file-formats: eot svg ttf woff);

%icon:before {
  color: inherit;
  display: inline-block;
  font-family: "Icomoon";
  font-size: .75em;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  speak: none;
  text-align: center;
  text-decoration: inherit;
  text-transform: none;
}

%icon-prop-chemical-resistance:before {
  content: "\e90d";
}
%icon-prop-flowability:before {
  content: "\e90e";
}
%icon-prop-medical:before {
  content: "\e90f";
}
%icon-prop-transparency:before {
  content: "\e910";
}
%icon-automotive:before {
  content: "\e906";
}
%icon-construction:before {
  content: "\e907";
}
%icon-electronics:before {
  content: "\e908";
}
%icon-healthcare:before {
  content: "\e909";
}
%icon-household:before {
  content: "\e90a";
}
%icon-packaging:before {
  content: "\e90b";
}
%icon-toys-leisure:before {
  content: "\e90c";
}
%icon-plus:before {
  content: "\e114";
}
%icon-minus:before {
  content: "\e115";
}
%icon-cross:before {
  content: "\e117";
}
%icon-menu:before {
  content: "\e120";
}
%icon-search:before {
  content: "\e600";
}
%icon-tags:before {
  content: "\e601";
}
%icon-bookmark:before {
  content: "\e602";
}
%icon-rss:before {
  content: "\e603";
}
%icon-youtube:before {
  content: "\e604";
}
%icon-twitter:before {
  content: "\e605";
}
%icon-facebook:before {
  content: "\e606";
}
%icon-arrow-right-circle:before {
  content: "\e607";
}
%icon-clock:before {
  content: "\e608";
}
%icon-external:before {
  content: "\e609";
}
%icon-warning:before {
  content: "\e60a";
}
%icon-download:before {
  content: "\e60b";
}
%icon-linkedin2:before {
  content: "\e60c";
}
%icon-grid:before {
  content: "\e901";
}
%icon-list:before {
  content: "\e902";
}
%icon-globe:before {
  content: "\e904";
}
%icon-earth:before {
  content: "\e905";
}
%icon-close:before {
  content: "\f00d";
}
%icon-play:before {
  content: "\f04b";
}
%icon-chevron-left:before {
  content: "\f053";
}
%icon-chevron-right:before {
  content: "\f054";
}
%icon-chevron-up:before {
  content: "\f077";
}
%icon-chevron-down:before {
  content: "\f078";
}
%icon-twitter-flat:before {
  content: "\f099";
}
%icon-facebook-flat:before {
  content: "\f09a";
}
%icon-rss-flat:before {
  content: "\f09e";
}
%icon-google-plus:before {
  content: "\f0d5";
}
%icon-caret-down:before {
  content: "\f0d7";
}
%icon-caret-up:before {
  content: "\f0d8";
}
%icon-mail:before {
  content: "\f0e0";
}
%icon-linkedin:before {
  content: "\f0e1";
}
%icon-angle-left:before {
  content: "\f104";
}
%icon-angle-right:before {
  content: "\f105";
}
%icon-angle-up:before {
  content: "\f106";
}
%icon-angle-down:before {
  content: "\f107";
}
%icon-youtube-flat:before {
  content: "\f167";
}
%icon-language:before {
  content: "\f1ab";
}
%icon-newspaper:before {
  content: "\f1ea";
}
%icon-checkmark:before {
  content: "\e911";
}
%icon-file-empty:before {
  content: "\e903";
}
%icon-cart:before {
  content: "\e900";
}