.mini-cart-container {
	position: relative;

	.status {
		background-color: $styrolution-orange;
		padding: 5px;
		border-radius: 0.5em;
		color: $styrolution-blue;
	}
}

.mini-cart {
	display: none;
	position: absolute;
	right: 0;
	margin-top: 10px;
	width: auto;
	background-color: white;
	box-shadow: 0 0 8px 0 rgba(0,0,0,0.4);

	.mini-cart__item {
		padding-bottom: 11px;
		padding-right: 20px;
	}

	.mini-cart__item:nth-child(even) {
		background-color: $white-hued;
	}

	.mini-cart__item:not(:first-child) {
		border-top: 1px solid $light-gray;
	}

	table {

		table-layout: auto;

		tr {
			background-color: transparent;
		}

		td {
			vertical-align: top;
		}

		&.mini-cart__head {
			margin: 0.75em 0 0 0;

			td {
				text-align: left;
				color: black;
				border: none;

				a {
					color: black;
					padding: 0;
				}
			}

			tr.mini-cart__article-row {
				td {
					padding: 10px 0 0 15px;

					&.mini-cart__article {
						white-space: nowrap;
						padding-right: 20px;
					}
				}
				td:last-child {
					padding-left: 0;
				}
			}

			tr.mini-cart__material-row {

				td {
					padding: 0 0 9px 15px;
					color: $gray;
				}
				td:first-child {
					padding-right: 20px;
					white-space: nowrap;
				}
				td:last-child {
					padding-left: 0;
					border-bottom: 1px solid $light-gray;
				}
			}
		}

		&.mini-cart__content {
			margin: 0;

			td {
				text-align: right;
				border: none;
				font-size: 12px;
				padding-right: 0;
				padding-bottom: 0;

				&.mini-cart__order-date {
					width: 80px;

				}
			}
		}
	}

	&__footer {
		text-align: right;
		background-color: white;
		padding: 15px 15px 5px 0;
		border-top: 1px solid $light-gray;

		a.button.primary {
			padding: 0.9em 1.6em 1em;
			font-size: 14px;
			margin-bottom: 10px;

			&:hover {
				color: white;
			}
		}
	}
}