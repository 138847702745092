// Locations actions - filtering
.locations-filters {
  @include media($large-screen-up) {
    padding: 30px 0;

    &.is-hidden {
      display: block;
    }
  }
}

.location-filter {
  width: 100%;
  float: left;
  margin-bottom: 10px;

  @include media($large-screen-up) {
    width: 220px;
    margin-bottom: 0;

    &:not(:last-of-type) {
      margin-right: 60px;
    }
  }

	.dropdown__button {
		line-height: 35px;
		color: $gray;
	}
}

.location-filter--large {
	width: auto;
}

.location-filter__title {
	font-weight: 700;
	font-size: 14px;
	margin-bottom: 5px;
}

.filter-by-type {
	@include clearfix;
}

.filter-category {
	display: inline-block;
	font-size: 14px;
	margin-right: 20px;
  color: $light-gray;

	span {
		display: inline-block;
		@include size(35px);
		border-radius: 50%;
		border: 2px solid white;
		font-weight: 700;
		text-align: center;
		line-height: 28px;
		color: white;
		margin-right: 10px;
    background-color: $lightest-gray;
	}

  &:hover {
    cursor: pointer;
    cursor: hand;
  }

	&.inactive {
		color: $lightest-gray;

		span {
			background-color: $lightest-gray;
			color: $light-gray;
		}
	}

  &.enabled {
    color: $gray;

    &:hover {
      color: $accented-font-color;
    }

    &.filter-category--1 {
      span {
        background-color: #009FE8;
      }
    }

    &.filter-category--2 {
      span {
        background-color: $accented-background-color;
      }
    }

    &.filter-category--3 {
      span {
        background-color: #B1CD00;
      }
    }
  }
}

// Locations
.locations {
	max-width: $max-width;
	margin: 0 auto;

	@include media($large-screen-up) {
		margin-top: 40px;

		.list--arrow {
			> li > a > i {
				&:before {
					content: '\e114';
				}
			}
		}
	}
}

// Locations details
.location-details__details {
	padding: $base-spacing 0;
}

.map-container {
	height: 200px;
	overflow: hidden;
	border-bottom: 1px solid $lightest-gray;

	img {
		display: block;
    @include position(relative, 50% null null null);
    @include transform(translateY(-50%));
	}

	@include media($large-screen-up) {
		height: 250px;
	}

  .no-csstransforms & {
    img {
      top: 0;
    }
  }
}

.map-details {
	position: relative;
	@include clearfix;
}

.map-details__title {
	font-weight: 700;
	font-size: 21px;
	margin-bottom: $base-spacing / 2;
}

.map-details__address {
	margin-bottom: $base-spacing / 2;

	.map-details__address__image {
		width: 130px;
		height: 90px;
		float: right;
		margin-left: 20px;

		@include media($large-screen-up) {
			@include size(220px 150px);
			float: left;
			margin-left: 0;
			margin-right: 55px;
		}
	}

	.address {
		font-size: 16px;
	}
}

.map-details__contacts,
.contacts-button-holder {
	margin-bottom: $base-spacing / 2;
}

.map-details__contacts {
	@include media($large-screen-up) {
		.list--contacts {
			li:first-child {
				padding-top: 0;
			}
		}
	}
}

.link--gmaps {
	@include media($large-screen-up) {
		font-size: 14px;
	}
}

// Locations list
.locations-list {

  > .wrapper {
    padding: 0;
  }

  .accordion {
    > .list--arrow__list-item {
      a:hover {
        cursor: pointer;
        cursor: hand;
      }
    }

    .accordion__content {
      padding: 0;

      .map-details {
        padding: 0 30px;
      }
    }
  }

  @include media($large-screen-up) {
    padding: ($base-spacing * 2) 0;

    > .wrapper {
      padding: 0;
    }
  }
}

// Locations map
.map {
	height: 560px;
	border-top: 2px solid $accented-border-color;
	background:#aecbd1;
	animation:fadeIn 200ms ease 200ms both;
}

.controls {
  margin: 0 0 10px;
}

.category-list {
  padding: 0;
  list-style: none;
}


.marker {
  width: 22px;
  height: 30px;
  position: absolute;
  margin-top: -24px;
  margin-left: -20px;
  cursor: pointer;
  pointer-events: all;
}

.marker-tooltip {
  z-index:999999;
  position:absolute;

  > div {
    bottom: auto !important;
    top: -38px;
  }
}

.marker-tooltip-close {
  position: absolute;
  top:9px;
  right: 9px;
  line-height: 10px;
  text-align:center;
  color: #FFF;
  cursor: pointer;

  .ie8 & {
    @include size(14px);
    background-image: url(../img/map/close_contact.png);
    background-repeat: no-repeat;
  }
}

.marker-popup {
  top:14px;
  left:10px;
  position:absolute;
  max-width:400px;
  color:#000;
  pointer-events: all;
  @include user-select(auto);
  box-shadow: 0 0 5px -2px;

  .infowindow {
    background: #FFF;
  }
}

.marker-location {
  padding: 5px 25px 3px 20px;
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  background: $gray;
  color: white;
  text-align:center;
  border-bottom: 2px solid $accented-border-color;
}

.panes {
  width: 250px;
  overflow:hidden;
  position:relative;
}

.pane-wrapper {
  width:750px;
  position: relative;
  overflow: hidden;
}

.pane {
  width: 250px;
  float: left;
  font-size: 14px;
  position: relative;
}

.pane-contents {
  padding: 0 15px 15px;
}

.pane ul {
  padding: 0;
  margin:0 -15px -15px;
  list-style-type: none;
}

.pane li {
  padding: 8px 10px;
  border-top: 1px solid #eee;
  cursor: pointer;
}

.pane li:first-child {
  border-top:none;
}

.pane img {
  width: 250px;
  margin: 15px 0 0;
}

.pane-contents li,
.map-categories li,
.pane-title {
  font-size: 11px;
  color: $dark-gray;
  padding: 6px 37px 5px 10px;
  position: relative;
  background-image: url('../img/map/Map-Infobox-Icons.png');
  background-repeat: no-repeat;
  background-position:right 0;
}

.pane-title {
  background-color: #F1F1F1;
  text-align: right;
}

.pane-contents li {
  background-position:right -180px;

  &:hover {
    color: $accented-font-color;
  }
}

.map-categories li.map-icon-cat1 {background-position:right -36px;}
.map-categories li.map-icon-cat2 {background-position:right -67px;}
.map-categories li.map-icon-cat3 {background-position:right -97px;}
.map-categories li.map-icon-cat4 {background-position:right -134px;}

.pane-title-cat1 {background-position:193px -35px;}
.pane-title-cat2 {background-position:193px -68px;}
.pane-title-cat3 {background-position:193px -99px;}
.pane-title-cat4 {background-position:193px -135px;}

.pane-back {
  font-family: "DinMedium", sans-serif;
  position: absolute;
  left: 3px;
  top: 0;
  display: block;
  height: 24px;
  line-height: 16px;
  padding: 0 10px 0 5px;
  color: #faa634;
  cursor: pointer;
  font-size: 12px;

  &:before {
    content: '\f104';
    display: inline-block;
    font-family: "Icomoon";
    font-size: 24px;
    margin-right: 5px;
    position: relative;
    top: 5px;
  }

  &:hover {
    color: $gray;
  }
}

.getDirections {
  font-family: "DinMedium", sans-serif;
  position: absolute;
  right: 8px;
  bottom: 8px;
  display: block;
  height: 17px;
  line-height: 20px;
  padding: 0 5px 0 5px;
  color: white;
  background:#faa634;
  cursor: pointer;
  font-size: 12px;
  text-decoration: none;
}

.getDirections:hover {background:#555;}
.getDirections:visited {color:white;}

.pane-contents h2 {
  color: $accented-font-color;
  margin:0.5em 0 0 0;
}

.pane-contents p {
  font-family: $base-font-family;
  font-size: 12px;
  color: $gray;
  margin:14px 0 0.5em 0;

  .pane-bu-title {
    font-weight: 500;
    font-size: 14px;
  }
}

.pane-contents p a {
  color: $accented-font-color;
  text-decoration: none;
}

.map-controls {
  width:980px;
  margin:0 auto;
  position: relative;
}

.zoomer,
.map-legends,
.map-tooltip,
.map-fullscreen {
  position:absolute;
  box-sizing:border-box;
  background:#fff;
}

.map-legends,
.map-tooltip {
  box-shadow:0 1px 2px rgba(0,0,0,0.15);
}

.map-fullscreen,
.map-tooltip .close {
  text-indent:-999em;
  background: #6a6a6a url('../img/map/map-controls.png') no-repeat 0 0;
  overflow:hidden;
  display:block;
}

.map-tooltip .close:active,
.zoomer:active,
.map-fullscreen:active {
  border-color:#b0b0b0;
  background-color:#f0f0f0;
  box-shadow:inset 0 1px 3px rgba(0,0,0,0.15);
}

.zoomer {
  text-indent: -9999px;
  overflow: hidden;
  display: block;
  background-color: #fff;
  width:30px;
  height:30px;
  top:20px;
  left:20px;
  z-index:2;
  border: 1px solid $light-gray;

  &:before {
    font-family: "Icomoon";
    display: block;
    position: absolute;
    color: $gray;
    text-indent: 0;
    width: 100%;
    text-align: center;
    line-height: 28px;
  }
}

.zoomin {
  &:before {
    content: '\e114';
  }
}

.zoomout {
  top:49px;

  &:before {
    content: '\e115';
  }
}

.zoomdisabled {
  background-color:#aaa;
}

.map-fullscreen {
  width:36px;
  height:34px;
  background-position:-90px 2px;
  position:absolute;
  top:18px;
  right: 0px;
  z-index:2;
}

.map-fullscreen-map {
  position:fixed!important;
  width:auto!important;
  height:auto!important;
  top:0!important;
  left:0!important;
  right:0!important;
  bottom:0!important;
  z-index:99999999999;
}

.map-fullscreen-map .map-fullscreen {
  background-position:-131px 2px;
}

.map-legends {
  position:absolute;
  right:10px;
  bottom:10px;
  z-index:999999;
}

.map-legends .map-legend {
  padding:10px;
}

.map-tooltip {
  z-index:999999;
  padding:10px;
  top:10px;
  right:10px;
  max-width:300px;
  opacity:1;
  -webkit-transition:opacity 150ms;
     -moz-transition:opacity 150ms;
      -ms-transition:opacity 150ms;
       -o-transition:opacity 150ms;
          transition:opacity 150ms;
  -webkit-user-select:auto;
     -moz-user-select:auto;
          user-select:auto;
}

.map-movetip {
  position:absolute;
  z-index:999999;
  background:#fff;
  padding:10px;
  max-width:300px;
}

.map-fade { opacity:0; }

.map-tooltip .close {
  display:block;
  position:absolute;
  top:0;
  right:0;
}

.map-mobile-body .map-tooltip {
  position:absolute;
  top:50px;
}

.zoombox-box,
.boxselector-box {
  margin:0;
  padding:0;
  border:1px dashed #888;
  background:rgba(255,255,255,0.25);
  position:absolute;
  top:0;
  left:0;
  width:0;
  height:0;
  display:none;
}

.map-point-div {
  width:10px;
  height:10px;
  margin-left:-5px;
  margin-top:-5px;
  background:#fff;
  border:1px solid #333;
  -webkit-border-radius:5px;
          border-radius:5px;
}

.map-attribution {
  position:absolute;
  background-color:rgba(255,255,255,0.7);
  color:#333;
  font-size:11px;
  line-height:20px;
  z-index:10;
  text-align:center;
  padding:0 5px;
  bottom:0;
  right:0;
}

.map-attribution a {color:#faa634;text-decoration: none;}

.map-attribution.map-g {
  left:65px;
  bottom:4px;
  background:transparent;
}

@media
  only screen and (-webkit-min-device-pixel-ratio : 2),
  only screen and (min-device-pixel-ratio : 2) {
    .map-fullscreen,
    .map-tooltip .close {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAAA8CAYAAADc3IdaAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAbrwAAG68BXhqRHAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAALjSURBVHic7d3RsZswEIXhJZNC2EZSSmpwKa7BpbgR6ER5MRniwURgVuiI/3vynblmDJbPlZaVb5dSMgBQ8OPsFwAAuQgsADIILAAyCCwAMggsADIILAAyCCwAMggsADIILAAyCCwAMggsADIILAAyCCwAMggsADIILAAyCCwAh3L3m7vfIo79M+KgAK7pFVS312MbhuF+5PEJLOAL8w/ommEY+vhXE8/db59CKPdafIMlIfCF14f30FlE5RaXewthdT96dmUWGFh717GR698z9H3/cPfR3ce+7x9nvx4c7+qhVSqszIKWhHvXsdHr3zN0Xfdr6THaMgzD3d3NgpdEFbm9ztesUFiZBcywFtI2a8a093kATlNsZjUpVcNaDZ8SxTogEmM4PqzMAgJrZT2fW6ybFLkAwLeWajh2rZpWMSEzrNzQIqyg7lPB+eqF+ChhfVgrRchPxboJYQUJ/7s7dtVCvFTRfW5tpmWEFYTl3spnpnWsLqUUdey/MguSkmHV9/3jqHaFlNJzHMffRxwrylXOd2NHt+TYVVTkLmHGXxnZN/zI3iqFPq0Lne+pHd1Yxl5C4LN/ajIthpXaXsgiM6yMiyLbJJpSetZ4rChXO197jc0Ww8pMr8YWXsPa2FDXxCCYc/dx/nMtf6mw7v19e9PiOF39nNYybkNnWGt9VrahuRSoSHNhZaYz0wqrYeU0ha71abU4KAB8J2SGldvBvnUbD1CBJsemyl7IEt/WMNnaWNfkwEATmhqbSnshS31bw+q6X2X9DMw0EVpqeyFD7hK+XYTsIuXe59WMu4SoVU6rxvQ7tYzbkKL7bNPnpuL53ufVLKX0nDq6RfqOcAFb9kLOvqzgdEX2EgKoi+pqhsACLmqqwamElRmBBUAI/5cQgAwCC4AMAguADAILgAwCC4AMAguADAILgAwCC4AMAguADAILgAwCC4AMAguADAILgAwCC4AMAguADAILgAwCC4CMP5S+k8U+TkveAAAAAElFTkSuQmCC);
      background-size: 150px 30px;
    }
  }


.minimap {
  margin-top: 10px;
}
