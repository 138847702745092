
.industry-module {
    [class^="iconprop-"],
    [class^="iconprop-"] {
        font-size: 35px;
        color: inherit;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        font-weight: normal;
        speak: none;
        text-align: center;
        text-decoration: inherit;
        text-transform: none;

        &:before {
            font-size: 35px;
            color: $styrolution-green;
        }
    }

    [class^="iconprop-"] {
        &:before {
            font-size: 25px;
        }
    }

    .hotspots {
        overflow: hidden;
    }
    .no-bottom-padding {
        padding-bottom: 0 !important;
    }

    .industry-tab-link {
        margin-top: 2px;
        overflow: hidden;

        &.current {
            .iconprop-minus {
                display: inline;

                &:before {
                    color: white;
                }
            }
            .iconprop-add-thin {
                display: none;

                &:before {
                    color: white;
                }
            }

            .industry-tab-hotspots-list {
                display: block;

                &.hidden {
                    display: none;
                }
            }
        }
    }

    .hotspots__modal-product-element {
        &.open {
            .hotspots__modal-dropdown-button {
                [class^="iconprop-"] {
                    transform: rotate(270deg);
                }
            }
        }
    }

    .hotspots__modal-dropdown-button {
        position: relative;
        background-color: transparent;
        border: none;
        outline: none;
        color: $styrolution-green;
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        text-transform: none;
        font-weight: normal;
        padding: 0.2em 1em 0.2em;
        width: 48px;

        [class^="iconprop-"] {
            flex: 0 0 auto;
            transform: rotate(90deg);
            transition: transform 200ms;
            margin-left: 5px;
        }

        &:before {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            border-left: 1px solid $light-gray;
            content: '';
        }
    }

    .hotspots__modal--content-headline {
        margin-top: 35px;
        display: none;
    }

    .popup-close-wrapper {
        display: flex;
        align-items: center;
    }

    .hotspots__modal {
        padding-top: 10px;
        transform: translate3d(0, -100%, 0);
        transition: transform 200ms;
        background-color: $white-hued;
        z-index: 1;

        &.active {
            transform: translate3d(0, 0, 0);
        }
    }

    .slick-next,
    .slick-prev {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-color: transparent;
        border: none;

        &:hover,
        &:focus {
            background-color: transparent;
        }
    }

    .slick-next {
        right: 5px;
    }
    .slick-prev {
        left: 5px;
    }

    .industry-tab-link-description {
        background-color: $styrolution-green;
        color: white;
        text-transform: uppercase;
        display: flex;
        padding-left: 10px;
        padding-right: 10px;
        cursor: pointer;
        align-items: center;
        z-index: 2;
        position: relative;

        span {
            flex: 1 1 auto;
            padding-top: 12px;
            padding-bottom: 12px;
            font-weight: normal;
            font-size: 18px;
        }

        [class^="iconprop-"] {
            flex: 0 0 auto;
        }

        .iconprop-minus {
            display: none;
            color: white;

            &:before {
                color: white;
            }
        }

        .iconprop-add-thin {
            display: inline;

            &:before {
                color: white;
            }
        }
    }

    .industry-tab-hotspots-list,
    .hotspots__modal-product-sublist {
        display: none;

        &.active {
            display: block;
        }
    }

    .industry-tabs {
        @media(max-width: $small-screen-max) {
            transform: none !important;
            margin-bottom: 20px;
        }
    }

    .industry-tabs__prev,
    .industry-tabs__next {
        display: none;
    }

    .hotspots__modal--content-description {
        margin-bottom: 0;
        margin-top: 20px;
    }

    .hotspots__modal-products-headline {
        margin-top: 55px;
    }

    .hotspots__modal-product-list {
        margin-top: 20px;
    }

    .hotspots__modal-product-element {
        &:before {
            border-top: 1px solid $light-gray;
            content: '';
            display: block;
        }

        &:last-child:after {
            border-bottom: 1px solid $light-gray;
            content: '';
            display: block;
        }
    }

    .hotspots__modal-product-element-label {
        padding-top: 10px;
        padding-bottom: 10px;
        display: flex;
        align-items: center;
    }

    .hotspots__modal-product-element-label {
        > div,
        > span {
            flex: 1 1 auto;
            text-transform: uppercase;
        }

        a {
            flex: 0 0 auto;
        }
    }

    .hotspots__modal-product-element-subline {
        text-transform: none;
    }

    .hotspots__modal-sublist-title,
    .hotspots__modal-sublist-features {
        margin-bottom: 0;
    }

    .hotspots__modal-sublist-element--unavailable {
        position: relative;
        .hotspots__modal-sublist-title,
        [class^="iconprop-"]:before {
            color: $gray;
        }

        .hotspots__modal-link {
            cursor: auto;
            pointer-events: none;
        }

        .hotspots__modal-sublist-unavailable {
            position: absolute;
            right: 0;
            bottom: 0;
            top: 0;
            left: 0;
            background-color: $styrolution-orange;
            color: white;
            text-align: center;
            display: none;
            align-items: center;
            width: 100%;
            justify-content: center;
        }

        &:hover {
            .hotspots__modal-sublist-unavailable {
                display: flex;
            }
        }
    }

    .hotspots__modal-sublist-element-inner {
        display: flex;
        align-items: center;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .hotspots__modal-sublist-element + .hotspots__modal-sublist-element,
    .hotspots__modal-sublist-element:first-child {
        &:before {
            border-top: 1px solid $light-gray;
            content: '';
            display: block;
        }
    }

    .hotspots__modal-sublist-title {
        text-transform: uppercase;
        color: $styrolution-green;
    }

    .hotspots__modal-sublist-container {
        flex: 1 1 auto;
        padding-right: 10px;
    }

    .hotspots__modal-footer {
        background-color: $styrolution-blue;
        color: white;
        padding-top: 25px;
        padding-bottom: 25px;
        margin-top: 50px;
    }

    .modal__contact-title {
        margin-bottom: 0;
    }

    .modal__contact-mail {
        color: white;
        text-decoration: underline;
    }

    .modal__contact-description,
    .modal__contact-details,
    .modal__contact-image,
    .modal__contact-mail {
        margin-top: 10px;
        margin-bottom: 0;
    }

    .hotspots__image-wrapper {
        display: none;
    }

    .hotspots__element-mobile {

        align-items: center;

        a {
            display: flex;
            align-items: center;
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 10px;
            padding-right: 10px;

            span {
                flex: 1;
            }

            [class^="iconprop-"] {
                flex: 0 0 auto;
                font-size: 20px;
                line-height: 20px;

                &:before {
                    font-size: 20px;
                    line-height: 20px;
                }
            }
        }

    }

    .hotspots__element-mobile + .hotspots__element-mobile {
        &:before {
            border-top: 1px solid $styrolution-green;
            content: '';
            display: block;
        }
    }

    .hotspots__modal-link {
        padding: 0.2em 0.74em 0.2em;
        min-width: 48px;
        text-align: center;
    }

    .hotspots__modal-link + .hotspots__modal-link {
        position: relative;

        &:before {
            border-left: 1px solid $light-gray;
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
        }
    }

    .hotspots__modal-link-container {
        position: relative;
        display: flex;
    }

    .hotspots__modal-image-carousel-wrapper {
        position: relative;
        padding-bottom: 66.66666%;
        margin-top: 20px;
        overflow: hidden;
    }

    .hotspots__modal-image-carousel {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .hotspots__modal-category-mobile {
        color: $styrolution-blue;
        flex: 1 1 auto;
        align-items: center;
        text-transform: uppercase;
    }

    .hotspots__modal-products-headline-mobile {
        margin-top: 10px;
    }

    @include breakpoint($medium-screen) {
        .hotspots__modal-footer {
            .hotspots__col {
                @include span-columns(2 of 4);
            }
        }
        .industry-tabs {
            padding: 0;
            list-style: none;
            display: flex;
            flex-wrap: nowrap;
            transform: translateX(0);
            transition: transform 200ms;
            overflow: visible;
        }

        .industry-tabs-wrapper {
            margin-top: 30px;
            position: relative;
            overflow: hidden;

            .industry-tabs__prev,
            .industry-tabs__next {
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                padding-left: 10px;
                padding-right: 10px;
                padding-top: 0;
                padding-bottom: 0;
                cursor: pointer;
                text-align: right;
                border: none;
                outline: none;
                background-color: white;
                z-index: 10;

                &.hidden {
                    display: none;
                }
            }

            .industry-tabs__prev {
                left: 0;
                .iconprop-arrow_forward {
                    transform: scale(-1);
                }
            }
            .industry-tabs__next {
                right: 0;
            }
        }

        .industry-tab-hotspots-list {
            display: none;
        }

        .industry-tab-link {
            background: none;
            color: $styrolution-green;
            display: inline-block;
            cursor: pointer;
            text-transform: uppercase;
            font-weight: 600;
            white-space: nowrap;
            overflow: visible;

            i {
                display: none;
            }

            a {
                padding: 12px 48px 12px 10px;
            }

            .iconprop-add-thin,
            .iconprop-minus {
                display: none !important;
            }

            &.current {
                border-bottom: 4px solid $styrolution-blue;
                color: $styrolution-blue;
                pointer-events: none;

                .industry-tab-link-description {
                    color: $styrolution-blue;
                }
            }
        }

        .industry-tab-link-description {
            background-color: transparent;
            color: $styrolution-green;

            span {
                padding: 0;
                font-weight: bold;
                font-size: medium;
            }
        }

        .industry-tab-content {
            display: none;

            &.current {
                display: inherit;
            }
        }

        .industry-tab-hotspots-list {
            display: none !important;
        }

        .hotspots__modal {
            width: 100%;
            display: flex;
            background-color: $white-hued;
            z-index: 90;
            padding-top: 0;
            flex-direction: column;
            justify-content: stretch;
            overflow: hidden;
            position: relative;

            .wrapper {
                min-height: 30px;
                position: relative;
            }

            .popup-inner {
                flex: 1 1 auto;
                background-color: $white-hued;
            }

            .popup-close-wrapper {
                text-align: right;
            }

            .popup-close {
                width: 100%;
                text-align: right;
                color: $styrolution-green;
                right: 0;
            }

        }

        .hotspots__modal-category-mobile {
            display: none;
        }

        // modal footer
        .hotspots__modal-footer {
            background-color: $styrolution-blue;
        }

        .hotspots__modal--application-headline {
            margin-top: -10px;
        }

        .hotspots__modal--content-headline {
            margin-top: 35px;
            display: block;
        }
        .hotspots__modal--content-description {
            margin-top: 25px;
            margin-bottom: 0;
        }

        .hotspots__modal-products-headline-mobile {
            display: none;
        }

        .modal__contact-right-wrapper {
            display: flex;
            align-items: flex-start;
        }

        .modal__contact-image {
            flex: 0 0 auto;
            margin-right: 10px;
        }

        .modal__contact-details {
            flex: 1 1 auto;
        }

        .modal__contact-headline {
            margin-top: 4px;
        }

        .hotspots__image-wrapper {
            display: block;
            position: relative;
            width: 100%;
            padding-bottom: 66.6666%
        }

        .hotspots__element {
            display: none;
        }

        .hotspots__image-wrapper--modal-open {
            padding-bottom: 0;

            .hotspot-image {
                display: none;
            }
        }

        .hotspots__image-wrapper--hotspots-visible {
            .hotspots__element {
                display: block;
            }
        }

        .hotspots__more-applications {
            position: absolute;
            z-index: 1;
        }

        .hotspot-image {
            width: 100%;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
        }

        .hotspots__element {
            //display: table;
            cursor: pointer;
            width: 46px;
            height: 46px;
        }

        .hotspots__element-image {
            border: 2px solid white;
            padding: 5px;
            border-radius: 100%;

            > div {
                border: 1px solid white;
                padding: 5px;
                border-radius: 100%;
                transition: border-color 0.25s;

                > div {
                    height: 20px;
                    width: 20px;
                    border-radius: 100%;
                    border: 2px solid white;
                    background-color: $styrolution-green;
                    transition: border-color 500ms;
                }
            }
        }

        .hotspots__element:hover {
            .hotspots__element-image > div {
                border-color: $styrolution-green;
            }
        }

        .hotspots__element-headline {
            visibility: hidden;
            position: absolute;
            margin-left: 15px;
            //visibility: hidden;
            background-color: #64A342;
            color: #fff;
            text-align: center;
            padding: 10px 15px;
            z-index: 1;
            top: 50%;
            margin-bottom: 0;
            text-transform: uppercase;
            white-space: nowrap;

            &:after {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                height: 10px;
                border-width: 10px;
                border-style: solid;
                border-color: transparent $styrolution-green transparent transparent;
            }
        }

        .show-label-right {
            .hotspots__element-headline {
                visibility: visible;
                -webkit-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);
                left: 45px;

                &:after {
                    right: 100%;
                }
            }
        }

        .show-label-left {
            .hotspots__element-headline {
                visibility: visible;
                -webkit-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);
                right: 60px;

                &:after {
                    left: 100%;
                    -webkit-transform: scaleX(-1);
                    -ms-transform: scaleX(-1);
                    transform: scaleX(-1);
                }
            }
        }

        .hotspots__more-applications-hotspot {
            height: 50px;
            width: 50px;
            background-color: $styrolution-green;
            position: absolute;
            bottom: 100px;
            left: 100px;

            &.open {
                left: 500px;
                bottom: 220px;
            }
        }

        .hotspots__more-applications {
            color: $white;
            bottom: 50px;
            left: 0;
            right: 0;

            .wrapper--more-applications {
                position: relative;
            }

            .hotspot {
                position: absolute;
                left: -70px;
                cursor: pointer;
                transform: translateY(-50%);
                top: 50%;

                .hotspots__element-image {
                    border: 2px solid white;
                    padding: 5px;
                    border-radius: 100%;

                    > div {
                        border: 1px solid white;
                        padding: 5px;
                        border-radius: 100%;

                        > div {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 35px;
                            width: 35px;
                            border-radius: 100%;
                            border: 2px solid white;
                            background-color: $styrolution-green;
                            transition: border-color 500ms;
                        }
                    }

                    &:hover {
                        > div {
                            border: 1px solid $styrolution-green;
                        }
                    }
                }

                [class^="iconprop-"] {
                    display: none;
                    font-size: 15px;
                    height: 15px;
                    line-height: 15px;

                    &:before {
                        color: white;
                        font-size: 15px;
                    }
                }
            }

            .hotspots__element {
                color: white;

                &:hover {
                    color: $styrolution-green;
                }
            }

            .content {
                display: inline-block;
                overflow: visible;
                position: relative;
                height: auto;
                padding: 0 3px;
                margin-left: 60px;
                background-color: rgba(0, 0, 0, 0.5);
                transition: padding .4s;

                .hotspots__more-applications-number {
                    font-weight: bold;
                    font-size: 16px;
                }

                &__inner {
                    //max-height: 0;
                    display: none;
                    overflow: hidden;
                    //transition: max-height 0.15s ease-out;
                }

                .content__headline {
                    cursor: pointer;
                    font-weight: bold;
                    text-transform: uppercase;
                    padding: 5px 0;
                }

                ul {
                    margin-top: 15px;
                    //display: none;
                    list-style: none;

                    .hotspots__element {
                        display: flex;
                        width: 100%;
                        align-items: center;

                        .hotspots__element-image {
                            margin-right: 5px;

                            > div {
                                padding: 5px;
                                border-color: white;

                                > div {
                                    height: 15px;
                                    width: 15px;
                                }
                            }
                        }

                        &:hover {
                            .hotspots__element-image {
                                > div {
                                    border-color: $styrolution-green;
                                }
                            }
                        }
                    }
                }

                li {
                    margin: 5px 5px 5px 0;
                    cursor: pointer;

                    + li {
                        margin-top: 10px;
                    }

                    a {
                        display: initial;
                    }

                    &:hover {
                        color: $accented-font-color;
                    }
                }
            }

            &.current {
                .hotspots__more-applications-number {
                    display: none;
                }

                .hotspot {
                    left: calc(100% - 25px);
                    top: 0;

                    [class^="iconprop-"] {
                        display: block;
                    }

                }
                .content {
                    //padding: 20px 50px 20px 35px;
                    padding: 20px 50px 20px 35px;

                    &__inner {
                        //max-height: 1000px;
                        //transition: max-height 0.25s ease-in;
                    }

                    //ul {
                    //  display: block;
                    //}
                }
            }
        }

        .wrapper--body {
            margin-top: 0;
        }
        .hotspots__modal-image-carousel-wrapper {
            margin-top: 0;
        }

        .hotspots__modal-product-list {
            margin-top: 30px;
        }
    }

    @include breakpoint($large-screen) {
        .hotspots__col {
            @include span-columns(2 of 4);
        }
        .hotspots__modal {
            padding-top: 15px;
        }
        .wrapper--body {
            margin-top: 10px;
        }
        .hotspots__modal-products-headline {
            margin-top: 0;
        }
        .hotspots__modal-image-carousel-wrapper {
            margin-top: 4px;
        }
        .hotspots__modal--content-description {
            margin-top: 35px;
        }
        .hotspots__modal-footer {
            margin-top: 75px;
        }
    }

    @include breakpoint($desktop-nav-screen) {
        .hotspots__modal-image-carousel-wrapper {
            margin-top: 10px;
        }
    }
}


