table {
  border-collapse: collapse;
  margin: ($base-spacing / 2) 0;
  table-layout: fixed;
  width: 100%;
}

th {
  font-weight: 700;
  padding: 10px;
  text-align: left;
  color: white;
  background-color: $gray;
}

td {
  border-bottom: $base-border;
  padding: 10px;
  font-size: 14px;
}

tr,
td,
th {
  vertical-align: middle;
}

tr {
  &:nth-child(even) {
    background-color: white;
  }

  &:nth-child(odd) {
    background-color: #FBFBFB;
  }
}

.table--highlighted {
  font-size: 14px;
  background-color: white;
  border-top: 2px solid $light-gray;

  th {
    font-size: 12px;
    line-height: 1;
    text-transform: uppercase;
    vertical-align: top;
    padding: 20px 15px;

    .ie8 &.product--toggle-col-b {
      width: 877px;
    }
  }

  tr {
    td:first-child {
      color: $accented-font-color;
      width: 140px;
    }
  }

  .button {
    font-size: 12px;
    padding: 5px 1.6em;
  }
}

.table-holder {
  overflow-x: scroll;

  table {
    display: table !important;
    width: auto;
    min-width: 600px;
  }
}

.table-products,
.table-dynamic-products {
  display: table !important;
}

.table-dynamic-products {
  margin-bottom: 25px;

  tr {
    td {
      &:first-child {
        color: $gray;
      }
    }
  }

  #biodiesel + .content & {
    tr td:last-child {
      width: 360px;
    }
  }
}
