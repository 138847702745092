.order-templates {
	h3 {
		font-size: 24px;
		margin: 1em 0 1em;
		font-weight: 500;
	}
	h4 {
		color: $styrolution-green;
		text-transform: uppercase;
		font-size: 16px;
		line-height: 32px;
	}
	.tab-content {
		li {
			border-bottom: 1px solid gray;
			display: table;
			width: 100%;

			.order-template__title-wrapper {
				display: table-cell;
				width: 100%;
				vertical-align: middle;
			}


			.order-template__table {
				display: table;

				.order-template__checkbox {
					display: table-cell;
					vertical-align: top;
					margin-top: 5px;
				}

				.order-template__text-block {
					display: table-cell;
				}

			}

			.button-wrapper {
				padding: 20px 0;
				display: table-cell;
				border: none;
			}

			&.show-all {
				border: none;
				padding-top: 16px;
			}

			&.is-hidden {
				display: none;
			}
		}
	}
	.accordion-tabs-minimal .tab-content {
		padding: 0;
	}
	.button-bar {
		padding-top: 20px;

		.button-wrapper {
			display: inline-block;
			border: none;
			padding-left: 0;
		}
	}
	.order-template__description {
		display: block;
		color: $gray;
		font-size: 12px;
	}

	@include media($large-screen-up) {
		.accordion-tabs-minimal {
			a.tab-link {
				width: 50%;
				margin-right: -4px;
			}
		}
	}
}